
import {
    addNewRuleAPI,
    getAllRuleAPI,
    updateRuleAPI,
    deleteRuleAPI
} from './apis'

import *  as commonActions from "../../common/actions"
import { errorMessage } from '../../../utils'

export const GET_ALL_RULE_REQUEST = 'GET_ALL_RULE_REQUEST';
export const GET_ALL_RULE_SUCCESS = 'GET_ALL_RULE_SUCCESS';
export const GET_ALL_RULE_FAILED = 'GET_ALL_RULE_FAILED';

export function getAllRule(filters) {
    return function (dispatch) {
        return new Promise((resolve, reject) => {
            dispatch({
                type: "GET_ALL_RULE_REQUEST"
            })
            getAllRuleAPI(filters).then(res => {
                const { info = {} } = res || {}
                dispatch({
                    type: 'GET_ALL_RULE_SUCCESS',
                    payload: info || {}
                })
                resolve(res)
            }).catch(err => {
                dispatch({
                    type: 'GET_ALL_RULE_FAILED'
                })
                dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
                reject(err)
            })
        })
    }
}

export const ADD_NEW_RULE_REQUEST = 'ADD_NEW_RULE_REQUEST';
export const ADD_NEW_RULE_SUCCESS = 'ADD_NEW_RULE_SUCCESS';
export const ADD_NEW_RULE_FAILED = 'ADD_NEW_RULE_FAILED';

export function addNewRule(data) {
    return function (dispatch) {
        return new Promise((resolve, reject) => {
            dispatch({
                type: "ADD_NEW_RULE_REQUEST"
            })
            addNewRuleAPI(data).then(res => {
                dispatch({
                    type: 'ADD_NEW_RULE_SUCCESS'
                })
                dispatch(commonActions.openSnackbarsState({ message: res && res.message }))
                resolve(res)
            }).catch(err => {
                dispatch({
                    type: 'ADD_NEW_RULE_FAILED'
                })
                dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
                reject(err)
            })
        })
    }
}

export const UPDATE_RULE_REQUEST = 'UPDATE_RULE_REQUEST';
export const UPDATE_RULE_SUCCESS = 'UPDATE_RULE_SUCCESS';
export const UPDATE_RULE_FAILED = 'UPDATE_RULE_FAILED';

export function updateRule(data) {
    return function (dispatch) {
        return new Promise((resolve, reject) => {
            dispatch({
                type: "UPDATE_RULE_REQUEST"
            })
            updateRuleAPI(data).then(res => {
                dispatch({
                    type: 'UPDATE_RULE_SUCCESS'
                })
                dispatch(commonActions.openSnackbarsState({ message: res && res.message }))
                resolve(res)
            }).catch(err => {
                dispatch({
                    type: 'UPDATE_RULE_FAILED'
                })
                dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
                reject(err)
            })
        })
    }
}

export const DELETE_RULE_REQUEST = 'DELETE_RULE_REQUEST';
export const DELETE_RULE_SUCCESS = 'DELETE_RULE_SUCCESS';
export const DELETE_RULE_FAILED = 'DELETE_RULE_FAILED';

export function deleteRule(id) {
    return function (dispatch) {
        return new Promise((resolve, reject) => {
            dispatch({
                type: "DELETE_RULE_REQUEST"
            })
            deleteRuleAPI(id).then(res => {
                dispatch({
                    type: 'DELETE_RULE_SUCCESS'
                })
                dispatch(commonActions.openSnackbarsState({ message: res && res.message }))
                resolve(res)
            }).catch(err => {
                dispatch({
                    type: 'DELETE_RULE_FAILED'
                })
                dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
                reject(err)
            })
        })
    }
}


export const CLEAR_ALL_RULE_STORE = "CLEAR_ALL_RULE_STORE"

export function clearStoreForRule() {
    return (dispatch) => {
        dispatch({
            type: "CLEAR_ALL_RULE_STORE"
        })
    }
}