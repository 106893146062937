import { apiGet, apiPost, apiPut, apiDelete } from '../../../utils';


export function getAllElectricalRetailerAPI(filters) {
    let arr = []
    for (const [key, value] of Object.entries(filters)) {
        if (key === "isOpen") continue
        if (typeof value !== "number" && !value?.length) continue

        if (["status"].includes(key)) {
            arr.push({ key, value, type: 'multi' })
        } else {
            arr.push({ key, value, type: "single" })
        }
    }
    return apiGet(`/api/electricity-retailer/get/all?filter=${JSON.stringify(arr)}`)
}

export function deleteElectricalRetailerAPI(id) {
    return apiDelete(`/api/electricity-retailer/delete?electricity_retailer_id=${id}`)
}

export function createElectricalRetailerAPI(name, favourite) {
    let finalData = {
        retailer_name: name,
        is_top_listed: favourite === true ? 1 : 0
    }
    return apiPost('/api/electricity-retailer/add', finalData)
}

export function updateElectricalRetailerAPI(id, name, favourite) {
    let data = {
        electricity_retailer_id: id,
        retailer_name: name,
        is_top_listed: favourite === true ? 1 : 0

    }
    return apiPut('/api/electricity-retailer/edit/update', data)
}

export function getRetailerListAPI() {
    return apiGet(`/api/electricity-retailer/list`)
}
