import React from 'react'
import { Button, CircularProgress } from '@material-ui/core'

function SearchFilterButtons(props) {
    const { clearFilter = () => { }, applyFilter = () => { }, isLoading = false, clearFilterLoader = false } = props
    return (
        <>
            <div className="buttons-div mt-3">
                <Button
                    className=" btn-custom-border edit-btn button-btn mr-3 ml-3"
                    onClick={clearFilter}
                    disabled={isLoading}
                >
                    {/* {isLoading && clearFilterLoader ? <CircularProgress className="add-loader" size={22} color='white' /> : "Clear"} */}
                    Clear
                </Button>
                <Button
                    className="btn-custom-border edit-btn button-btn"
                    onClick={applyFilter}
                    disabled={isLoading}
                >
                    Apply
                    {/* {isLoading && !clearFilterLoader ? <CircularProgress className="add-loader" size={22} color='white' /> : "Apply"} */}
                </Button>
            </div>
        </>
    )
}

export default SearchFilterButtons