import * as actionsTypes from './actions';

const initialState = {
    isLoading: false,
    allDevices: {},
    liveLinkDevice: {},
    deviceHistory: []

}

export default function (state = { ...initialState }, action) {
    switch (action.type) {

        case actionsTypes.GET_ALL_LIVELINK_DEVIECS_REQUEST:
            return { ...state, isLoading: true }

        case actionsTypes.GET_ALL_LIVELINK_DEVIECS_SUCCESS:
            return { ...state, isLoading: false, allDevices: action.payload || {} }

        case actionsTypes.GET_ALL_LIVELINK_DEVIECS_FAILED:
            return { ...state, isLoading: false }

        case actionsTypes.CLEAR_ALL_LIVELINK_DEVICE_DATA:
            return { ...state, ...initialState }

        case actionsTypes.GET_LIVELINK_DEVICE_BY_ID_REQUEST:
            return { ...state, isLoading: true }

        case actionsTypes.GET_LIVELINK_DEVICE_BY_ID_SUCCESS:
            return { ...state, isLoading: false, liveLinkDevice: action.payload || {} }

        case actionsTypes.GET_LIVELINK_DEVICE_BY_ID_FAILED:
            return { ...state, isLoading: false }

        case actionsTypes.GET_LIVELINK_DEVICE_HISTORY_REQUEST:
            return { ...state, isLoading: true }

        case actionsTypes.GET_LIVELINK_DEVICE_HISTORY_SUCCESS:
            return { ...state, isLoading: false, deviceHistory: action.payload }

        case actionsTypes.GET_LIVELINK_DEVICE_HISTORY_FAILED:
            return { ...state, isLoading: false }


        default:
            return state
    }
}
