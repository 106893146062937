
import {
    getAllDeviceAPI,
    getAllOTAGroupAPI,
    deleteSingleGroupAPI,
    addDeviceInGroupAPI,
    getGroupDetailsAPI,
    updateGroupAPI,
    updateGroupInformationAPI,
    deleteDeviceFromGroupAPI
} from './apis'
import *  as commonActions from "../../common/actions"
import { errorMessage } from '../../../utils';

export const GET_ALL_OTA_GROUP_REQUEST = 'GET_ALL_OTA_GROUP_REQUEST';
export const GET_ALL_OTA_GROUP_SUCCESS = 'GET_ALL_OTA_GROUP_SUCCESS';
export const GET_ALL_OTA_GROUP_FAILED = 'GET_ALL_OTA_GROUP_FAILED';

export function getAllOTAGroup(filters) {
    return function (dispatch) {
        return new Promise((resolve, reject) => {
            dispatch({
                type: "GET_ALL_OTA_GROUP_REQUEST"
            })
            getAllOTAGroupAPI(filters).then(res => {
                dispatch({
                    type: 'GET_ALL_OTA_GROUP_SUCCESS',
                    payload: res.info || {}
                })
                resolve(res)
            }).catch(err => {
                dispatch({
                    type: 'GET_ALL_OTA_GROUP_FAILED'
                })
                dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
                reject(err)
            })
        })
    }
}

export const GET_ALL_DEVICE_REQUEST = 'GET_ALL_DEVICE_REQUEST';
export const GET_ALL_DEVICE_SUCCESS = 'GET_ALL_DEVICE_SUCCESS';
export const GET_ALL_DEVICE_FAILED = 'GET_ALL_DEVICE_FAILED';

export function getAllDevice(filters) {
    return function (dispatch) {
        return new Promise((resolve, reject) => {
            dispatch({
                type: "GET_ALL_DEVICE_REQUEST"
            })
            getAllDeviceAPI(filters).then(res => {
                const { data = [] } = res.info || {}
                dispatch({
                    type: 'GET_ALL_DEVICE_SUCCESS',
                    payload: data?.length ? data.map(res => ({ ...res, title: res.serial_number, value: res.id, })) : []
                })
                resolve(res)
            }).catch(err => {
                dispatch({
                    type: 'GET_ALL_DEVICE_FAILED'
                })
                dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
                reject(err)
            })
        })
    }
}

export const DELETE_SINGLE_OTA_GROUP_REQUEST = 'DELETE_SINGLE_OTA_GROUP_REQUEST';
export const DELETE_SINGLE_OTA_GROUP_SUCCESS = 'DELETE_SINGLE_OTA_GROUP_SUCCESS';
export const DELETE_SINGLE_OTA_GROUP_FAILED = 'DELETE_SINGLE_OTA_GROUP_FAILED';

export function deleteSinglegroup(id) {
    return function (dispatch) {
        return new Promise((resolve, reject) => {
            dispatch({
                type: "DELETE_SINGLE_OTA_GROUP_REQUEST"
            })
            deleteSingleGroupAPI(id).then(res => {
                dispatch({
                    type: 'DELETE_SINGLE_OTA_GROUP_SUCCESS',
                })
                dispatch(commonActions.openSnackbarsState({ message: res && res.message }))
                resolve(res)
            }).catch(err => {
                dispatch({
                    type: 'DELETE_SINGLE_OTA_GROUP_FAILED'
                })
                dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
                reject(err)
            })
        })
    }
}

export const ADD_DEVICE_IN_GROUP_REQUEST = 'ADD_DEVICE_IN_GROUP_REQUEST';
export const ADD_DEVICE_IN_GROUP_SUCCESS = 'ADD_DEVICE_IN_GROUP_SUCCESS';
export const ADD_DEVICE_IN_GROUP_FAILED = 'ADD_DEVICE_IN_GROUP_FAILED';

export function addDeviceInGroup(selectedDevice, data) {
    return function (dispatch) {
        return new Promise((resolve, reject) => {
            dispatch({
                type: "ADD_DEVICE_IN_GROUP_REQUEST"
            })
            addDeviceInGroupAPI(selectedDevice, data).then(res => {
                dispatch({
                    type: 'ADD_DEVICE_IN_GROUP_SUCCESS',
                })
                dispatch(commonActions.openSnackbarsState({ message: res && res.message }))
                resolve(res)
            }).catch(err => {
                dispatch({
                    type: 'ADD_DEVICE_IN_GROUP_FAILED'
                })
                dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
                reject(err)
            })
        })
    }
}

export const UPDATE_GROUP_REQUEST = 'UPDATE_GROUP_REQUEST';
export const UPDATE_GROUP_SUCCESS = 'UPDATE_GROUP_SUCCESS';
export const UPDATE_GROUP_FAILED = 'UPDATE_GROUP_FAILED';

export function updateGroup(selectedDevice, data, id) {
    return function (dispatch) {
        return new Promise((resolve, reject) => {
            dispatch({
                type: "UPDATE_GROUP_REQUEST"
            })
            updateGroupAPI(selectedDevice, data, id).then(res => {
                dispatch({
                    type: 'UPDATE_GROUP_SUCCESS',
                })
                dispatch(commonActions.openSnackbarsState({ message: res && res.message }))
                resolve(res)
            }).catch(err => {
                dispatch({
                    type: 'UPDATE_GROUP_FAILED'
                })
                dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
                reject(err)
            })
        })
    }
}

export const GET_GROUP_DETAILS_REQUEST = 'GET_GROUP_DETAILS_REQUEST';
export const GET_GROUP_DETAILS_SUCCESS = 'GET_GROUP_DETAILS_SUCCESS';
export const GET_GROUP_DETAILS_FAILED = 'GET_GROUP_DETAILS_FAILED';

export function getGroupDetails(id, filters) {
    return function (dispatch) {
        return new Promise((resolve, reject) => {
            dispatch({
                type: "GET_GROUP_DETAILS_REQUEST"
            })
            getGroupDetailsAPI(id, filters).then(res => {
                dispatch({
                    type: 'GET_GROUP_DETAILS_SUCCESS',
                    payload: res.info || {}
                })
                resolve(res)
            }).catch(err => {
                dispatch({
                    type: 'GET_GROUP_DETAILS_FAILED'
                })
                dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
                reject(err)
            })
        })
    }
}

export const UPDATE_GROUP_INFORMATION_REQUEST = 'UPDATE_GROUP_INFORMATION_REQUEST';
export const UPDATE_GROUP_INFORMATION_SUCCESS = 'UPDATE_GROUP_INFORMATION_SUCCESS';
export const UPDATE_GROUP_INFORMATION_FAILED = 'UPDATE_GROUP_INFORMATION_FAILED';

export function updateGroupInformation(data) {
    return function (dispatch) {
        return new Promise((resolve, reject) => {
            dispatch({
                type: "UPDATE_GROUP_INFORMATION_REQUEST"
            })
            updateGroupInformationAPI(data).then(res => {
                dispatch({
                    type: 'UPDATE_GROUP_INFORMATION_SUCCESS',
                    payload: res.info || {}
                })
                dispatch(commonActions.openSnackbarsState({ message: res && res.message }))
                resolve(res)
            }).catch(err => {
                dispatch({
                    type: 'UPDATE_GROUP_INFORMATION_FAILED'
                })
                dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
                reject(err)
            })
        })
    }
}

export const DELETE_DEVICE_FROM_GROUP_REQUEST = 'DELETE_DEVICE_FROM_GROUP_REQUEST';
export const DELETE_DEVICE_FROM_GROUP_SUCCESS = 'DELETE_DEVICE_FROM_GROUP_SUCCESS';
export const DELETE_DEVICE_FROM_GROUP_FAILED = 'DELETE_DEVICE_FROM_GROUP_FAILED';

export function deleteDeviceFromGroup(data) {
    return function (dispatch) {
        return new Promise((resolve, reject) => {
            dispatch({
                type: "DELETE_DEVICE_FROM_GROUP_REQUEST"
            })
            deleteDeviceFromGroupAPI(data).then(res => {
                dispatch({
                    type: 'DELETE_DEVICE_FROM_GROUP_SUCCESS',
                    payload: res.info || {}
                })
                dispatch(commonActions.openSnackbarsState({ message: res && res.message }))
                resolve(res)
            }).catch(err => {
                dispatch({
                    type: 'DELETE_DEVICE_FROM_GROUP_FAILED'
                })
                dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
                reject(err)
            })
        })
    }
}

export const CLEAR_ALL_GROUP_STORE = 'CLEAR_ALL_GROUP_STORE';

export function clearStoreForGroup() {
    return (dispatch) => {
        dispatch({
            type: "CLEAR_ALL_GROUP_STORE"
        })
    }
}

