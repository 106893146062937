import {
    getAllFirmwareAPI,
    createFirmwareAPI,
    updateFirmwareAPI,
    deleteFirmwareAPI
} from './apis'
import *  as commonActions from "../../common/actions"
import { errorMessage } from '../../../utils'

export const GET_ALL_FIRMWARE_REQUEST = 'GET_ALL_FIRMWARE_REQUEST';
export const GET_ALL_FIRMWARE_SUCCESS = 'GET_ALL_FIRMWARE_SUCCESS';
export const GET_ALL_FIRMWARE_FAILED = 'GET_ALL_FIRMWARE_FAILED';

export const getAllFirmware = (filters) => dispatch =>

    new Promise((resolve, reject) => {
        dispatch({
            type: GET_ALL_FIRMWARE_REQUEST
        })
        getAllFirmwareAPI(filters).then(res => {
            dispatch({
                type: GET_ALL_FIRMWARE_SUCCESS,
                payload: res && res.info || {},
                total: res.total
            })
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: GET_ALL_FIRMWARE_FAILED
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })


export const CREATE_FIRMWARE_REQUEST = 'CREATE_FIRMWARE_REQUEST';
export const CREATE_FIRMWARE_SUCCESS = 'CREATE_FIRMWARE_SUCCESS';
export const CREATE_FIRMWARE_FAILED = 'CREATE_FIRMWARE_FAILED';

export const createFirmware = (data) => dispatch =>

    new Promise((resolve, reject) => {
        dispatch({
            type: CREATE_FIRMWARE_REQUEST
        })
        createFirmwareAPI(data).then(res => {
            dispatch({
                type: CREATE_FIRMWARE_SUCCESS,
            })
            dispatch(commonActions.openSnackbarsState({ message: res && res.message }))
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: CREATE_FIRMWARE_FAILED
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })


export const UPDATE_FIRMWARE_REQUEST = 'UPDATE_FIRMWARE_REQUEST';
export const UPDATE_FIRMWARE_SUCCESS = 'UPDATE_FIRMWARE_SUCCESS';
export const UPDATE_FIRMWARE_FAILED = 'UPDATE_FIRMWARE_FAILED';

export const updateFirmware = (data) => dispatch =>

    new Promise((resolve, reject) => {
        dispatch({
            type: UPDATE_FIRMWARE_REQUEST
        })
        updateFirmwareAPI(data).then(res => {
            dispatch({
                type: UPDATE_FIRMWARE_SUCCESS,
            })
            dispatch(commonActions.openSnackbarsState({ message: res && res.message }))
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: UPDATE_FIRMWARE_FAILED
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })

export const DELETE_FIRMWARE_REQUEST = 'DELETE_FIRMWARE_REQUEST';
export const DELETE_FIRMWARE_SUCCESS = 'DELETE_FIRMWARE_SUCCESS';
export const DELETE_FIRMWARE_FAILED = 'DELETE_FIRMWARE_FAILED';

export const deleteFirmware = (id,deviceType) => dispatch =>

    new Promise((resolve, reject) => {
        dispatch({
            type: DELETE_FIRMWARE_REQUEST
        })
        deleteFirmwareAPI(id,deviceType).then(res => {
            dispatch({
                type: DELETE_FIRMWARE_SUCCESS,
            })
            dispatch(commonActions.openSnackbarsState({ message: res && res.message }))
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: DELETE_FIRMWARE_FAILED
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })

export const CLEAR_STORE_FOR_FIRMWARE_DATA = 'CLEAR_STORE_FOR_FIRMWARE_DATA';

export const clearStoreForFirmware = () => (dispatch) =>
    dispatch({
        type: CLEAR_STORE_FOR_FIRMWARE_DATA
    })
