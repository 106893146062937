import * as actionsTypes from './actions';

const initialState = {
    isLoading: false,
    allDevices: {},
    singleSafelinkDevice: {},
    smokeAlarmDevicesForTable: {},
    smokeAlarmDevicesIsLoading: false,
    deviceHistory: []
}

export default function (state = { ...initialState }, action) {
    switch (action.type) {

        case actionsTypes.GET_ALL_SAFELINK_DEVIECS_REQUEST:
            return { ...state, isLoading: true }

        case actionsTypes.GET_ALL_SAFELINK_DEVIECS_SUCCESS:
            return { ...state, isLoading: false, allDevices: action.payload || {} }

        case actionsTypes.GET_ALL_SAFELINK_DEVIECS_FAILED:
            return { ...state, isLoading: false }

        case actionsTypes.GET_SINGLE_SAFELINK_DEVIECS_REQUEST:
            return { ...state, isLoading: true }

        case actionsTypes.GET_SINGLE_SAFELINK_DEVIECS_SUCCESS:
            return { ...state, isLoading: false, singleSafelinkDevice: action.payload || {} }

        case actionsTypes.GET_SINGLE_SAFELINK_DEVIECS_FAILED:
            return { ...state, isLoading: false }

        case actionsTypes.GET_SMOKE_ALARM_TABLE_REQUEST:
            return { ...state, smokeAlarmDevicesIsLoading: true }

        case actionsTypes.GET_SMOKE_ALARM_TABLE_SUCCESS:
            return { ...state, smokeAlarmDevicesIsLoading: false, smokeAlarmDevicesForTable: action.payload }

        case actionsTypes.GET_SMOKE_ALARM_TABLE_FAILED:
            return { ...state, smokeAlarmDevicesIsLoading: false }

        case actionsTypes.GET_SAFELINK_DEVICE_HISTORY_REQUEST:
            return { ...state, isLoading: true }

        case actionsTypes.GET_SAFELINK_DEVICE_HISTORY_SUCCESS:
            return { ...state, isLoading: false, deviceHistory: action.payload }

        case actionsTypes.GET_SAFELINK_DEVICE_HISTORY_FAILED:
            return { ...state, isLoading: false }

        case actionsTypes.CLEAR_ALL_SAFELINK_DEVICE_DATA:
            return { ...state, ...initialState }

        default:
            return state
    }
}
