import {
    getAllInstallationAgentsAPI,
    getAgentDetailByIdAPI,
    updateInstallationAgentAPI,
    deleteInstallationAgentAPI,
    addInstallationAgentAPI,
    getAgentDevicesForTableAPI,
    // getAgentRatedByCustomerForTableAPI
} from './apis'
import *  as commonActions from "../../common/actions"
import { errorMessage } from '../../../utils'

export const GET_INSTALLATION_AGENTS_BY_AGENCY_REQUEST = 'GET_INSTALLATION_AGENTS_BY_AGENCY_REQUEST';
export const GET_INSTALLATION_AGENTS_BY_AGENCY_SUCCESS = 'GET_INSTALLATION_AGENTS_BY_AGENCY_SUCCESS';
export const GET_INSTALLATION_AGENT_BY_AGENCY_FAILED = 'GET_INSTALLATION_AGENT_BY_AGENCY_FAILED';

export const getAllInstallationAgents = (filters) => dispatch =>

    new Promise((resolve, reject) => {
        dispatch({
            type: GET_INSTALLATION_AGENTS_BY_AGENCY_REQUEST
        })
        getAllInstallationAgentsAPI(filters).then(res => {
            dispatch({
                type: GET_INSTALLATION_AGENTS_BY_AGENCY_SUCCESS,
                payload: res && res.info || {}
            })
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: GET_INSTALLATION_AGENT_BY_AGENCY_FAILED
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })

export const GET_INSTALLATION_AGENT_DETAIL_BY_ID_REQUEST = 'GET_INSTALLATION_AGENT_DETAIL_BY_ID_REQUEST';
export const GET_INSTALLATION_AGENT_DETAIL_BY_ID_SUCCESS = 'GET_INSTALLATION_AGENT_DETAIL_BY_ID_SUCCESS';
export const GET_INSTALLATION_AGENT_DETAIL_BY_ID_FAILED = 'GET_INSTALLATION_AGENT_DETAIL_BY_ID_FAILED';
export const CLEAR__AGENCY_AGENT_DETAIL_BY_ID = 'CLEAR__AGENCY_AGENT_DETAIL_BY_ID';

export const getAgentDetailById = (id) => dispatch =>
    new Promise((resolve, reject) => {
        dispatch({
            type: GET_INSTALLATION_AGENT_DETAIL_BY_ID_REQUEST
        })
        getAgentDetailByIdAPI(id).then(res => {
            dispatch({
                type: GET_INSTALLATION_AGENT_DETAIL_BY_ID_SUCCESS,
                payload: res && res.info,
                // rating: res.ratings
            })
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: GET_INSTALLATION_AGENT_DETAIL_BY_ID_FAILED
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })

export const clearStoreForAgencyAgent = () => {
    return (dispatch) => {
        dispatch({
            type: CLEAR__AGENCY_AGENT_DETAIL_BY_ID
        })
    }
}


export const ADD_INSTALLATION_AGENT_REQUEST = 'ADD_INSTALLATION_AGENT_REQUEST';
export const ADD_INSTALLATION_AGENT_SUCCESS = 'ADD_INSTALLATION_AGENT_SUCCESS';
export const ADD_INSTALLATION_AGENT_FAILED = 'ADD_INSTALLATION_AGENT_FAILED';

export const addInstallationAgent = (data) => dispatch =>
    new Promise((resolve, reject) => {

        dispatch({
            type: ADD_INSTALLATION_AGENT_REQUEST
        })
        addInstallationAgentAPI(data).then(res => {
            dispatch({
                type: ADD_INSTALLATION_AGENT_SUCCESS,
            })
            dispatch(commonActions.openSnackbarsState({ message: res && res.message }))
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: ADD_INSTALLATION_AGENT_FAILED
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })

export const UPDATE_INSTALLATION_AGENT_REQUEST = 'UPDATE_INSTALLATION_AGENT_REQUEST';
export const UPDATE_INSTALLATION_AGENT_SUCCESS = 'UPDATE_INSTALLATION_AGENT_SUCCESS';
export const UPDATE_INSTALLATION_AGENT_FAILED = 'UPDATE_INSTALLATION_AGENT_FAILED';

export const updateInstallationAgent = (data, agentID) => dispatch =>
    new Promise((resolve, reject) => {
        dispatch({
            type: UPDATE_INSTALLATION_AGENT_REQUEST
        })
        updateInstallationAgentAPI(data, agentID).then(res => {
            dispatch({
                type: UPDATE_INSTALLATION_AGENT_SUCCESS,
                payload: res
            })
            dispatch(commonActions.openSnackbarsState({ message: res && res.message }))
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: UPDATE_INSTALLATION_AGENT_FAILED
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })

export const DELETE_INSTALLATION_AGENT_REQUEST = 'DELETE_INSTALLATION_AGENT_REQUEST';
export const DELETE_INSTALLATION_AGENT_SUCCESS = 'DELETE_INSTALLATION_AGENT_REQUEST';
export const DELETE_INSTALLATION_AGENT_FAILED = 'DELETE_INSTALLATION_AGENT_REQUEST';

export const deleteInstallationAgent = (id) => dispatch =>
    new Promise((resolve, reject) => {
        dispatch({
            type: DELETE_INSTALLATION_AGENT_REQUEST
        })
        deleteInstallationAgentAPI(id).then(res => {
            dispatch({
                type: DELETE_INSTALLATION_AGENT_SUCCESS,
                payload: res
            })
            dispatch(commonActions.openSnackbarsState({ message: res && res.message }))
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: DELETE_INSTALLATION_AGENT_FAILED
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })

export const GET_AGENT_DEVICES_FOR_TABLE_REQUEST = "GET_AGENT_DEVICES_FOR_TABLE_REQUEST";
export const GET_AGENT_DEVICES_FOR_TABLE_SUCCESS = "GET_AGENT_DEVICES_FOR_TABLE_SUCCESS";
export const GET_AGENT_DEVICES_FOR_TABLE_FAILED = "GET_AGENT_DEVICES_FOR_TABLE_FAILED";

export const getAgentDevicesForTable = (filters) => (dispatch) => {
    return new Promise((resolve, reject) => {
        dispatch({
            type: GET_AGENT_DEVICES_FOR_TABLE_REQUEST
        })
        getAgentDevicesForTableAPI(filters).then(res => {
            const { info = {} } = res
            dispatch({
                type: GET_AGENT_DEVICES_FOR_TABLE_SUCCESS,
                payload: info
            })
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: GET_AGENT_DEVICES_FOR_TABLE_FAILED,
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })
}

// export const GET_AGENCY_AGENT_RATING_BY_CUSTOMER_FOR_TABLE_REQUEST = "GET_AGENCY_AGENT_RATING_BY_CUSTOMER_FOR_TABLE_REQUEST";
// export const GET_AGENCY_AGENT_RATING_BY_CUSTOMER_FOR_TABLE_SUCCESS = "GET_AGENCY_AGENT_RATING_BY_CUSTOMER_FOR_TABLE_SUCCESS";
// export const GET_AGENCY_AGENT_RATING_BY_CUSTOMER_FOR_TABLE_FAILED = "GET_AGENCY_AGENT_RATING_BY_CUSTOMER_FOR_TABLE_FAILED";

// export const getAgentRatedByCustomerForTable = (filters) => (dispatch) => {
//     return new Promise((resolve, reject) => {
//         dispatch({
//             type: GET_AGENCY_AGENT_RATING_BY_CUSTOMER_FOR_TABLE_REQUEST
//         })
//         getAgentRatedByCustomerForTableAPI(filters).then(res => {
//             const { info = {} } = res
//             dispatch({
//                 type: GET_AGENCY_AGENT_RATING_BY_CUSTOMER_FOR_TABLE_SUCCESS,
//                 payload: info
//             })
//             return resolve(res)
//         }).catch(err => {
//             dispatch({
//                 type: GET_AGENCY_AGENT_RATING_BY_CUSTOMER_FOR_TABLE_FAILED,
//             })
//             dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
//             return reject(err)
//         })
//     })
// }
