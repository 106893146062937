import { apiGet, getSelectedDeviceType, getDeviceType, isLoggedIn } from "../../../utils";
const { userType = "" } = isLoggedIn();

export function getUserCountAndVersionAPI() {
  return apiGet(
    `/api/${userType?.toLowerCase()}/dashboard/getusercountandversion?deviceType=${getDeviceType()}`
  );
}

export function getdevicegraphdataAPI(year) {
  return apiGet(
    `/api/${userType?.toLowerCase()}/dashboard/getdevicegraphdata?year=${year}&deviceType=${getDeviceType()}`
  );
}

export function getConsumptionGraphDataAPI(year) {
  return apiGet(
    `/api/${userType?.toLowerCase()}/dashboard/getdevicemonthlyconsumption?year=${year}&deviceType=${getDeviceType()}`
  );
}

export function getDeviceOverviewDataAPI() {
  return apiGet(
    `/api/${userType?.toLowerCase()}/dashboard/getdeviceoverview?deviceType=${getDeviceType()}`
  );
}

export function getDashboardDeviceByMonthAPI(filters, year, deviceStatus = "All") {
  let { page = 1, limit = 25, orderBy = "desc", sortBy = "created_at", month = "" } = filters || {};

  let deviceType = getSelectedDeviceType() || "electricity-advisor";

  let urlOption = {
    "electricity-advisor": "month-device",
    livelink: "month-device-livelink",
    safelink: "month-device-safelink",
    airlink: "month-device-airlink",
    bulb: "month-device-bulb",
    heat_pump: "month-device-heat_pump",
  };

  return apiGet(
    `/api/${userType?.toLowerCase()}/dashboard/${
      urlOption[deviceType]
    }?month=${month}&page=${page}&limit=${limit}&orderBy=${orderBy}&sortBy=${sortBy}&year=${year}&deviceStatus=${deviceStatus}`
  );
}

export function getConsumptionOverviewAPI() {
  return apiGet(
    `/api/${userType?.toLowerCase()}/dashboard/getdeviceconsumptionoverview?deviceType=${getDeviceType()}`
  );
}
