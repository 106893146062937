import { isEmpty } from "lodash";

export function aggregator(data) {
    let errors = {}

    if (!data.name) {
        errors.name = 'Name is required.'
    }

    return {
        isValid: isEmpty(errors),
        errors
    }
}