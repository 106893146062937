import React from 'react'
import CustomDate from './CustomDate'
import { FormControl, InputLabel, Select, MenuItem, Tooltip, } from '@material-ui/core'
import GetAppIcon from '@material-ui/icons/GetApp';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import RefreshIcon from '@material-ui/icons/Refresh';

function SearchField(props) {

    const {
        filters,
        onChange,
        placeholder = 'Search',
        onChangeSearchBy,
        searchByList = [],
        searchByLabel = {},
        searchByValueDropdownList = [],
        handleDateChange = () => { },
        setFocusInput = () => { },
        focusInput = null,
        clearFilter = () => { }
    } = props;

    const { term = '', searchBy = '' } = filters

    return (
        <div className="d-flex demo-select-test-select">
            <div className="demo-select-test">
                <FormControl variant="outlined" >
                    <InputLabel id="demo-simple-select-outlined-label">
                        Filter By
                    </InputLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={searchBy}
                        onChange={onChangeSearchBy}
                        className="filterBy-dropdown"
                    >
                        {
                            searchByList?.length ? searchByList.map((res, index) => {
                                return <MenuItem key={index} value={res.value}>{res.label}</MenuItem>
                            }) : []
                        }
                    </Select>
                </FormControl>
            </div>
            {
                searchBy && ["status", "firmware_version", "from_version", "to_version", "device_status", "upgradable"].includes(searchBy) ?
                    <div className="demo-select-test mr-2">
                        <FormControl variant="outlined" >
                            <InputLabel id="demo-simple-select-outlined-label">
                                Select {searchByLabel?.label}
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={term}
                                onChange={onChange}
                            >
                                {
                                    searchByValueDropdownList?.length ? searchByValueDropdownList.map((res, index) => {
                                        return <MenuItem key={index} value={res.value}>{res.label}</MenuItem>
                                    }) : []
                                }
                            </Select>
                        </FormControl>
                    </div>
                    : searchBy && ["installation_date", "created_at"].includes(searchBy) ?
                        <div item xs={12} sm={12} md={12} lg={12} className="mr-2">
                            <CustomDate
                                filters={filters}
                                handleDateChange={handleDateChange}
                                setFocusInput={setFocusInput}
                                focusInput={focusInput}
                            />
                        </div>
                        : searchBy ?
                            <div className="custom-search-field">
                                <input
                                    type="text"
                                    placeholder={placeholder + " " + searchByLabel?.label || ''}
                                    value={term}
                                    onChange={onChange}
                                />
                            </div> : null
            }
            {searchBy ? <div className="text-right d-flex download-btn-date" onClick={clearFilter}>
                <div className="download-btn">
                    <LightTooltip title="Clear Search Filter" >
                        <a
                            className={`download-csv devices`}
                            rel="noopener noreferrer"
                            target="_blank"
                            download>
                            <RefreshIcon />
                        </a>
                    </LightTooltip>
                </div>
            </div>
                : null}
        </div>
    )
}

export default SearchField

const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 12,
    },
}))(Tooltip);
