import {
    getAllAgentsAPI,
    getInstallerAgentAPI,
    updateInstallerAgentAPI,
    getAgentDeviceInfoAPI,
    getAgentDevicesForTableAPI,
    agentLoginPayloadForTableAPI,
    DownloadCSVAllAgentsAPI,
    // getAgentRatedByCustomerForTableAPI
} from './apis'
import *  as commonActions from "../../common/actions"
import { errorMessage } from '../../../utils'


export const GET_ADMIN_ALL_AGENTS_REQUEST = 'GET_ADMIN_ALL_AGENTS_REQUEST';
export const GET_ADMIN_ALL_AGENTS_SUCCESS = 'GET_ADMIN_ALL_AGENTS_SUCCESS';
export const GET_ADMIN_ALL_AGENTS_FAILED = 'GET_ADMIN_ALL_AGENTS_FAILED';

export const getAllAgents = (filters) => dispatch =>
    new Promise((resolve, reject) => {
        dispatch({
            type: GET_ADMIN_ALL_AGENTS_REQUEST
        })
        getAllAgentsAPI(filters).then(res => {
            dispatch({
                type: GET_ADMIN_ALL_AGENTS_SUCCESS,
                payload: res && res.info || {}
            })
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: GET_ADMIN_ALL_AGENTS_FAILED
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            reject(err)
        })
    })


export const GET_ADMIN_INSTALLER_AGENT_DETAIL_BY_ID_REQUEST = 'GET_ADMIN_INSTALLER_AGENT_DETAIL_BY_ID_REQUEST';
export const GET_ADMIN_INSTALLER_AGENT_DETAIL_BY_ID_SUCCESS = 'GET_ADMIN_INSTALLER_AGENT_DETAIL_BY_ID_SUCCESS';
export const GET_ADMIN_INSTALLER_AGENT_DETAIL_BY_ID_FAILED = 'GET_ADMIN_INSTALLER_AGENT_DETAIL_BY_ID_FAILED';

export const getInstallerAgent = (id) => dispatch =>
    new Promise((resolve, reject) => {
        dispatch({
            type: GET_ADMIN_INSTALLER_AGENT_DETAIL_BY_ID_REQUEST
        })
        getInstallerAgentAPI(id).then(res => {
            dispatch({
                type: GET_ADMIN_INSTALLER_AGENT_DETAIL_BY_ID_SUCCESS,
                payload: res.info,
                // rating: res.ratings
            })
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: GET_ADMIN_INSTALLER_AGENT_DETAIL_BY_ID_FAILED
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })

export const UPDATE_INSTALLER_AGENT_DETAIL_BY_ID_REQUEST = 'UPDATE_INSTALLER_AGENT_DETAIL_BY_ID_REQUEST';
export const UPDATE_INSTALLER_AGENT_DETAIL_BY_ID_SUCCESS = 'UPDATE_INSTALLER_AGENT_DETAIL_BY_ID_SUCCESS';
export const UPDATE_INSTALLER_AGENT_DETAIL_BY_ID_FAILED = 'UPDATE_INSTALLER_AGENT_DETAIL_BY_ID_FAILED';

export const updateInstallerAgent = (data) => dispatch =>
    new Promise((resolve, reject) => {
        dispatch({
            type: UPDATE_INSTALLER_AGENT_DETAIL_BY_ID_REQUEST
        })
        updateInstallerAgentAPI(data).then(res => {
            dispatch({
                type: UPDATE_INSTALLER_AGENT_DETAIL_BY_ID_SUCCESS,
            })
            dispatch(commonActions.openSnackbarsState({ message: res && res.message }))
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: UPDATE_INSTALLER_AGENT_DETAIL_BY_ID_FAILED
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })


export const CLEAR_STORE_FOR_AGENT_DETAIL = 'CLEAR_STORE_FOR_AGENT_DETAIL';

export const clearStoreForAgent = () => {
    return (dispatch) => {
        dispatch({
            type: CLEAR_STORE_FOR_AGENT_DETAIL
        })
    }
}

export const GET_AGENT_DEVICE_INFO_REQUEST = 'GET_AGENT_DEVICE_INFO_REQUEST';
export const GET_AGENT_DEVICE_INFO_SUCCESS = 'GET_AGENT_DEVICE_INFO_SUCCESS';
export const GET_AGENT_DEVICE_INFO_FAILED = 'GET_AGENT_DEVICE_INFO_FAILED';

export const getAgentDeviceInfo = (id) => dispatch =>

    new Promise((resolve, reject) => {
        dispatch({
            type: GET_AGENT_DEVICE_INFO_REQUEST
        })
        getAgentDeviceInfoAPI(id).then(res => {
            dispatch({
                type: GET_AGENT_DEVICE_INFO_SUCCESS,
                payload: res && res.info
            })
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: GET_AGENT_DEVICE_INFO_FAILED
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })


export const GET_ADMIN_AGENT_DEVICES_FOR_TABLE_REQUEST = "GET_ADMIN_AGENT_DEVICES_FOR_TABLE_REQUEST";
export const GET_ADMIN_AGENT_DEVICES_FOR_TABLE_SUCCESS = "GET_ADMIN_AGENT_DEVICES_FOR_TABLE_SUCCESS";
export const GET_ADMIN_AGENT_DEVICES_FOR_TABLE_FAILED = "GET_ADMIN_AGENT_DEVICES_FOR_TABLE_FAILED";

export const getAgentDevicesForTable = (filters) => (dispatch) => {
    return new Promise((resolve, reject) => {
        dispatch({
            type: GET_ADMIN_AGENT_DEVICES_FOR_TABLE_REQUEST
        })
        getAgentDevicesForTableAPI(filters).then(res => {
            const { info = {} } = res
            dispatch({
                type: GET_ADMIN_AGENT_DEVICES_FOR_TABLE_SUCCESS,
                payload: info
            })
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: GET_ADMIN_AGENT_DEVICES_FOR_TABLE_FAILED,
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })
}

export const GET_AGENT_LOGIN_PAYLOAD_FOR_TABLE_REQUEST = "GET_AGENT_LOGIN_PAYLOAD_FOR_TABLE_REQUEST";
export const GET_AGENT_LOGIN_PAYLOAD_FOR_TABLE_SUCCESS = "GET_AGENT_LOGIN_PAYLOAD_FOR_TABLE_SUCCESS";
export const GET_AGENT_LOGIN_PAYLOAD_FOR_TABLE_FAILED = "GET_AGENT_LOGIN_PAYLOAD_FOR_TABLE_FAILED";

export const getAgentPayloadForTable = (filters) => (dispatch) => {
    return new Promise((resolve, reject) => {
        dispatch({
            type: GET_AGENT_LOGIN_PAYLOAD_FOR_TABLE_REQUEST
        })
        agentLoginPayloadForTableAPI(filters).then(res => {
            const { info = {} } = res
            dispatch({
                type: GET_AGENT_LOGIN_PAYLOAD_FOR_TABLE_SUCCESS,
                payload: info
            })
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: GET_AGENT_LOGIN_PAYLOAD_FOR_TABLE_FAILED,
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })
}

// export const GET_AGENT_RATING_BY_CUSTOMER_FOR_TABLE_REQUEST = "GET_AGENT_RATING_BY_CUSTOMER_FOR_TABLE_REQUEST";
// export const GET_AGENT_RATING_BY_CUSTOMER_FOR_TABLE_SUCCESS = "GET_AGENT_RATING_BY_CUSTOMER_FOR_TABLE_SUCCESS";
// export const GET_AGENT_RATING_BY_CUSTOMER_FOR_TABLE_FAILED = "GET_AGENT_RATING_BY_CUSTOMER_FOR_TABLE_FAILED";

// export const getAgentRatedByCustomerForTable = (filters) => (dispatch) => {
//     return new Promise((resolve, reject) => {
//         dispatch({
//             type: GET_AGENT_RATING_BY_CUSTOMER_FOR_TABLE_REQUEST
//         })
//         getAgentRatedByCustomerForTableAPI(filters).then(res => {
//             const { info = {} } = res
//             dispatch({
//                 type: GET_AGENT_RATING_BY_CUSTOMER_FOR_TABLE_SUCCESS,
//                 payload: info
//             })
//             return resolve(res)
//         }).catch(err => {
//             dispatch({
//                 type: GET_AGENT_RATING_BY_CUSTOMER_FOR_TABLE_FAILED,
//             })
//             dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
//             return reject(err)
//         })
//     })
// }

export const DownloadCSVAllAgents = () => dispatch => {
    return new Promise((resolve, reject) => {
        DownloadCSVAllAgentsAPI().then(res => {
            return resolve(res)
        }).catch(err => {
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })
}





