import *  as commonActions from "../../../common/actions";
import {
    getAllSites,
    postSite,
    getMultiSiteContact,
    getMultiSiteCompaniesApiData
} from './apis'
import { errorMessage } from "../../../../utils";


// multi site company Data
export const GET_MSC_SITES_REQUEST = 'GET_MSC_SITES_REQUEST';
export const GET_MSC_SITES_SUCCESS = 'GET_MSC_SITES_SUCCESS';
export const GET_MSC_SITES_FAIED = 'GET_MSC_SITES_FAIED';

export const ADD_MSC_SITES_REQUEST = 'ADD_MSC_SITES_REQUEST';
export const ADD_MSC_SITES_SUCCESS = 'ADD_MSC_SITES_SUCCESS';
export const ADD_MSC_SITES_FAIED = 'ADD_MSC_SITES_FAIED';

// multi site contact Data
export const GET_MSC_SITES_CONTACT_REQUEST = 'GET_MSC_SITES_CONTACT_REQUEST';
export const GET_MSC_SITES_CONTACT_SUCCESS = 'GET_MSC_SITES_CONTACT_SUCCESS';
export const GET_MSC_SITES_CONTACT_FAIED = 'GET_MSC_SITES_CONTACT_FAIED';

// multi site contact Data
export const GET_MSC_SITES_COMPANIES_LIST_REQUEST = 'GET_MSC_SITES_COMPANIES_LIST_REQUEST';
export const GET_MSC_SITES_COMPANIES_LIST_SUCCESS = 'GET_MSC_SITES_COMPANIES_LIST_SUCCESS';
export const GET_MSC_SITES_COMPANIES_LIST_FAIED = 'GET_MSC_SITES_COMPANIES_LIST_FAIED';

export const getMscSites = (filters) => dispatch =>

    new Promise((resolve, reject) => {
        dispatch({
            type: GET_MSC_SITES_REQUEST
        })
        getAllSites(filters).then(res => {
            dispatch({
                type: GET_MSC_SITES_SUCCESS,
                payload: res.info
            })
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: GET_MSC_SITES_FAIED
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        });
    })


export const addSite = (data) => dispatch =>

    new Promise((resolve, reject) => {
        dispatch({
            type: ADD_MSC_SITES_REQUEST
        })
        postSite(data).then(res => {
            dispatch({
                type: ADD_MSC_SITES_SUCCESS,
            });
            dispatch(commonActions.openSnackbarsState({ message: res && res.message }))
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: ADD_MSC_SITES_FAIED
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        });
    })

// export const getMultiSiteContactData = (filters) => dispatch =>

//     new Promise((resolve, reject)=>{
//         dispatch({
//             type: GET_MSC_SITES_CONTACT_REQUEST
//         })
//         getMultiSiteContact(filters).then(res => {
//             dispatch({
//                 type: GET_MSC_SITES_CONTACT_SUCCESS,
//                 payload: res.info
//             })
//             return resolve(res)
//         }).catch(err => {
//             dispatch({
//                 type: GET_MSC_SITES_CONTACT_FAIED
//             })
//             dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
//             return reject(err)
//         });
//     })


export const getMultiSiteContactData = (filters) => dispatch =>

    new Promise((resolve, reject) => {
        dispatch({
            type: GET_MSC_SITES_CONTACT_REQUEST
        })
        getMultiSiteContact(filters).then(res => {
            dispatch({
                type: GET_MSC_SITES_CONTACT_SUCCESS,
                payload: res.info || {}
            })
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: GET_MSC_SITES_CONTACT_FAIED
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        });
    })

export const getMultiSiteCompaniesData = (filters) => dispatch =>

    new Promise((resolve, reject) => {
        dispatch({
            type: GET_MSC_SITES_COMPANIES_LIST_REQUEST
        })
        getMultiSiteCompaniesApiData(filters).then(res => {
            dispatch({
                type: GET_MSC_SITES_COMPANIES_LIST_SUCCESS,
                payload: res.info || {}
            })
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: GET_MSC_SITES_COMPANIES_LIST_FAIED
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        });
    })


