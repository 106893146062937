import * as actionsTypes from './actions';

const initialState = {
    isLoading: false,
    allElecricianAgents: {},
    electricianInstallerAgent: {},
    electricianAgentsForTable: {},
    electricianAgentsIsLoading: false,
}

export default function (state = { ...initialState }, action) {
    switch (action.type) {
        case actionsTypes.GET_DATAFORCE_AGENCY_AGENT_REQUEST:
            return { ...state, isLoading: true }

        case actionsTypes.GET_DATAFORCE_AGENCY_AGENT_SUCCESS:
            return { ...state, isLoading: false, allElecricianAgents: action.payload || {} }

        case actionsTypes.GET_DATAFORCE_AGENCY_AGENT_FAILED:
            return { ...state, isLoading: false }

        case actionsTypes.GET_DATAFORCE_INSTALLER_AGENTS_REQUEST:
            return { ...state, isLoading: true }

        case actionsTypes.GET_DATAFORCE_INSTALLER_AGENTS_SUCCESS:
            return { ...state, isLoading: false, electricianInstallerAgent: action.payload || {} }

        case actionsTypes.GET_DATAFORCE_INSTALLER_AGENTS_FAILED:
            return { ...state, isLoading: false }

        case actionsTypes.GET_DATAFORCE_AGENT_DEVICES_FOR_TABLE_REQUEST:
            return { ...state, electricianAgentsIsLoading: true }

        case actionsTypes.GET_DATAFORCE_AGENT_DEVICES_FOR_TABLE_SUCCESS:
            return { ...state, electricianAgentsIsLoading: false, electricianAgentsForTable: action.payload || {} }

        case actionsTypes.GET_DATAFORCE_AGENT_DEVICES_FOR_TABLE_FAILED:
            return { ...state, electricianAgentsIsLoading: false }

        case actionsTypes.CLEAR_DATAFORCE_AGENCY_AGENTS:
            return { ...state, ...initialState }

        default:
            return state

    }
}