import * as actionsTypes from './actions';

const initialState = {
    isLoading: false,
    allDevices: {},
    property_list: {},
    isSubmitBtnloading: false,
    isPropertyLoading: false
}

export default function (state = { ...initialState }, action) {
    switch (action.type) {

        case actionsTypes.GET_ALL_SITE_MANAGER_DEVIECS_REQUEST:
            return { ...state, isLoading: true }

        case actionsTypes.GET_ALL_SITE_MANAGER_DEVIECS_SUCCESS:
            return { ...state, isLoading: false, allDevices: action.payload || {} }

        case actionsTypes.GET_ALL_SITE_MANAGER_DEVIECS_FAILED:
            return { ...state, isLoading: false }

        case actionsTypes.CLEAR_ALL_DEVICE_DATA:
            return { ...state, ...initialState }
        
        case actionsTypes.GET_PROPERTY_LIST_REQUEST:
            return { ...state, isPropertyLoading: true }

        case actionsTypes.GET_PROPERTY_LIST_SUCCESS:
            return { ...state, isPropertyLoading: false, property_list: action.payload }

        case actionsTypes.GET_PROPERTY_LIST_FAILED:
            return { ...state, isPropertyLoading: false }

        case actionsTypes.SITE_MANAGER_ASSIGN_DEVICES_FAILED:
            return { ...state, isSubmitBtnloading: true }
    
        case actionsTypes.SITE_MANAGER_ASSIGN_DEVICES_SUCCESS:
            return { ...state, isSubmitBtnloading: false }

        case actionsTypes.SITE_MANAGER_ASSIGN_DEVICES_FAILED:
            return { ...state, isSubmitBtnloading: false }

        default:
            return state
    }
}