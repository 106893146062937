import { getAllSiteManagerDevicesAPI, assignSiteManagerDevicesApi, getAllProperty, deleteDeviceFromSiteApi, GetSiteManagerProfileAPI } from './apis'
import *  as commonActions from "../common/actions"
import { errorMessage } from '../../utils'

export const GET_ALL_SITE_MANAGER_DEVIECS_REQUEST = 'GET_ALL_SITE_MANAGER_DEVIECS_REQUEST';
export const GET_ALL_SITE_MANAGER_DEVIECS_SUCCESS = 'GET_ALL_SITE_MANAGER_DEVIECS_SUCCESS';
export const GET_ALL_SITE_MANAGER_DEVIECS_FAILED = 'GET_ALL_SITE_MANAGER_DEVIECS_FAILED';

export const getAllSiteManagerDevices = (filters) => dispatch =>

    new Promise((resolve, reject) => {
        dispatch({
            type: GET_ALL_SITE_MANAGER_DEVIECS_REQUEST
        })
        getAllSiteManagerDevicesAPI(filters).then(res => {
            dispatch({
                type: GET_ALL_SITE_MANAGER_DEVIECS_SUCCESS,
                payload: res && res.info || {}
            })
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: GET_ALL_SITE_MANAGER_DEVIECS_FAILED
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })

export const CLEAR_ALL_DEVICE_DATA = 'CLEAR_ALL_DEVICE_DATA';

export const clearStoreForAgencyDevice = () => {
    return (dispatch) => {
        dispatch({
            type: CLEAR_ALL_DEVICE_DATA
        })
    }
}

export const GET_PROPERTY_LIST_REQUEST = 'GET_PROPERTY_LIST_REQUEST';
export const GET_PROPERTY_LIST_SUCCESS = 'GET_PROPERTY_LIST_SUCCESS';
export const GET_PROPERTY_LIST_FAILED = 'GET_PROPERTY_LIST_FAILED';

export const getAllPropertyList = (filters) => dispatch =>

    new Promise((resolve, reject) => {
        dispatch({
            type: GET_PROPERTY_LIST_REQUEST
        })
        getAllProperty(filters).then(res => {
            dispatch({
                type: GET_PROPERTY_LIST_SUCCESS,
                payload: res && res.info || {}
            })
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: GET_PROPERTY_LIST_FAILED
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })

export const SITE_MANAGER_ASSIGN_DEVICES_REQUEST = 'SITE_MANAGER_ASSIGN_DEVICES_REQUEST';
export const SITE_MANAGER_ASSIGN_DEVICES_SUCCESS = 'SITE_MANAGER_ASSIGN_DEVICES_SUCCESS';
export const SITE_MANAGER_ASSIGN_DEVICES_FAILED = 'SITE_MANAGER_ASSIGN_DEVICES_FAILED';

export const assignSiteManagerDevices = (filters) => dispatch =>

    new Promise((resolve, reject) => {
        dispatch({
            type: SITE_MANAGER_ASSIGN_DEVICES_REQUEST
        })
        assignSiteManagerDevicesApi(filters).then(res => {
            dispatch({
                type: SITE_MANAGER_ASSIGN_DEVICES_SUCCESS,
            })
            dispatch(commonActions.openSnackbarsState({ message: res && res.message }))
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: SITE_MANAGER_ASSIGN_DEVICES_FAILED
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })

export const DELETE_DEVICE_FROM_SITE_REQUEST = "DELETE_DEVICE_FROM_SITE_REQUEST";
export const DELETE_DEVICE_FROM_SITE_SUCCESS = "DELETE_DEVICE_FROM_SITE_SUCCESS";
export const DELETE_DEVICE_FROM_SITE_FAILED = "DELETE_DEVICE_FROM_SITE_FAILED";

export const deleteDeviceFromSite = (obj) => (dispatch) => {
    return new Promise((resolve, reject) => {
        dispatch({
            type: DELETE_DEVICE_FROM_SITE_REQUEST
        })
        deleteDeviceFromSiteApi(obj).then(res => {
            dispatch({
                type: DELETE_DEVICE_FROM_SITE_SUCCESS,
            })
            dispatch(commonActions.openSnackbarsState({ message: res && res.message }))
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: DELETE_DEVICE_FROM_SITE_FAILED,
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })
}

export function getSiteManagerProfile() {
    return new Promise((resolve, reject) => {
        GetSiteManagerProfileAPI().then(res => {
            resolve(res)
        }).catch(err => {
            // dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            reject(err)
        })
    })
}
