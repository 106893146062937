import { getAllMultiSiteContactsAPI, postNewMultiSiteContact, updateMultiSiteContact } from './apis'
import *  as commonActions from "../../common/actions"
import { errorMessage } from '../../../utils'

export const GET_ALL_MULTI_SITE_CONTACTS_REQUEST = 'GET_ALL_MULTI_SITE_CONTACTS_REQUEST';
export const GET_ALL_MULTI_SITE_CONTACTS_SUCCESS = 'GET_ALL_MULTI_SITE_CONTACTS_SUCCESS';
export const GET_ALL_MULTI_SITE_CONTACTS_FAILED = 'GET_ALL_MULTI_SITE_CONTACTS_FAILED';

export const getAllMultiSiteContacts = (filters) => dispatch =>

    new Promise((resolve, reject) => {
        dispatch({
            type: GET_ALL_MULTI_SITE_CONTACTS_REQUEST
        })
        getAllMultiSiteContactsAPI(filters).then(res => {
            dispatch({
                type: GET_ALL_MULTI_SITE_CONTACTS_SUCCESS,
                payload: res && res.info || {}
            })
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: GET_ALL_MULTI_SITE_CONTACTS_FAILED
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })

export const ADD_NEW_MSC_CONTACT_REQUEST = "ADD_NEW_MSC_CONTACT_REQUEST";
export const ADD_NEW_MSC_CONTACT_SUCCESS = "ADD_NEW_MSC_CONTACT_SUCCESS";
export const ADD_NEW_MSC_CONTACT_FAIED = "ADD_NEW_MSC_CONTACT_FAIED";

export const addNewMultiSiteContact = (data, isEdit) => dispatch =>

    new Promise((resolve, reject) => {
        dispatch({
            type: ADD_NEW_MSC_CONTACT_REQUEST
        })
        const resp = isEdit ? updateMultiSiteContact(data) : postNewMultiSiteContact(data)

        resp.then(res => {
            dispatch({
                type: ADD_NEW_MSC_CONTACT_SUCCESS,
            });
            dispatch(commonActions.openSnackbarsState({ message: res && res.message }))
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: ADD_NEW_MSC_CONTACT_FAIED
            })
            // dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        });
    })