import * as commonActions from "../../common/actions";
import { errorMessage } from "../../../utils";
import {
  getAllFaultySystemAPI,
  getDeviceConsumptionHistoryMscId,
  getFaultyHistoryAPI,
  getFaultySystemAPIHistoryBySerialNoAPI,
  getMultiSiteByIdAPI,
  getMultiSiteDevicesForTableAPI,
  getSingleFaultySystemAPI,
  getSitesForTableAPI,
  newAgencyFaultAPI,
  updateFaultAPI,
} from "./apis";
import { DownloadCSVAllDevicesAPI } from "../../common/apis";

export const GET_AGENCY_FAULTY_SYSTEM_REQUEST = "GET_AGENCY_FAULTY_SYSTEM_REQUEST";
export const GET_AGENCY_FAULTY_SYSTEM_SUCCESS = "GET_AGENCY_FAULTY_SYSTEM_SUCCESS";
export const GET_AGENCY_FAULTY_SYSTEM_FAILED = "GET_AGENCY_FAULTY_SYSTEM_FAILED";

export const getallfaultySystems = (filters) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch({
      type: GET_AGENCY_FAULTY_SYSTEM_REQUEST,
    });
    getAllFaultySystemAPI(filters)
      .then((res) => {
        dispatch({
          type: GET_AGENCY_FAULTY_SYSTEM_SUCCESS,
          payload: (res && res.info) || {},
        });
        return resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: GET_AGENCY_FAULTY_SYSTEM_FAILED,
        });
        dispatch(
          commonActions.openSnackbarsState({ message: errorMessage(err), messageType: "error" })
        );
        return reject(err);
      });
  });

export const UPDATE_AGENCY_FAULTY_SYSTEM_REQUEST = "UPDATE_AGENCY_FAULTY_SYSTEM_REQUEST";
export const UPDATE_AGENCY_FAULTY_SYSTEM_SUCCESS = "UPDATE_AGENCY_FAULTY_SYSTEM_SUCCESS";
export const UPDATE_AGENCY_FAULTY_SYSTEM_FAILED = "UPDATE_AGENCY_FAULTY_SYSTEM_FAILED";

export const updatefaultySystem = (serialNo) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch({
      type: UPDATE_AGENCY_FAULTY_SYSTEM_REQUEST,
    });
    updateFaultAPI(serialNo)
      .then((res) => {
        const { info = [] } = res || {};
        dispatch({
          type: UPDATE_AGENCY_FAULTY_SYSTEM_SUCCESS,
          payload: info,
        });
        // dispatch(commonActions.openSnackbarsState({ message: res && res.message }))
        return resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: UPDATE_AGENCY_FAULTY_SYSTEM_FAILED,
        });
        dispatch(
          commonActions.openSnackbarsState({ message: errorMessage(err), messageType: "error" })
        );
        return reject(err);
      });
  });
export const DownloadCSVAllDevices = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    DownloadCSVAllDevicesAPI()
      .then((res) => {
        return resolve(res);
      })
      .catch((err) => {
        dispatch(
          commonActions.openSnackbarsState({ message: errorMessage(err), messageType: "error" })
        );
        return reject(err);
      });
  });
};

//** Make Actions for Multi-site Details for Agency  */

export const GET_MULTISITE_BY_ID_REQUEST = "GET_MULTISITE_BY_ID_REQUEST";
export const GET_MULTISITE_BY_ID_SUCESS = "GET_MULTISITE_BY_ID_SUCESS";
export const GET_MULTISITE_BY_ID_FAILED = "GET_MULTISITE_BY_ID_FAILED";
export const CLEAR_MULTISITE_DETAIL = "CLEAR_MULTISITE_DETAIL";

export const getMultiSiteDetails = (id) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch({
      type: GET_MULTISITE_BY_ID_REQUEST,
    });
    getMultiSiteByIdAPI(id)
      .then((res) => {
        dispatch({
          type: GET_MULTISITE_BY_ID_SUCESS,
          payload: res && res.info,
        });

        return resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: GET_MULTISITE_BY_ID_FAILED,
        });
        dispatch(
          commonActions.openSnackbarsState({ message: errorMessage(err), messageType: "error" })
        );
        return reject(err);
      });
  });

export const GET_DEVICE_CONSUMPTION_BY_MSCID_REQUEST = "GET_DEVICE_CONSUMPTION_BY_MSCID_REQUEST";
export const GET_DEVICE_CONSUMPTION_BY_MSCID_SUCCESS = "GET_DEVICE_CONSUMPTION_BY_MSCID_SUCCESS";
export const GET_DEVICE_CONSUMPTION_BY_MSCID_FAILED = "GET_DEVICE_CONSUMPTION_BY_MSCID_FAILED";

export const getDevicesConsumptionHistory = (id) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch({
      type: GET_DEVICE_CONSUMPTION_BY_MSCID_REQUEST,
    });
    getDeviceConsumptionHistoryMscId(id)
      .then((res) => {
        dispatch({
          type: GET_DEVICE_CONSUMPTION_BY_MSCID_SUCCESS,
          payload: res && res.info,
        });

        return resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: GET_DEVICE_CONSUMPTION_BY_MSCID_FAILED,
        });
        dispatch(
          commonActions.openSnackbarsState({ message: errorMessage(err), messageType: "error" })
        );
        return reject(err);
      });
  });

export const clearStoreForAMultiSiteDetails = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_MULTISITE_DETAIL,
    });
  };
};

export const GET_MULTI_SITE_DEVICES_FOR_TABLE_REQUEST = "GET_MULTI_SITE_DEVICES_FOR_TABLE_REQUEST";
export const GET_MULTI_SITE_DEVICES_FOR_TABLE_SUCCESS = "GET_MULTI_SITE_DEVICES_FOR_TABLE_SUCCESS";
export const GET_MULTI_SITE_DEVICES_FOR_TABLE_FAILED = "GET_MULTI_SITE_DEVICES_FOR_TABLE_FAILED";

export const getMultiSiteDevicesForTable = (filters) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: GET_MULTI_SITE_DEVICES_FOR_TABLE_REQUEST,
    });
    getMultiSiteDevicesForTableAPI(filters)
      .then((res) => {
        const { info = {} } = res;
        dispatch({
          type: GET_MULTI_SITE_DEVICES_FOR_TABLE_SUCCESS,
          payload: info,
        });

        return resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: GET_MULTI_SITE_DEVICES_FOR_TABLE_FAILED,
        });
        dispatch(
          commonActions.openSnackbarsState({ message: errorMessage(err), messageType: "error" })
        );
        return reject(err);
      });
  });
};

export const GET_SITES_FOR_TABLE_REQUEST = "GET_SITES_FOR_TABLE_REQUEST";
export const GET_SITES_FOR_TABLE_SUCCESS = "GET_SITES_FOR_TABLE_SUCCESS";
export const GET_SITES_FOR_TABLE_FAILED = "GET_SITES_FOR_TABLE_FAILED";

export const getSitesForTable = (filters) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: GET_SITES_FOR_TABLE_REQUEST,
    });
    getSitesForTableAPI(filters)
      .then((res) => {
        const { info = {} } = res;
        dispatch({
          type: GET_SITES_FOR_TABLE_SUCCESS,
          payload: info,
        });
        console.log("res=====-----", res);
        return resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: GET_SITES_FOR_TABLE_FAILED,
        });
        dispatch(
          commonActions.openSnackbarsState({ message: errorMessage(err), messageType: "error" })
        );
        return reject(err);
      });
  });
};
