import {
    getAllPartnersAPI,
    getPartnerAPI,
    getPartnerAgentsForTableAPI,
    getPartnerDevicesForTableAPI,
    getPartnerAgenciesForTableAPI
} from './apis'
import *  as commonActions from "../../common/actions"
import { errorMessage } from '../../../utils'

export const GET_ALL_PARTNERS_REQUEST = 'GET_ALL_PARTNERS_REQUEST';
export const GET_ALL_PARTNERS_SUCCESS = 'GET_ALL_PARTNERS_SUCCESS';
export const GET_ALL_PARTNERS_FAILED = 'GET_ALL_PARTNERS_FAILED';
// export const CLEAR_ALL_PARTNERS = 'CLEAR_ALL_PARTNERS';

export const getAllPartners = (filters) => dispatch =>
    new Promise((resolve, reject) => {
        dispatch({
            type: GET_ALL_PARTNERS_REQUEST
        })
        getAllPartnersAPI(filters).then(res => {
            dispatch({
                type: GET_ALL_PARTNERS_SUCCESS,
                payload: res && res.info || {}
            })
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: GET_ALL_PARTNERS_FAILED
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })

export const GET_PARTNER_BY_ID_REQUEST = 'GET_PARTNER_BY_ID_REQUEST';
export const GET_PARTNER_BY_ID_SUCESS = 'GET_PARTNER_BY_ID_SUCESS';
export const GET_PARTNER_BY_ID_FAILED = 'GET_PARTNER_BY_ID_FAILED';
export const CLEAR_PARTNER_DETAIL = 'CLEAR_PARTNER_DETAIL';

export const getPartner = id => dispatch =>

    new Promise((resolve, reject) => {
        dispatch({
            type: GET_PARTNER_BY_ID_REQUEST
        })
        getPartnerAPI(id).then(res => {
            dispatch({
                type: GET_PARTNER_BY_ID_SUCESS,
                payload: res && res.info
            })
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: GET_PARTNER_BY_ID_FAILED
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })

export const GET_PARTNER_DEVICES_FOR_TABLE_REQUEST = "GET_PARTNER_DEVICES_FOR_TABLE_REQUEST";
export const GET_PARTNER_DEVICES_FOR_TABLE_SUCCESS = "GET_PARTNER_DEVICES_FOR_TABLE_SUCCESS";
export const GET_PARTNER_DEVICES_FOR_TABLE_FAILED = "GET_PARTNER_DEVICES_FOR_TABLE_FAILED";

export const getPartnerDevicesForTable = (filters) => (dispatch) => {
    return new Promise((resolve, reject) => {
        dispatch({
            type: GET_PARTNER_DEVICES_FOR_TABLE_REQUEST
        })
        getPartnerDevicesForTableAPI(filters).then(res => {
            const { info = {} } = res
            dispatch({
                type: GET_PARTNER_DEVICES_FOR_TABLE_SUCCESS,
                payload: info
            })
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: GET_PARTNER_DEVICES_FOR_TABLE_FAILED,
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })
}


export const GET_PARTNER_AGENCIES_FOR_TABLE_REQUEST = "GET_PARTNER_AGENCIES_FOR_TABLE_REQUEST";
export const GET_PARTNER_AGENCIES_FOR_TABLE_SUCCESS = "GET_PARTNER_AGENCIES_FOR_TABLE_SUCCESS";
export const GET_PARTNER_AGENCIES_FOR_TABLE_FAILED = "GET_PARTNER_AGENCIES_FOR_TABLE_FAILED";

export const getPartnerAgenciesForTable = (filters) => (dispatch) => {
    return new Promise((resolve, reject) => {
        dispatch({
            type: GET_PARTNER_AGENCIES_FOR_TABLE_REQUEST
        })
        getPartnerAgenciesForTableAPI(filters).then(res => {
            const { info = {} } = res
            dispatch({
                type: GET_PARTNER_AGENCIES_FOR_TABLE_SUCCESS,
                payload: info
            })
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: GET_PARTNER_AGENCIES_FOR_TABLE_FAILED,
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })
}

export const GET_PARTNER_AGENTS_FOR_TABLE_REQUEST = "GET_PARTNER_AGENTS_FOR_TABLE_REQUEST";
export const GET_PARTNER_AGENTS_FOR_TABLE_SUCCESS = "GET_PARTNER_AGENTS_FOR_TABLE_SUCCESS";
export const GET_PARTNER_AGENTS_FOR_TABLE_FAILED = "GET_PARTNER_AGENTS_FOR_TABLE_FAILED";

export const getPartnerAgentsForTable = (filters) => (dispatch) => {
    return new Promise((resolve, reject) => {
        dispatch({
            type: GET_PARTNER_AGENTS_FOR_TABLE_REQUEST
        })
        getPartnerAgentsForTableAPI(filters).then(res => {
            const { info = {} } = res
            dispatch({
                type: GET_PARTNER_AGENTS_FOR_TABLE_SUCCESS,
                payload: info
            })
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: GET_PARTNER_AGENTS_FOR_TABLE_FAILED,
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })
}

export const CLEAR_STORE_FOR_PARTNERS = 'CLEAR_STORE_FOR_PARTNERS';

export const clearStoreForPartners = () => {
    return (dispatch) => {
        dispatch({
            type: CLEAR_STORE_FOR_PARTNERS
        })
    }
}
