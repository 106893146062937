import * as actionsTypes from './actions';

const initialState = {
    isLoading: false,
    allAgencies: {},
    agency: {},
    rating: {},
    agencyDevicesForTable: {},
    agencyDevicesIsLoading: false,
    agencyAgentsForTable: {},
    agencyAgentsIsLoading: false,
    // ratingCustomerList: {},
    // ratingCustomerListIsLoading: false
}

export default function (state = { ...initialState }, action) {
    switch (action.type) {

        case actionsTypes.GET_ALL_AGENCIES_REQUEST:
            return { ...state, isLoading: true }

        case actionsTypes.GET_ALL_AGENCIES_SUCCESS:
            return { ...state, isLoading: false, allAgencies: action.payload || {} }

        case actionsTypes.GET_ALL_AGENCIES_FAILED:
            return { ...state, isLoading: false }

        case actionsTypes.CLEAR_ALL_AGENCIES:
            return { ...state, allAgencies: {} }

        case actionsTypes.GET_AGENCY_BY_ID_REQUEST:
            return { ...state, isLoading: true }

        case actionsTypes.GET_AGENCY_BY_ID_SUCESS:
            return { ...state, isLoading: false, agency: action.payload || {}, rating: action.rating || {} }

        case actionsTypes.GET_AGENCY_BY_ID_FAILED:
            return { ...state, isLoading: false }

        case actionsTypes.UPDATE_AGENCY_REQUEST:
            return { ...state, isLoading: true }

        case actionsTypes.UPDATE_AGENCY_SUCCESS:
            return { ...state, isLoading: false }

        case actionsTypes.UPDATE_AGENCY_FAILED:
            return { ...state, isLoading: false }

        case actionsTypes.CREATE_AGENCY_REQUEST:
            return { ...state, isLoading: true }

        case actionsTypes.CREATE_AGENCY_SUCCESS:
            return { ...state, isLoading: false }

        case actionsTypes.CREATE_AGENCY_FAILED:
            return { ...state, isLoading: false }

        case actionsTypes.DELETE_AGENCY_REQUEST:
            return { ...state, isLoading: true }

        case actionsTypes.DELETE_AGENCY_SUCCESS:
            return { ...state, isLoading: false }

        case actionsTypes.DELETE_AGENCY_FAILED:
            return { ...state, isLoading: false }

        case actionsTypes.GET_AGENCY_DEVICES_FOR_TABLE_REQUEST:
            return { ...state, agencyDevicesIsLoading: true }

        case actionsTypes.GET_AGENCY_DEVICES_FOR_TABLE_SUCCESS:
            return { ...state, agencyDevicesIsLoading: false, agencyDevicesForTable: action.payload || {} }

        case actionsTypes.GET_AGENCY_DEVICES_FOR_TABLE_FAILED:
            return { ...state, agencyDevicesIsLoading: false }

        case actionsTypes.GET_AGENCY_AGENTS_FOR_TABLE_REQUEST:
            return { ...state, agencyAgentsIsLoading: true }

        case actionsTypes.GET_AGENCY_AGENTS_FOR_TABLE_SUCCESS:
            return { ...state, agencyAgentsIsLoading: false, agencyAgentsForTable: action.payload || {} }

        case actionsTypes.GET_AGENCY_AGENTS_FOR_TABLE_FAILED:
            return { ...state, agencyAgentsIsLoading: false }

        // case actionsTypes.GET_AGENCY_RATING_BY_CUSTOMER_FOR_TABLE_REQUEST:
        //     return { ...state, ratingCustomerListIsLoading: true }

        // case actionsTypes.GET_AGENCY_RATING_BY_CUSTOMER_FOR_TABLE_SUCCESS:
        //     return { ...state, ratingCustomerListIsLoading: false, ratingCustomerList: action.payload || {} }

        // case actionsTypes.GET_AGENCY_RATING_BY_CUSTOMER_FOR_TABLE_FAILED:
        //     return { ...state, ratingCustomerListIsLoading: false }

        case actionsTypes.CLEAR_AGENCY_DETAIL:
            return { ...state, ...initialState }


        default:
            return state
    }
}