import React, { useEffect, useState, useCallback, useRef, useMemo } from "react";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  InputLabel,
  Paper,
  TextField,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Input from "../common/Input";
import * as actions from "../../containers/Agency/AgencyDevices/actions";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import Validation from "../../containers/Validation";
import { openGlobalDialog } from "../../containers/common/actions";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import {
  addNewMultiSiteCompany,
  getAllMultiSiteCompanies,
  updateMultiSiteCompany,
} from "../../containers/Admin/MultiSiteCompany/actions";
import { isLoggedIn } from "../../utils";
import { getAllAgencies } from "../../containers/Admin/InstallerAgencies/actions";
import AccountAdminEmailInfo from "../common/AccountAdminEmailInfo";
import SearchGooglePlace from "../common/searchGooglePlace";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const initialFilter = {
  limit: 25,
  serial_number: "",
  page: 1,
  sortBy: "created_at",
  orderBy: "desc",
};

const initialState = {
  company_name: "",
  company_address: "",
  contact_person_email: "",
};

export default function AddMultisite(props) {
  const { oldData, addDevices } = props?.globalModal?.data || {};
  const { userType = null } = isLoggedIn();

  const dispatch = useDispatch();
  const [data, setData] = useState({ ...initialState });
  const [agenciesList, setAgenciesList] = useState([]);
  const { isLoading = false, devices = [] } = useSelector((state) => state?.OTAGroupReducer);
  const [errors, setErrors] = useState({});
  const [open, setOpen] = React.useState(false);
  const [isSubmitBtnloading, setIsSubmitBtnloading] = React.useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoadingPagination, setIsLoadingPagination] = useState(false);
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);

  const debouncedFetchData = useCallback(
    _.debounce((query, page, agenciesList) => {
      getAgencies(query, page, agenciesList);
    }, 500),
    []
  );

  const getAgencies = (query = "", currentPage = 1, currentAgencyList = []) => {
    dispatch(getAllAgencies({ agency_name: query, page: currentPage, limit: 25 })).then((res) => {
      if (currentPage > page) {
        setAgenciesList([...currentAgencyList, ...(res?.info?.data ?? [])]);
      } else {
        setAgenciesList(res?.info?.data || []);
      }
      setIsLoadingPagination(false);
      setPage(res?.info?.current_page);
      setLastPage(res?.info?.last_page);
    });
  };

  const closeModal = () => {
    if (userType == "Admin") {
      dispatch(getAllMultiSiteCompanies({}, userType));
    } else if (userType === "Agency") {
      dispatch(actions.getAllAgencyMultiSiteCompanies({}));
    }
    props.closeModal();
    setData({ ...initialState });
  };

  const handleInputChange = (event, value) => {
    setSearchQuery(value.trim());
    setIsLoadingPagination(true);
    debouncedFetchData(value.trim());
  };

  const onAgencyChange = (event, value) => {
    if (value && value.length > 0) {
      setData({ ...data, agency_ids: value });
      setErrors({ ...errors, agency_ids: "" });
    } else {
      setData({ ...data, agency_ids: "" });
      setErrors({ ...errors, agency_ids: "Please Select Agency" });
    }
  };

  const onChangeGroupField = ({ target: { value = "", type = "text", name = "" } }) => {
    if (name == "company_name" && value && value.length > 60) {
      return;
    }
    setData({ ...data, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  const isValid = () => {
    let isValid = true;
    let error_msgs = {};
    if (userType === "Admin") {
      if (!data.agency_ids || data.agency_ids?.length == 0) {
        isValid = false;
        error_msgs.agency_ids = "Please Select Agency";
      } else {
        error_msgs.agency_ids = "";
      }
    }
    if (!data.company_name) {
      isValid = false;
      error_msgs.company_name = "Company name is required";
    } else {
      error_msgs.company_name = "";
    }

    if (!data.company_address?.label) {
      isValid = false;
      error_msgs.company_address = "Company Address is required";
    } else {
      error_msgs.company_address = "";
    }

    if (!data.contact_person_email) {
      isValid = false;
      error_msgs.contact_person_email = "Contact Person Email is required";
    } else if (!data.contact_person_email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
      isValid = false;
      error_msgs.contact_person_email = "Please enter a valid email address";
    } else {
      error_msgs.contact_person_email = "";
    }
    setErrors(error_msgs);
    return isValid;
  };

  const onSubmit = () => {
    setErrorMessage("");
    if (isValid()) {
      setIsSubmitBtnloading(true);
      const request = {
        ...data,
        company_address: data.company_address?.label,
        agency_ids: data.agency_ids && data.agency_ids?.map((agency) => agency.id),
      };
      dispatch(
        oldData
          ? updateMultiSiteCompany(request, userType)
          : addNewMultiSiteCompany(request, userType)
      )
        .then((res) => {
          if (props?.globalModal?.data?.openCheckModal) {
            dispatch(
              openGlobalDialog(
                {
                  addDevices: addDevices,
                },
                "MULTISITE_MODAL"
              )
            );
          } else {
            if (props?.globalModal?.data?.refreshData) {
              props?.globalModal?.data?.refreshData();
            }
            closeModal();
          }
        })
        .catch((err) => {
          setIsSubmitBtnloading(false);
          setErrorMessage(
            err && err.response && err.response.data && err.response.data.message
              ? err.response.data.message
              : "Something went wrong"
          );
          console.log(err.message);
        });
    }
  };

  const CustomPaper = (props) => {
    return <Paper {...props} style={{ border: "1px solid black", borderRadius: "4px" }} />;
  };

  const handleScroll = (event) => {
    const listboxNode = event.currentTarget;
    if (
      listboxNode.scrollTop + listboxNode.clientHeight >= listboxNode.scrollHeight &&
      !isLoadingPagination &&
      page < lastPage
    ) {
      setIsLoadingPagination(true);
      getAgencies(searchQuery, page + 1, agenciesList);
    }
  };

  const isAllFieldContainsValues = useMemo(() => {
    return !Object.values(data)?.some((value) => !value);
  }, [data]);

  useEffect(() => {
    if (oldData) {
      const agency_ids = oldData?.agencies;
      const { id, company_name, company_address, contact_person_email } = oldData;

      setData({
        id,
        company_name,
        company_address: { value: company_address, label: company_address },
        contact_person_email,
        agency_ids,
      });
    }
  }, [oldData]);

  useEffect(() => {
    if (userType === "Admin") {
      getAgencies("", 1, []);
    }
  }, []);

  // let mergeOptionsArray = [...devices, ...selectedDevice]
  // let uniqueOptions = mergeOptionsArray.filter((elem, index) => mergeOptionsArray.findIndex(obj => obj.value === elem.value) === index);

  console.log("data", data);

  return (
    <>
      <DialogTitle className="heat_pump_modal_title">
        <Grid item md={12} className="d-flex align-items-center">
          <span
            className="d-flex align-items-center mr-2 cus-pointer"
            onClick={
              props?.globalModal?.data?.openCheckModal
                ? () =>
                    dispatch(
                      openGlobalDialog(
                        {
                          addDevices: addDevices,
                        },
                        "MULTISITE_MODAL"
                      )
                    )
                : () => props.closeModal()
            }
          >
            <ArrowBackIcon style={{ color: "#94A3B8" }} />
          </span>
          <span className="multi-site-head">
            {oldData ? "Update Multi-Site Company" : "New Multi-Site Company"}
          </span>
        </Grid>
      </DialogTitle>
      <DialogContent dividers className="heat_pump_dialog_content">
        {userType == "Admin" && (
          <Grid item md={12} className="mb-3">
            <InputLabel className="inputlabelHeatPumpModal">Select Agency</InputLabel>
            <Autocomplete
              id="asynchronous-demo"
              sx={{ width: 300 }}
              multiple
              open={open} // Control whether the dropdown is open
              onOpen={() => {
                setOpen(true); // Keep it open
              }}
              onClose={(event, reason) => {
                if (reason !== "blur") {
                  setOpen(false); // Close only when clicking outside
                }
              }}
              name="agency_ids"
              isOptionEqualToValue={(option, value) => option.agency_name === value.agency_name}
              getOptionLabel={(option) => option.agency_name}
              options={agenciesList || []}
              //   loading={loading}
              // PaperComponent={CustomPaper}
              value={data.agency_ids}
              onInputChange={handleInputChange}
              onChange={onAgencyChange}
              loading={isLoadingPagination}
              //   loadingText={<CircularProgress size={24} />}
              // onClose={() => false}
              ListboxProps={{
                onScroll: handleScroll, // Attach the scroll event handler for infinite scrolling
                // ref: listboxRef,
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label=""
                  className="inputfieldHeatPumpModalRemove"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {isLoadingPagination ? (
                          <CircularProgress
                            className="heatpump_dropdownloader"
                            color="inherit"
                            size={20}
                          />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
            {errors.agency_ids && <span className="profile-error">{errors.agency_ids}</span>}
          </Grid>
        )}

        <Grid item md={12} className="mb-3">
          <InputLabel className="inputlabelHeatPumpModal">Company Name</InputLabel>
          <Input
            variant="outlined"
            fullWidth
            name="company_name"
            className="inputfieldHeatPumpModal"
            label=""
            type="text"
            value={data.company_name || ""}
            onChange={onChangeGroupField}
          />
          {errors.company_name && <span className="profile-error">{errors.company_name}</span>}
        </Grid>
        <Grid item md={12} className="mb-3">
          <InputLabel className="inputlabelHeatPumpModal">Company Address</InputLabel>
          <SearchGooglePlace
            value={data.company_address || ""}
            onChange={(value) => {
              setData((prev) => ({ ...prev, company_address: value }));
            }}
          />
          {errors.company_address && (
            <span className="profile-error">{errors.company_address}</span>
          )}
        </Grid>
        <Grid item md={12} className="">
          <InputLabel className="inputlabelHeatPumpModal">
            <Box display="flex" alignItems="center" gridGap={2}>
              <span>Account Admin Email </span>
              <AccountAdminEmailInfo />
            </Box>
          </InputLabel>

          <Input
            variant="outlined"
            className="inputfieldHeatPumpModal"
            fullWidth
            disabled={!!oldData}
            name="contact_person_email"
            label={``}
            type="text"
            value={data.contact_person_email || ""}
            onChange={onChangeGroupField}
          />
          {errors.contact_person_email && (
            <span className="profile-error">{errors.contact_person_email}</span>
          )}
        </Grid>
        <Grid item md={12} className="mb-3">
          {errorMessage && <span className="profile-error">{errorMessage}</span>}
        </Grid>
      </DialogContent>
      <Grid>
        <DialogActions className="heat_pump_dialog_action">
          <Button
            className={`cus-btn-modal-submit ${
              isLoading || !isAllFieldContainsValues ? "disabled" : ""
            }`}
            onClick={isSubmitBtnloading ? null : onSubmit}
          >
            {isSubmitBtnloading ? (
              <CircularProgress size={18} color="white" />
            ) : oldData ? (
              "Update"
            ) : (
              "Add"
            )}
          </Button>
        </DialogActions>
      </Grid>
    </>
  );
}
