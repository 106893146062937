import { apiPost, apiGet } from "../../utils";

export function loginAPI(data) {
  return apiPost("/api/authenticate/login", data);
}

export function verifyUserAPI(data) {
  return apiPost("/api/authenticate/login-otp", data);
}

export function signupAPI(data) {
  data = {
    agency_name: data.companyName,
    email: data.companyEmail,
    abn_no: data.ABN,
    contact: data.phone,
    password: data.password,
  };
  return apiPost("/api/agency/sign-up", data);
}

export function forgotPasswordAPI(data) {
  return apiPost("/api/reset-password-request", data);
}

export function resetPasswordAPI(data, obj) {
  obj = {
    password: data.password,
    confirm_password: data.confirmPassword,
    token: obj.token,
    email: obj.email,
    type: obj.type,
  };
  return apiPost("/api/reset-password", obj);
}

export function getProfileAPI() {
  return apiGet("/api/agency/getprofile");
}

export function updateProfileAPI(data) {
  const formData = new FormData();

  if (data?.password && data?.password?.length) {
    formData.append("password", data.password);
  }

  let flow = data?.flows ? data?.flows?.filter((res) => res) : [];

  formData.append("agency_name", data?.agencyName);
  formData.append("email", data?.email);
  formData.append("postcode", data?.postcode);
  formData.append("contact", data?.contactNo);
  formData.append("country", data?.country);
  formData.append(
    "state",
    (data?.state?.value && data?.state?.value !== null) || data?.state?.value !== undefined
      ? data?.state?.value
      : ""
  );
  formData.append("city", data?.city);
  formData.append("street_address", data?.streetAddress);
  formData.append("plot_address", data?.plotAddress);
  formData.append("contact_first_name", data?.contactFirstName);
  formData.append("contact_last_name", data?.contactLastName);
  formData.append("status", data?.status);
  formData.append("email_notification", data?.email_notification);
  formData.append("flows", flow.join(","));

  return apiPost("/api/agency/editprofile", formData);
}

export function refreshTokenAPI() {
  return apiPost("/api/token-refresh");
}

export function resendSignupVerificationTokenAPI(data) {
  return apiPost("/api/send-verification-mail", data);
}

export function electricianForgotPasswordAPI(data) {
  return apiPost("/api/electrician/reset-password-request", data);
}

export function electricianResetPasswordAPI(data, obj) {
  obj = {
    password: data.password,
    confirm_password: data.confirmPassword,
    token: obj.token,
    email: obj.email,
  };
  return apiPost("/api/electrician/reset-password", obj);
}
