import { apiGet, apiPut } from '../../../utils';


export function getAdminProfileAPI() {
    return apiGet(`/api/profile`)
}

export function getCustomerProfileAPI() {
    return apiGet(`/api/nms/customer/profile`)
}
export function updateAdminProfileAPI(data) {
    return apiPut(`/api/profile/update`,data)
}
export function updateCustomerProfileAPI(data) {
    return apiPut(`/api/nms/customer/profile/update`,data)
}