import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Popover from "@material-ui/core/Popover";

const useStyles = makeStyles(() => ({
    customWidth: {
        fontSize: 15,
        color: 'black',
        backgroundColor: '#fff',
        padding: '16px',
        wordBreak: 'break-word'
    }
}));


export default function MoreText(props) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);

    let {
        data = "",
        textStrong = false,
        textSize=5
    } = props


    const handleClick = (event) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (event) => {
        event.stopPropagation();
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    if (data && data.length >= textSize) {
        return (
            <div className="d-flex">
                <p className="pr-1">{data.slice(0, textSize)}</p>
                <p className="text-more" onClick={handleClick}>{open ? ' Show Less' : 'Show More'}</p>
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={e => handleClose(e)}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center"
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "center"
                    }}
                >
                    <p className={classes.customWidth}>
                        {data}
                    </p>
                </Popover>
            </div>
        )
    }

    return (
        <React.Fragment>
            { textStrong ? <strong className="pl-5">{data}</strong> : data}
        </React.Fragment >
    );
}