import * as actionTypes from './actions'

const initialState = {
    adminProfileInfo: {},
    isLoading: true
}

export default function (state = { ...initialState }, action) {

    switch (action.type) {

        case actionTypes.GET_ADMIN_PROFILE_REQUEST:
            return { ...state, isLoading: true }

        case actionTypes.GET_ADMIN_PROFILE_SUCCESS:
            return { ...state, isLoading: false, adminProfileInfo: action.payload || {} }

        case actionTypes.GET_ADMIN_PROFILE_FAILED:
            return { ...state, isLoading: false }
        
        case actionTypes.UPDATE_ADMIN_PROFILE_REQUEST:
            return { ...state, isLoading: true }
            
        case actionTypes.UPDATE_ADMIN_PROFILE_SUCCESS:
            return { ...state, isLoading: false, adminProfileInfo: action.payload || {} }
        
        case actionTypes.UPDATE_ADMIN_PROFILE_FAILED:
            return { ...state, isLoading: true }

        default:
            return state

    }


}