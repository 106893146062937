import { apiGet, apiPost, apiPut } from '../../../utils';
import moment from 'moment';
import axios from 'axios';


export function getAllSmokeAlarmDevicesAPI(filters) {
    let arr = []

    for (const [key, value] of Object.entries(filters)) {

        if (key === "isOpen") continue

        if (typeof value !== "number" && !value?.length) continue

        if (key === "installation_date") {
            if (value[0] == null) continue
            arr.push({
                key,
                value: moment(filters.installation_date[0]).format("DD-MM-YYYY"),
                end_value: moment(filters.installation_date[1]).format("DD-MM-YYYY"),
                type: 'single'
            })
        }
        else if (["device_status", "customer_status"].includes(key)) {
            arr.push({ key, value, type: 'multi' })
        } else {
            arr.push({ key, value: encodeURIComponent(value), type: "single" })
        }
    }

    return apiGet(`/api/safelink/smokealarm/list?filter=${JSON.stringify(arr)}`, null, 'safelink')
}

export function getSingleSmokeAlarmDeviceAPI(id) {
    return apiGet(`/api/safelink/smokealarm/info/${id}`, null, 'safelink')
}

export function getSmokeAlarmDeviceForTableAPI(filters) {
    let { page = 1, limit = 25, orderBy = "desc", sortBy = 'created_at', id = '' } = filters || {}

    return apiGet(`/api/electrician/get-devices?electrician_id=${'31d5056c-72a9-4f5a-9162-e3d99dfb7d79'}&page=${page}&limit=${limit}&orderBy=${orderBy}&sortBy=${sortBy}`)
}

