import axios from "axios";
import qs from "query-string";
// import * as commonActions from '../../../common/actions'
import config from "./config";
import moment from "moment";
import history from "./components/common/history";
// import { isDevelopment } from './constant'

const { apiUrl = "", apiUrlSafelink = "" } = config || {};

export function saveObject(key = "", value = "") {
  if (window && window.localStorage) {
    window.localStorage.setItem(key, value);
  }
}

export function getObject(name) {
  if (window && window.localStorage) {
    return window.localStorage.getItem(name);
  }
  return false;
}

export function removeObject(key) {
  localStorage.removeItem(key);
}

export function generateUrl(path, device) {
  // console.log(apiUrl, apiUrlSafelink, device, "SSSSSSSSSSSSSSSSSSsss")
  if (path.includes("http")) {
    return path;
  }

  if (["safelink", "airlink"].includes(device)) {
    // console.log("RRRRRRRRRRRR")
    return apiUrlSafelink + path;
  } else {
    return apiUrl + path;
  }
}

export function getHeaders() {
  let userToken = getObject("access-token");
  // let deviceType = getObject('selectedProduct')
  // if (deviceType === 'electricity-advisor') {
  //     deviceType = 'ihd'
  // }
  if (userToken && userToken.length)
    return {
      Authorization: `Bearer ${userToken || null}`,
      // "Type": deviceType
    };
  return {};
}

export function apiReq(endPoint, data, method, headers) {
  return new Promise((res, rej) => {
    headers = {
      ...getHeaders(),
      ...headers,
      // "Access-Control-Allow-Origin": "*"
    };

    if (method === "get" || method === "delete") {
      data = {
        params: data,
        paramsSerializer: function (params) {
          return qs.stringify(params);
        },
        headers,
      };
    }

    axios[method](endPoint, data, { headers })
      .then((result) => {
        let { data = {} } = result || {};
        if (data && data.status === false) {
          return rej(data);
        }
        return res(data);
      })
      .catch((err) => {
        let { code = 0 } = (err && err.response && err.response.data) || {};
        // if ((code === 401) && (message = 'Session has Expired')) {
        // store.dispatch(commonActions.openDeleteModal({
        //     confirmAction: deleteCustomer,
        //     title: 'Confirmation',
        //     dialogtext: 'Are you sure you want to delete this Customer?'
        // }))
        // store.dispatch(commanActions.openTokenDialog())
        // return
        // }
        // if (code === 403 || code === 401) {
        //     logOut("access-token")
        //     logOut("login-user-info")
        //     history.push('/login')
        // }
        return rej(err);
      });
  });
}

export function apiPost(endPoint, data, device = "", headers = {}) {
  return apiReq(generateUrl(endPoint, device), data, "post", headers);
}

export function apiDelete(endPoint, data, device = "", headers = {}) {
  return apiReq(generateUrl(endPoint, device), data, "delete", headers);
}

export function apiGet(endPoint, data, device = "", headers = {}) {
  return apiReq(generateUrl(endPoint, device), data, "get", headers);
}

export function apiPut(endPoint, data, device = "", headers = {}) {
  return apiReq(generateUrl(endPoint, device), data, "put", headers);
}
export function apiPatch(endPoint, data, device = "", headers = {}) {
  return apiReq(generateUrl(endPoint, device), data, "patch", headers);
}

export const scrollIntoView = (label, top = 90) => {
  var elmnt = document.getElementById(label);

  if (elmnt) {
    let position = elmnt.getBoundingClientRect();
    // scrolls to 90px above element
    window.scrollTo({
      top: position.top + window.scrollY - top,
      behavior: "smooth",
    });
  }
};

export function capitalizeFirstLetter(string = "") {
  if (string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  return string;
}

export const errorMessage = (error) => {
  if (error.message === "Network Error") {
    return "Oops! Please check your internet connection.";
  }
  return (
    (error && error.response && error.response.data && error.response.data.message) ||
    "Something went wrong"
  );
};

export function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export function stableSort(array = [], comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  return stabilizedThis.map((el) => el[0]);
}

export const tablestyle = (theme) => ({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 600,
  },
  [theme.breakpoints.up("md")]: {
    container: {
      // maxHeight: 440
    },
  },
});

//Function For Managinig Table Rows Functionality
export function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function isLoggedIn(userType) {
  let accessToken = JSON.parse(getObject("login-user-info")) || {};
  if (accessToken.id) return { isAuthenticated: true, userType: accessToken.user_type };
  else return { isAuthenticated: false };
}

export function logOut(userType) {
  return new Promise((res, rej) => {
    localStorage.removeItem(userType);
    res(true);
  });
}

export function doConsole(msg = "", title = "") {
  if (config.isDevelopment) {
  } else return;
}

export function getWeekendsByWeekdays(weekdays = {}, weekends = {}) {
  let updatedWeekends = {};

  updatedWeekends = {
    offPeak: weekdays.offPeak.map((item, index) => {
      let editableOffPeak = weekends.offPeak[index];
      if (!!editableOffPeak) {
        editableOffPeak = {
          ...item,
          weekDestributionId: weekends.offPeak[index].weekDestributionId,
        };
      } else {
        editableOffPeak = { ...item, weekDestributionId: "" };
      }
      return { ...editableOffPeak };
    }),
    other: weekdays.other.map((item, index) => {
      let editableOther = weekends.other[index];
      if (!!editableOther) {
        editableOther = { ...item, weekDestributionId: weekends.other[index].weekDestributionId };
      } else {
        editableOther = { ...item, weekDestributionId: "" };
      }
      return { ...editableOther };
    }),
    peak: weekdays.peak.map((item, index) => {
      let editablePeak = weekends.peak[index];
      if (!!editablePeak) {
        editablePeak = { ...item, weekDestributionId: weekends.peak[index].weekDestributionId };
      } else {
        editablePeak = { ...item, weekDestributionId: "" };
      }
      return { ...editablePeak };
    }),
    shoulder: weekdays.shoulder.map((item, index) => {
      let editableShoulderPeak = weekends.shoulder[index];
      if (!!editableShoulderPeak) {
        editableShoulderPeak = {
          ...item,
          weekDestributionId: weekends.shoulder[index].weekDestributionId,
        };
      } else {
        editableShoulderPeak = { ...item, weekDestributionId: "" };
      }
      return { ...editableShoulderPeak };
    }),
  };

  return updatedWeekends;
}

export function dateWithoutTime(date) {
  if (date) {
    if (getSelectedDeviceType() == "heat_pump") {
      return moment(date).format("DD/MM/YYYY");
    }
    return moment(date).format("DD-MM-YYYY");
  } else return;
}

export function dateTimeWithoutGMT(date) {
  if (date) {
    return moment(date).format("DD/MM/YYYY | hh:mm A");
  } else return;
}

export function dateWithTime(date) {
  // dd/mm/yyyy
  if (date) {
    let year = moment(date).format("YYYY");

    let dateFormat = moment(date).format("YYYY-MM-DD");
    let testDate = new Date(dateFormat);

    const WinterStart = new Date(`${year}-04-01`);
    const WinterEnd = new Date(`${year}-09-30`);
    // console.log("win")
    if (testDate >= WinterStart && testDate <= WinterEnd) {
      if (getSelectedDeviceType() == "heat_pump") {
        date = moment(date).format("DD/MM/YYYY | hh:mm A");
      } else {
        date = moment(date).format("DD-MM-YYYY | hh:mm A");
      }

      return `${date}  (+10GMT)`;
    } else {
      date = moment(date).format("DD-MM-YYYY | hh:mm A");
      if (getSelectedDeviceType() == "heat_pump") {
      } else {
        date = moment(date).format("DD/MM/YYYY | hh:mm A");
      }
      return `${date}  (+11GMT)`;
    }
  } else return;
}

export function formatTime(date) {
  if (!date) return;

  const isValidDate = moment(date).isValid();
  if (!isValidDate) return;

  let timeFormat = moment(date).format("HH:MM");
  return timeFormat;
}

export function formatDateTime(date) {
  if (!date) return;

  const isValidDate = moment(date).isValid();
  if (!isValidDate) return;

  let timeFormat = moment(date).format("DD/MM/YYYY | HH:mm");
  return timeFormat;
}

export const parseKey = (key) => {
  if (!key) return "";

  if (key) {
    return key.replace(/_/g, " ");
  }
};

export function handleClickTableCheckbox(name, selected) {
  const selectedIndex = selected.indexOf(name);
  let newSelected = [];
  if (selectedIndex === -1) {
    newSelected = newSelected.concat(selected, name);
  } else if (selectedIndex === 0) {
    newSelected = newSelected.concat(selected.slice(1));
  } else if (selectedIndex === selected.length - 1) {
    newSelected = newSelected.concat(selected.slice(0, -1));
  } else if (selectedIndex > 0) {
    newSelected = newSelected.concat(
      selected.slice(0, selectedIndex),
      selected.slice(selectedIndex + 1)
    );
  }

  return newSelected;
}

export function handleTableCheckbox(item, selected, allFirmwareTargetVersion, allUpgradeTarget) {
  let matchedItem = selected.find((value) => value.id === item.id);

  if (matchedItem?.id) {
    let index = selected.findIndex((value) => value.id === item.id);
    selected.splice(index, 1);
  } else {
    if (allFirmwareTargetVersion)
      item = {
        ...item,
        available_firmware: allFirmwareTargetVersion,
        upgradable: allUpgradeTarget,
      };
    if (allUpgradeTarget) item = { ...item, upgradable: allUpgradeTarget };
    selected.push(item);
  }
  return selected;
}

export function setSurveyQuestionValue(
  tempSurveyQuestion,
  value,
  key1,
  key2,
  key3,
  inputType,
  textType
) {
  let val = "";
  switch (inputType) {
    case "Text":
      switch (textType) {
        case "Integer":
          val = value.target.value;
          if (!val.match(/^[0-9]*$/)) return tempSurveyQuestion;
          break;
        case "Decimal":
          val = value.target.value;
          if (!val.match(/^\d*\.?\d*$/)) return tempSurveyQuestion;
          break;
        default:
          break;
      }
      break;
    case "Dropdown":
      val = value?.value || {};
      break;
    case "Dichotomous":
      val = value;
      break;
    default:
      val = "";
      break;
  }
  if (key3 !== null) {
    tempSurveyQuestion[key1].sub_questions[key2].sub_questions[key3].given_answer = val;
  } else if (key2 !== null) {
    tempSurveyQuestion[key1].sub_questions[key2].given_answer = val;
    if (
      tempSurveyQuestion[key1].sub_questions[key2].question_type === "Dropdown" &&
      tempSurveyQuestion[key1].sub_questions[key2].question === "Type of air conditioner"
    ) {
      tempSurveyQuestion[key1].sub_questions[key2].sub_questions[0].given_answer = "";
    }
  } else if (key1 !== null) {
    tempSurveyQuestion[key1].given_answer = val;
    if (
      val === "No" &&
      (tempSurveyQuestion[key1]?.question_type === "Dichotomous" ||
        tempSurveyQuestion[key1]?.question_type === "Dropdown") &&
      tempSurveyQuestion[key1]?.sub_questions?.length
    ) {
      tempSurveyQuestion[key1].sub_questions[0].given_answer = "";
      if (tempSurveyQuestion[key1]?.sub_questions[0]?.sub_questions?.length) {
        tempSurveyQuestion[key1].sub_questions[0].sub_questions[0].given_answer = "";
      }
    }
  } else return tempSurveyQuestion;

  return tempSurveyQuestion;
}

export function convertURLToStringify(filters) {
  let { status = [], ...rest } = filters;
  status = status.map((res) => res.title);
  let stringRestFilters = qs.stringify({ ...rest });
  let statusInString = qs.stringify({ status: status }, { arrayFormat: "bracket" });
  return statusInString + "&" + stringRestFilters;
}

export function impulseRateConversion(rate = "", type = "") {
  // if (rate == null) { rate = '' }
  if (!rate || !type) return rate + type;

  let impRate = "";
  if (type === "Wh/imp") {
    impRate = 1000 / rate;
    return (impRate = impRate + type);
  } else {
    return (impRate = rate + type);
  }
}

export function propertyAddressFormat(
  unit_number = "",
  street_number = "",
  street = "",
  city = "",
  state = "",
  postal_code = ""
) {
  let address = "";

  if (unit_number) {
    address += unit_number + ",";
  }
  if (street_number) {
    address += street_number + ",";
  }
  if (street) {
    address += street + ",";
  }
  if (city) {
    address += city + ",";
  }
  if (state) {
    address += state + ",";
  }
  if (postal_code) {
    address += postal_code;
  }

  return address;
}

export function addressFormat(addressKey) {
  let address = "";
  addressKey?.forEach((key, idx) => {
    if (key) {
      address += idx === addressKey?.length - 1 ? key : key + ",";
    }
  });
  return address;
}

export function agentNameFormat(firstName, lastName) {
  let fullName = "";
  if (firstName) {
    fullName += firstName + " ";
  }
  if (lastName) {
    fullName += lastName;
  }

  return fullName;
}

export function getFullName(firstName, lastName) {
  let fullName = "";
  if (firstName) {
    fullName += firstName + " ";
  }
  if (lastName) {
    fullName += lastName;
  }

  return fullName;
}

export function passwordValidationRegex(password) {
  let regex = {
    upperCase: /[A-Z]/,
    lowerCase: /[a-z]/,
    digit: /[0-9]/,
  };

  let emailError = {};

  if (!regex.upperCase.test(password)) {
    emailError.noUpperCase = true;
  }

  if (!regex.lowerCase.test(password)) {
    emailError.noLowerCase = true;
  }

  if (!regex.digit.test(password)) {
    emailError.noDigit = true;
  }
  if (password.length < 8) {
    emailError.noLength = true;
  }

  return emailError;
}

export const downloadFile = (fileLink, fileName) => {
  return new Promise((resolve, reject) => {
    if (fileLink) {
      let link = document.createElement("a");
      link.href = fileLink;
      link.download = `${fileName}.csv`;
      link.click();
      return resolve(true);
    } else {
      return reject(false);
    }
  });
};

export const getMonthName = (index) => {
  let monthNamelist = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  return monthNamelist[index];
};

export function getYearsDynamic(startYear = 2021) {
  let selectYear = startYear;
  let currentYear = new Date().getFullYear();
  // let today = new Date();
  const yearData = [];
  for (let i = selectYear; i <= currentYear; i++) {
    yearData.push({ label: i, value: i });
  }
  return yearData.reverse();
}

export const getRatingFormat = (average_rating) => {
  if (average_rating && average_rating.toString().includes(".")) {
    return (
      average_rating.toString().split(".")[0] + "." + average_rating.toString().split(".")[1][0]
    );
  }
  return average_rating;
};

export function getSelectedDeviceType() {
  if (window && window.localStorage) {
    return window.localStorage.getItem("selectedProduct");
  }
  return false;
}

export function getDeviceType() {
  if (window && window.localStorage) {
    let deviceType = window.localStorage.getItem("selectedProduct");
    if (deviceType === "electricity-advisor") {
      deviceType = "ihd";
    }
    return deviceType;
  }
  return false;
}

export const permissionType = {
  restricted: "Allow once",
  denied: "Denied",
  authorizedAlways: "Always",
  authorizedWhenInUse: "Allow while using the app",
  notDetermined: "Ask next time or when i share",
};

export const trimFilters = (filters = {}) => {
  let newObj = {};
  Object.keys(filters).forEach((filterKey) => {
    if (typeof filters[filterKey] === "string") {
      newObj = { ...newObj, [filterKey]: filters[filterKey]?.trim() };
    } else {
      newObj = { ...newObj, [filterKey]: filters[filterKey] };
    }
  });
  return newObj;
};

export const getTextFormat = (texts = "") => {
  let newText = "";
  let textArr = texts.split("");
  if (!texts.includes("_")) {
    textArr.forEach((text, idx) => {
      if (text.toUpperCase() === texts.charAt(idx)) {
        newText = newText + " " + text;
      } else if (idx === 0) {
        newText += text.toLocaleUpperCase();
      } else if (text === "_") {
        newText = newText + " ";
      } else {
        newText += text;
      }
    });
  } else {
    let splitCharIndx = textArr.indexOf("_");
    let leftText = "";
    let rightText = "";
    textArr.forEach((item, idx) => {
      if (idx < splitCharIndx) {
        leftText += item;
      }
      if (idx > splitCharIndx) {
        rightText += item;
      }
    });
    rightText = rightText.replace(rightText[0], rightText[0]?.toUpperCase());
    leftText = leftText.replace(leftText[0], leftText[0]?.toUpperCase());
    newText = leftText + " " + rightText;
  }

  return newText;
};

export const IotDevices = [
  { name: "Electricity Advisor", key: "ihd" },
  { name: "SafeLink", key: "safelink" },
  { name: "LiveLink", key: "livelink" },
  { name: "AirLink", key: "airlink" },
  { name: "Lighting", key: "bulb" },
];

export const getWifiSignalStrengthImage = (wifiValue) => {
  if (wifiValue) {
    let wifi = wifiValue?.replace("db", "")?.replace(" ", "");
    const wifiRSSI = Math.abs(parseInt(wifi, 10));

    if (!isNaN(wifiRSSI)) {
      if (wifiRSSI >= 76 && wifiRSSI <= 100) {
        return <img src="/images/WifiSignalLow.svg" alt="WifiSignalLow" />;
      } else if (wifiRSSI >= 65 && wifiRSSI <= 75) {
        return <img src="/images/WifiSignalMedium.svg" alt="WifiSignalMedium" />;
      } else if (wifiRSSI >= 51 && wifiRSSI <= 64) {
        return <img src="/images/WifiSignalHigh.svg" alt="WifiSignalHigh" />;
      } else if (wifiRSSI >= 0 && wifiRSSI <= 50) {
        return <img src="/images/WifiSignalHighest.svg" alt="WifiSignalHighest" />;
      }
    }
  }
  return <img src="/images/no_wi-fi.svg" alt="NoWiFi" />;
};

export const getWifiSignalStrengthValue = (filterValue) => {
  if (filterValue) {
    if (filterValue === "excellent") {
      return { value: -51, end_value: 0 };
    } else if (filterValue === "good") {
      return { value: -65, end_value: -51 };
    } else if (filterValue === "medium") {
      return { value: -76, end_value: -65 };
    } else if (filterValue === "poor") {
      return { value: -100, end_value: -76 };
    }
  }
  return { value: -100, end_value: 0 };
};

export const wifiSignalStregthFilters = [
  { value: "excellent", label: "Excellent" },
  { value: "good", label: "Good" },
  { value: "medium", label: "Medium" },
  { value: "poor", label: "Poor" },
];

export const generateUrlWithUserType = (userType, url) => {
  return `/api${
    userType === "Admin"
      ? ""
      : userType === "sm"
      ? "/sm/nms"
      : userType === "mscc"
      ? "/mscc/nms"
      : userType === "Agency"
      ? "/agency/nms"
      : `/${userType?.toLowerCase()}/`
  }${url}`;
};

export const initialDashBoardCounts = {
  offline_devices: 0,
  online_devices: 0,
  total_devices: 0,
  faulty_count: 0,
};

export const getDashboardCounts = (dashboardCountDataList = [], setState = () => {}, ref = {}) => {
  if (dashboardCountDataList && dashboardCountDataList?.length > 0) {
    const dashboardCounts = { ...initialDashBoardCounts };

    for (let item of dashboardCountDataList) {
      if (item.is_online === 1) {
        dashboardCounts.online_devices = dashboardCounts.online_devices + 1;
      } else if (item.is_online === 0) {
        dashboardCounts.offline_devices = dashboardCounts.offline_devices + 1;
      }

      if (item.fault_code) {
        dashboardCounts.faulty_count = dashboardCounts.faulty_count + 1;
      }
    }

    setState({ ...dashboardCounts, total_devices: dashboardCountDataList.length });
    ref.current = dashboardCountDataList;
  }
};

export const updateDashboardCountsFromSocket = (
  ref,
  socketData,
  updateDashboardCountDataList,
  setDashboardCountNo
) => {
  const dashboardCountData = ref?.current;

  if (dashboardCountData && dashboardCountData?.length > 0) {
    const oldDashboardCountData = [...(dashboardCountData || [])];

    if (socketData.mac_address) {
      const isMacAddressExist = dashboardCountData.some((item) =>
        item.mac_address.includes(socketData.mac_address)
      );

      if (isMacAddressExist) {
        const index = oldDashboardCountData.findIndex((obj) =>
          obj.mac_address.includes(socketData.mac_address)
        );
        const oldData = oldDashboardCountData[index];
        oldDashboardCountData.splice(index, 1, { ...oldData, is_online: socketData.is_online });
        updateDashboardCountDataList(oldDashboardCountData);
      }
      // else {
      //   oldDashboardCountData.push({ ...socketData });
      //   updateDashboardCountDataList(oldDashboardCountData);
      // }
    } else if (socketData.device_id) {
      const deviceExist = dashboardCountData.some((item) => item.device_id == socketData.device_id);

      if (deviceExist) {
        const index = oldDashboardCountData.findIndex(
          (obj) => obj.device_id == socketData.device_id
        );
        const oldData = oldDashboardCountData[index];
        oldDashboardCountData.splice(index, 1, {
          ...oldData,
          fault_code: socketData.fault_code,
        });
        updateDashboardCountDataList(oldDashboardCountData);
      }
    }
    getDashboardCounts(oldDashboardCountData, setDashboardCountNo, ref);
  }
};
