import { apiGet, getDeviceType } from '../../../utils';
import moment from 'moment';

export function getAllOTAAPIHistoryAPI(filters) {
    let arr = []
    for (const [key, value] of Object.entries(filters)) {

        if (key === "isOpen") continue

        if (typeof value !== "number" && !value?.length) continue

        if (key === "created_at") {
            if (value[0] == null) continue
            arr.push({
                key,
                value: moment(filters.created_at[0]).format("DD-MM-YYYY"),
                end_value: moment(filters.created_at[1]).format("DD-MM-YYYY"),
                type: 'single'
            })
        }
        else if (["status"].includes(key)) {
            arr.push({ key, value, type: 'multi' })
        } else {
            arr.push({ key, value, type: "single" })
        }
    }
    return apiGet(`/api/ota/history?filter=${JSON.stringify(arr)}&deviceType=${getDeviceType()}`)

}