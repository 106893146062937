import { apiPost, apiGet, apiDelete, apiPut } from "../../../utils";

export function addNewAggregatorAPI(data) {
    return apiPost('/api/aggregators/add', data)
}

export function getAllAggregatorsAPI(filters) {
    let arr = []
    for (const [key, value] of Object.entries(filters)) {
        if (key === "isOpen") continue
        if (typeof value !== "number" && !value?.length) continue

        if (["status"].includes(key)) {
            arr.push({ key, value, type: 'multi' })
        } else {
            arr.push({ key, value, type: "single" })
        }
    }
    return apiGet(`/api/aggregators/get/all?filter=${JSON.stringify(arr)}`)
}

export function updateAggregatorAPI(data) {
    return apiPut(`/api/aggregators/edit/${data.id}`, data)
}

export function deleteAggregatorAPI(id) {
    return apiDelete(`/api/aggregators/delete/${id}`)
}


