import * as actionsTypes from './actions';

const initialState = {
    getMscLoding: false,
    allMscs: {},
}


export default function reducer(state = { ...initialState }, action) {

    switch (action.type) {

        case actionsTypes.GET_MSC_REQUEST:
            return { ...state, getMscLoding: true }

        case actionsTypes.GET_MSC_SUCCESS:
            return { ...state, getMscLoding: false, allMscs: action.payload }

        case actionsTypes.GET_MSC_FAILED:
            return { ...state, getMscLoding: false }

        default:
            return state
    }

}