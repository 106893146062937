import * as actionsTypes from './actions';

const initialState = {
    isLoading: false,
    allMscSiteManagers: {},
    agency_msc: {},
    isSubmitBtnloading: false
}

export default function (state = { ...initialState }, action) {
    switch (action.type) {

        case actionsTypes.GET_ALL_MSC_SITE_MANAGERS_REQUEST:
            return { ...state, isLoading: true }

        case actionsTypes.GET_ALL_MSC_SITE_MANAGERS_SUCCESS:
            return { ...state, isLoading: false, allMscSiteManagers: action.payload || {} }

        case actionsTypes.GET_ALL_MSC_SITE_MANAGERS_FAILED:
            return { ...state, isLoading: false }

        case actionsTypes.CLEAR_ALL_DEVICE_DATA:
            return { ...state, ...initialState }

        case actionsTypes.ADD_MSC_NEW_SITE_MANAGER_REQUEST:
            return { ...state, isSubmitBtnloading: true }
    
        case actionsTypes.ADD_MSC_NEW_SITE_MANAGER_SUCCESS:
            return { ...state, isSubmitBtnloading: false }

        case actionsTypes.ADD_MSC_NEW_SITE_MANAGER_FAILED:
            return { ...state, isSubmitBtnloading: false }

        default:
            return state
    }
}