import React from "react"
import ContentLoader from "react-content-loader"
import { useDispatch, useSelector } from 'react-redux'

const TableLoaderStyle = {
  padding: 16,
  minHeight: 400
}

function TableLoader(props) {
  let { isDarkMode = false } = useSelector(state => state.common)
  return (
    <div style={TableLoaderStyle}>
      <ContentLoader
        speed={2}
        width="100%"
        height={400}
        // viewBox="0 0 100% 600"
        backgroundColor={isDarkMode ? "#2a3243" : "#f3f3f3"}
        foregroundColor={isDarkMode ? "#2a3243" : "#ecebeb"}
        {...props}
      >
        <rect x="0" y="0" width="100%" height="32" />
        <rect x="0" y="50" width="100%" height="32" />
        <rect x="0" y="100" width="100%" height="32" />
        <rect x="0" y="150" width="100%" height="32" />
        <rect x="0" y="200" width="100%" height="32" />
        <rect x="0" y="250" width="100%" height="32" />
        <rect x="0" y="300" width="100%" height="32" />
        <rect x="0" y="350" width="100%" height="32" />
      </ContentLoader>
    </div>
  )
}

export default TableLoader
