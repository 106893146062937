import {
    getAllAgenciesAPI,
    getAgencyAPI,
    updateAgencyAPI,
    createAgencyAPI,
    deleteAgencyAPI,
    getAgencyDevicesForTableAPI,
    getAgencyAgentForTableAPI,
    downloadCSVAllAgenciesAPI,
    getAgencyRatedByCustomerForTableAPI
} from './apis'
import *  as commonActions from "../../common/actions"
import { errorMessage } from '../../../utils'

export const GET_ALL_AGENCIES_REQUEST = 'GET_ALL_AGENCIES_REQUEST';
export const GET_ALL_AGENCIES_SUCCESS = 'GET_ALL_AGENCIES_SUCCESS';
export const GET_ALL_AGENCIES_FAILED = 'GET_ALL_AGENCIES_FAILED';
export const CLEAR_ALL_AGENCIES = 'CLEAR_ALL_AGENCIES';

export const getAllAgencies = (filters) => dispatch =>
    new Promise((resolve, reject) => {
        dispatch({
            type: GET_ALL_AGENCIES_REQUEST
        })
        getAllAgenciesAPI(filters).then(res => {
            dispatch({
                type: GET_ALL_AGENCIES_SUCCESS,
                payload: res && res.info || {}
            })
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: GET_ALL_AGENCIES_FAILED
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })

export const clearStoreForAllAgency = () => {
    return (dispatch) => {
        dispatch({
            type: CLEAR_ALL_AGENCIES
        })
    }
}

export const GET_AGENCY_BY_ID_REQUEST = 'GET_AGENCY_BY_ID_REQUEST';
export const GET_AGENCY_BY_ID_SUCESS = 'GET_AGENCY_BY_ID_SUCESS';
export const GET_AGENCY_BY_ID_FAILED = 'GET_AGENCY_BY_ID_FAILED';
export const CLEAR_AGENCY_DETAIL = 'CLEAR_AGENCY_DETAIL';

export const getAgency = id => dispatch =>

    new Promise((resolve, reject) => {
        dispatch({
            type: GET_AGENCY_BY_ID_REQUEST
        })
        getAgencyAPI(id).then(res => {
            dispatch({
                type: GET_AGENCY_BY_ID_SUCESS,
                payload: res && res.info,
                // rating: res && res.ratings
            })
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: GET_AGENCY_BY_ID_FAILED
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })

export const clearStoreForAgency = () => {
    return (dispatch) => {
        dispatch({
            type: CLEAR_AGENCY_DETAIL
        })
    }
}

export const UPDATE_AGENCY_REQUEST = 'UPDATE_AGENCY_REQUEST';
export const UPDATE_AGENCY_SUCCESS = 'UPDATE_AGENCY_SUCCESS';
export const UPDATE_AGENCY_FAILED = 'UPDATE_AGENCY_FAILED';

export const updateAgency = (data, id) => dispatch =>
    new Promise((resolve, reject) => {
        dispatch({
            type: UPDATE_AGENCY_REQUEST
        })
        updateAgencyAPI(data, id).then(res => {
            dispatch({
                type: UPDATE_AGENCY_SUCCESS,
                payload: res
            })
            dispatch(commonActions.openSnackbarsState({ message: res && res.message }))
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: UPDATE_AGENCY_FAILED
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })


export const CREATE_AGENCY_REQUEST = 'CREATE_AGENCY_REQUEST';
export const CREATE_AGENCY_SUCCESS = 'CREATE_AGENCY_SUCCESS';
export const CREATE_AGENCY_FAILED = 'CREATE_AGENCY_FAILED';

export const createAgency = data => dispatch =>
    new Promise((resolve, reject) => {
        dispatch({
            type: CREATE_AGENCY_REQUEST
        })
        createAgencyAPI(data).then(res => {
            dispatch({
                type: CREATE_AGENCY_SUCCESS,
                payload: res
            })
            dispatch(commonActions.openSnackbarsState({ message: res && res.message }))
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: CREATE_AGENCY_FAILED
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })

export const DELETE_AGENCY_REQUEST = 'DELETE_AGENCY_REQUEST';
export const DELETE_AGENCY_SUCCESS = 'DELETE_AGENCY_SUCCESS';
export const DELETE_AGENCY_FAILED = 'DELETE_AGENCY_FAILED';

export const deleteAgency = (id) => dispatch =>
    new Promise((resolve, reject) => {
        dispatch({
            type: DELETE_AGENCY_REQUEST
        })
        deleteAgencyAPI(id).then(res => {
            dispatch({
                type: DELETE_AGENCY_SUCCESS,
                payload: res
            })
            dispatch(commonActions.openSnackbarsState({ message: res && res.message }))
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: DELETE_AGENCY_FAILED
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })

export const GET_AGENCY_DEVICES_FOR_TABLE_REQUEST = "GET_AGENCY_DEVICES_FOR_TABLE_REQUEST";
export const GET_AGENCY_DEVICES_FOR_TABLE_SUCCESS = "GET_AGENCY_DEVICES_FOR_TABLE_SUCCESS";
export const GET_AGENCY_DEVICES_FOR_TABLE_FAILED = "GET_AGENCY_DEVICES_FOR_TABLE_FAILED";

export const getAgencyDevicesForTable = (filters) => (dispatch) => {
    return new Promise((resolve, reject) => {
        dispatch({
            type: GET_AGENCY_DEVICES_FOR_TABLE_REQUEST
        })
        getAgencyDevicesForTableAPI(filters).then(res => {
            const { info = {} } = res
            dispatch({
                type: GET_AGENCY_DEVICES_FOR_TABLE_SUCCESS,
                payload: info
            })
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: GET_AGENCY_DEVICES_FOR_TABLE_FAILED,
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })
}

export const GET_AGENCY_AGENTS_FOR_TABLE_REQUEST = "GET_AGENCY_AGENTS_FOR_TABLE_REQUEST";
export const GET_AGENCY_AGENTS_FOR_TABLE_SUCCESS = "GET_AGENCY_AGENTS_FOR_TABLE_SUCCESS";
export const GET_AGENCY_AGENTS_FOR_TABLE_FAILED = "GET_AGENCY_AGENTS_FOR_TABLE_FAILED";

export const getAgencyAgentsForTable = (filters) => (dispatch) => {
    return new Promise((resolve, reject) => {
        dispatch({
            type: GET_AGENCY_AGENTS_FOR_TABLE_REQUEST
        })
        getAgencyAgentForTableAPI(filters).then(res => {
            const { info = {} } = res
            dispatch({
                type: GET_AGENCY_AGENTS_FOR_TABLE_SUCCESS,
                payload: info
            })
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: GET_AGENCY_AGENTS_FOR_TABLE_FAILED,
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })
}

// export const GET_AGENCY_RATING_BY_CUSTOMER_FOR_TABLE_REQUEST = "GET_AGENCY_RATING_BY_CUSTOMER_FOR_TABLE_REQUEST";
// export const GET_AGENCY_RATING_BY_CUSTOMER_FOR_TABLE_SUCCESS = "GET_AGENCY_RATING_BY_CUSTOMER_FOR_TABLE_SUCCESS";
// export const GET_AGENCY_RATING_BY_CUSTOMER_FOR_TABLE_FAILED = "GET_AGENCY_RATING_BY_CUSTOMER_FOR_TABLE_FAILED";

// export const getAgencyRatedByCustomerForTable = (filters) => (dispatch) => {
//     return new Promise((resolve, reject) => {
//         dispatch({
//             type: GET_AGENCY_RATING_BY_CUSTOMER_FOR_TABLE_REQUEST
//         })
//         getAgencyRatedByCustomerForTableAPI(filters).then(res => {
//             const { info = {} } = res
//             dispatch({
//                 type: GET_AGENCY_RATING_BY_CUSTOMER_FOR_TABLE_SUCCESS,
//                 payload: info
//             })
//             return resolve(res)
//         }).catch(err => {
//             dispatch({
//                 type: GET_AGENCY_RATING_BY_CUSTOMER_FOR_TABLE_FAILED,
//             })
//             dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
//             return reject(err)
//         })
//     })
// }

export const DownloadCSVAllAgencies = () => dispatch => {
    return new Promise((resolve, reject) => {
        downloadCSVAllAgenciesAPI().then(res => {
            return resolve(res)
        }).catch(err => {
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })
}
