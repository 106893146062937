import Validator from 'is_js'
import { isEmpty } from "lodash";

export function editInstallerAgent(data) {

    let errors = {};
    // const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    const emailRegex = /^.+@.+\..+$/

    // const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/



    if (!data.firstName || Validator.empty(data.firstName)) {
        errors.firstName = "First Name is required";
    }

    if (data.firstName && data.firstName.length > 200) {
        errors.firstName = "First Name should be less than 200 character";
    }

    if (!data.lastName || Validator.empty(data.lastName)) {
        errors.lastName = "Last Name is required";
    }

    if (data.lastName && data.lastName.length > 200) {
        errors.lastName = "Last Name should be less than 200 character";
    }

    if (Validator.empty(data.email)) {
        errors.email = "Email is required";
    }

    if (!Validator.empty(data.email) && !emailRegex.test(data.email)) {
        errors.email = "Entered Email address is invalid"
    }

    if (Validator.empty(data.phone) || data.phone === undefined) {
        errors.phone = "Phone Number is required";
    }

    if (data.phone && (data.phone.length < 8)) {
        errors.phone = "Phone Number must be 8 to 13 characters";
    }

    if (data.phone && (data.phone.length > 13)) {
        errors.phone = "Phone Number must be 8 to 13 characters";
    }

    return {
        isValid: isEmpty(errors),
        errors
    };
}