import { addNewSiteManagerAPI, getAllSiteManagersAPI } from './apis'
import *  as commonActions from "../../common/actions"
import { errorMessage } from '../../../utils'

export const GET_ALL_SITE_MANAGERS_REQUEST = 'GET_ALL_SITE_MANAGERS_REQUEST';
export const GET_ALL_SITE_MANAGERS_SUCCESS = 'GET_ALL_SITE_MANAGERS_SUCCESS';
export const GET_ALL_SITE_MANAGERS_FAILED = 'GET_ALL_SITE_MANAGERS_FAILED';

export const getAllSiteManagersList = (filters) => dispatch =>

    new Promise((resolve, reject) => {
        dispatch({
            type: GET_ALL_SITE_MANAGERS_REQUEST
        })
        getAllSiteManagersAPI(filters).then(res => {
            dispatch({
                type: GET_ALL_SITE_MANAGERS_SUCCESS,
                payload: res && res.info || {}
            })
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: GET_ALL_SITE_MANAGERS_FAILED
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })

export const CLEAR_ALL_DEVICE_DATA = 'CLEAR_ALL_DEVICE_DATA';

export const clearStoreForAgencyDevice = () => {
    return (dispatch) => {
        dispatch({
            type: CLEAR_ALL_DEVICE_DATA
        })
    }
}

export const ADD_NEW_SITE_MANAGER_REQUEST = 'ADD_NEW_SITE_MANAGER_REQUEST';
export const ADD_NEW_SITE_MANAGER_SUCCESS = 'ADD_NEW_SITE_MANAGER_SUCCESS';
export const ADD_NEW_SITE_MANAGER_FAILED = 'ADD_NEW_SITE_MANAGER_FAILED';

export const addNewSiteManager = (filters) => dispatch =>

    new Promise((resolve, reject) => {
        dispatch({
            type: ADD_NEW_SITE_MANAGER_REQUEST
        })
        addNewSiteManagerAPI(filters).then(res => {
            dispatch({
                type: ADD_NEW_SITE_MANAGER_SUCCESS,
            })
            dispatch(commonActions.openSnackbarsState({ message: res && res.message }))
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: ADD_NEW_SITE_MANAGER_FAILED
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })