import *  as commonActions from "../../../common/actions";
import {
    getSingleSitesStripDataAPI,
    getAllSitesAPI,
    getSitesCountDataAPI
} from './apis'
import { DownloadCSVAllDevicesAPI } from "../../../common/apis";
import { errorMessage } from "../../../../utils";


// graph Data
export const GET_GRAPH_DATA = 'GET_GRAPH_DATA';
// sites Data
export const GET_SITES_REQUEST = 'GET_SITES_REQUEST';
export const GET_SITES_SUCCESS = 'GET_SITES_SUCCESS';
export const GET_SITES_FAILED = 'GET_SITES_FAILED';

export const getallSites = (filters) => dispatch =>

    new Promise((resolve, reject) => {
        dispatch({
            type: GET_SITES_REQUEST
        })
        getAllSitesAPI(filters).then(res => {
            dispatch({
                type: GET_SITES_SUCCESS,
                payload: res && res.info || {}
            })
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: GET_SITES_FAILED
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })

export const GET_SITE_COUNT_REQUEST = 'GET_SITE_COUNT_REQUEST';
export const GET_SITE_COUNT_SUCCESS = 'GET_SITE_COUNT_SUCCESS';
export const GET_SITE_COUNT_FAILED = 'GET_SITE_COUNT_FAILED';

export const getSitesCount = (id) => dispatch =>

    new Promise((resolve, reject) => {
        dispatch({
            type: GET_SITE_COUNT_REQUEST
        })
        getSitesCountDataAPI().then(res => {
            dispatch({
                type: GET_SITE_COUNT_SUCCESS,
                payload: res && res.info || {}
            })
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: GET_SITE_COUNT_FAILED
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })

// export const GET_HEAT_PUMP_DEVICE_HISTORY_REQUEST = 'GET_HEAT_PUMP_DEVICE_HISTORY_REQUEST';
// export const GET_HEAT_PUMP_DEVICE_HISTORY_SUCCESS = 'GET_HEAT_PUMP_DEVICE_HISTORY_SUCCESS';
// export const GET_HEAT_PUMP_DEVICE_HISTORY_FAILED = 'GET_HEAT_PUMP_DEVICE_HISTORY_FAILED';

// export const getHeatPumpSitesHistoryBySerialNo = (serialNo) => dispatch =>
//     new Promise((resolve, reject) => {
//         dispatch({
//             type: GET_HEAT_PUMP_DEVICE_HISTORY_REQUEST
//         })
//         getHeatPumpSitesHistoryBySerialNoAPI(serialNo).then(res => {
//             const { info = [] } = res || {}
//             dispatch({
//                 type: GET_HEAT_PUMP_DEVICE_HISTORY_SUCCESS,
//                 payload: info
//             })
//             // dispatch(commonActions.openSnackbarsState({ message: res && res.message }))
//             return resolve(res)
//         }).catch(err => {
//             dispatch({
//                 type: GET_HEAT_PUMP_DEVICE_HISTORY_FAILED
//             })
//             dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
//             return reject(err)
//         })
//     })

//     export const DownloadCSVAllDevices = () => dispatch => {
//         return new Promise((resolve, reject) => {
//             DownloadCSVAllDevicesAPI().then(res => {
//                 return resolve(res)
//             }).catch(err => {
//                 dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
//                 return reject(err)
//             })
//         })
//     }



