import * as actionsTypes from './actions';
import { saveObject } from '../../utils'
const initalState = {
    isLoading: false,
    isVerifying: false,
    user: {},
    verificationTokenLoading: false
}


export default function (state = { ...initalState }, action) {
    switch (action.type) {

        case actionsTypes.LOGIN_REQUEST:
            return { ...state, isLoading: true }

        case actionsTypes.LOGIN_SUCCESS:
            // saveObject('login-user-info', JSON.stringify(action.payload.userInfo));
            // saveObject("access-token", action.payload.access_token);
            return { ...state, isLoading: false, user: action.payload }

        case actionsTypes.LOGIN_ERROR:
            return { ...state, isLoading: false }

        case actionsTypes.VERIFY_REQUEST:
            return { ...state, isVerifying: true }

        case actionsTypes.VERIFY_SUCCESS:
            saveObject('login-user-info', JSON.stringify(action.payload.userInfo));
            saveObject("access-token", action.payload.access_token);
            return { ...state, isVerifying: false, user: action.payload }
        case actionsTypes.VERIFY_ERROR:
            return { ...state, isVerifying: false }

        case actionsTypes.LOGIN_USER_REQUEST:
            return { ...state, isLoading: true }

        case actionsTypes.LOGIN_USER_SUCCESS:
            return { ...state, isLoading: false, }

        case actionsTypes.LOGIN_USER_ERROR:
            return { ...state, isLoading: false }

        case actionsTypes.GET_USER_PROFILE_REQUEST:
            return { ...state, isLoading: true }

        case actionsTypes.GET_USER_PROFILE_SUCCESS:
            return { ...state, isLoading: false, user: action.payload }

        case actionsTypes.GET_USER_PROFILE_FAILED:
            return { ...state, isLoading: false }

        case actionsTypes.SIGNUP_REQUEST:
            return { ...state, isLoading: true }

        case actionsTypes.SIGNUP_SUCCESS:
            return { ...state, isLoading: false }

        case actionsTypes.SIGNUP_ERROR:
            return { ...state, isLoading: false }

        case actionsTypes.FORGOT_PASSWORD_REQUEST:
            return { ...state, isLoading: true }

        case actionsTypes.FORGOT_PASSWORD_SUCCESS:
            return { ...state, isLoading: false }

        case actionsTypes.FORGOT_PASSWORD_ERROR:
            return { ...state, isLoading: false }

        case actionsTypes.RESET_PASSWORD_REQUEST:
            return { ...state, isLoading: true }

        case actionsTypes.RESET_PASSWORD_SUCCESS:
            return { ...state, isLoading: false }

        case actionsTypes.RESET_PASSWORD_FAILED:
            return { ...state, isLoading: false }

        case actionsTypes.REFRESH_TOKEN_REQUEST:
            return { ...state, isLoading: true }

        case actionsTypes.REFRESH_TOKEN_SUCCESS: {
            saveObject('login-user-info', JSON.stringify(action.payload.userInfo));
            saveObject("access-token", action.payload.access_token);
            return { ...state, isLoading: false }
        }

        case actionsTypes.REFRESH_TOKEN_FAILED:
            return { ...state, isLoading: false }

        case actionsTypes.RESEND_SIGNUP_VERIFICATION_EMAIL_TOKEN_REQUEST:
            return { ...state, verificationTokenLoading: true }

        case actionsTypes.RESEND_SIGNUP_VERIFICATION_EMAIL_TOKEN_SUCCESS:
            return { ...state, verificationTokenLoading: false }

        case actionsTypes.RESEND_SIGNUP_VERIFICATION_EMAIL_TOKEN_FAILED:
            return { ...state, verificationTokenLoading: false }

        case actionsTypes.ELECTRICIAN_FORGOT_PASSWORD_REQUEST:
            return { ...state, isLoading: true }

        case actionsTypes.ELECTRICIAN_FORGOT_PASSWORD_SUCCESS:
            return { ...state, isLoading: false }

        case actionsTypes.ELECTRICIAN_FORGOT_PASSWORD_ERROR:
            return { ...state, isLoading: false }

        case actionsTypes.ELECTRICIAN_RESET_PASSWORD_REQUEST:
            return { ...state, isLoading: true }

        case actionsTypes.ELECTRICIAN_RESET_PASSWORD_SUCCESS:
            return { ...state, isLoading: false }

        case actionsTypes.ELECTRICIAN_RESET_PASSWORD_FAILED:
            return { ...state, isLoading: false }

        case actionsTypes.CLEAR_STORE_FOR_USER_PROFILE:
            return { ...state, user: {} }

        default:
            return state
    }
}

