import * as actionsTypes from './actions';

const initialState = {
    getUserCountAndVersionLoding: false,
    getUserCountAndVersion: {},
    deviceGraphDataLoading: false,
    deviceGraphData: {},
    deviceOverviewDataLoading: false,
    deviceOverviewData: {},
    devicesByMonth: {},
    devicesByMonthLoading: false
}


export default function reducer(state = { ...initialState }, action) {

    switch (action.type) {

        case actionsTypes.GET_USER_COUNT_AND_VERSION_REQUEST:
            return { ...state, getUserCountAndVersionLoding: true }

        case actionsTypes.GET_USER_COUNT_AND_VERSION_SUCCESS:
            return { ...state, getUserCountAndVersionLoding: false, getUserCountAndVersion: action.payload }

        case actionsTypes.GET_USER_COUNT_AND_VERSION_FAILED:
            return { ...state, getUserCountAndVersionLoding: false }

        case actionsTypes.GET_DEVICE_GRAPH_DATA_REQUEST:
            return { ...state, deviceGraphDataLoading: true }

        case actionsTypes.GET_DEVICE_GRAPH_DATA_SUCCESS:
            return { ...state, deviceGraphDataLoading: false, deviceGraphData: action.payload }

        case actionsTypes.GET_DEVICE_GRAPH_DATA_FAILED:
            return { ...state, deviceGraphDataLoading: false }

        case actionsTypes.GET_DEVICE_OVERVIEW_DATA_REQUEST:
            return { ...state, deviceOverviewDataLoading: true }

        case actionsTypes.GET_DEVICE_OVERVIEW_DATA_SUCCESS:
            return { ...state, deviceOverviewDataLoading: false, deviceOverviewData: action.payload }

        case actionsTypes.GET_DEVICE_OVERVIEW_DATA_FAILED:
            return { ...state, deviceOverviewDataLoading: false }

        case actionsTypes.GET_DASHBOARD_DEVICES_MONTH_REQUEST:
            return { ...state, devicesByMonthLoading: true }

        case actionsTypes.GET_DASHBOARD_DEVICES_MONTH_SUCCESS:
            return { ...state, devicesByMonthLoading: false, devicesByMonth: action.payload }

        case actionsTypes.GET_DASHBOARD_DEVICES_MONTH_FAILED:
            return { ...state, devicesByMonthLoading: false }

        case actionsTypes.GET_CONSUMPTION_GRAPH_DATA_REQUEST:
            return { ...state, isConsumptionGraphLoading: true }

        case actionsTypes.GET_CONSUMPTION_GRAPH_DATA_SUCCESS:
            return { ...state, isConsumptionGraphLoading: false, consumptionGraphData: action.payload }

        case actionsTypes.GET_CONSUMPTION_GRAPH_DATA_FAILED:
            return { ...state, isConsumptionGraphLoading: false }

        case actionsTypes.GET_CONSUMPTION_OVERVIEW_REQUEST:
            return { ...state, isConsumptionLoading: true }

        case actionsTypes.GET_CONSUMPTION_OVERVIEW_SUCCESS:
            return { ...state, isConsumptionLoading: false, consumptionOverview: action.payload }

        case actionsTypes.GET_CONSUMPTION_OVERVIEW_FAILED:
            return { ...state, isConsumptionLoading: false }


        case actionsTypes.CLEAR_ALL_DEVICE_DATA:
            return { ...state, ...initialState }

        default:
            return state
    }

}