import { apiGet, apiPost, apiPut } from '../../../utils';
import moment from 'moment';


export function getAllAirlinkDevicesAPI(filters) {
    let arr = []

    for (const [key, value] of Object.entries(filters)) {

        if (key === "isOpen") continue

        if (typeof value !== "number" && !value?.length) continue

        if (key === "installation_date") {
            if (value[0] == null) continue
            arr.push({
                key,
                value: moment(filters.installation_date[0]).format("DD-MM-YYYY"),
                end_value: moment(filters.installation_date[1]).format("DD-MM-YYYY"),
                type: 'single'
            })
        }
        else if (["device_status", "status","connection_type"].includes(key)) {
            arr.push({ key, value, type: 'multi' })
        } else {
            arr.push({ key, value: encodeURIComponent(value), type: "single" })
        }
    }
    return apiGet(`/api/airlink/list?filter=${JSON.stringify(arr)}`, null, 'airlink')

}

export function getSingleAirlinkDeviceAPI(id) {
    return apiGet(`/api/airlink/info/${id}`, null, 'airlink')
}

export function getAirlinkDeviceHistoryBySerialNoAPI(serialNo) {
    return apiGet(`/api/airlink/install-history?serial_number=${serialNo}`,null, 'airlink')
}


