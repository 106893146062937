import { apiDelete, apiGet, apiPost, apiPut } from '../../../utils';
import moment from 'moment';

export function getAllElectriciansAPI(filters) {
    let arr = []
    for (const [key, value] of Object.entries(filters)) {
        if (key === "isOpen") continue
        if (typeof value !== "number" && !value?.length) continue

        if (["status"].includes(key)) {
            arr.push({ key, value, type: 'multi' })
        } else {
            arr.push({ key, value: encodeURIComponent(value), type: "single" })
        }
    }

    return apiGet(`/api/electrician/get/all?filter=${JSON.stringify(arr)}`)

}

export function getElectricianAPI(id) {
    return apiGet(`/api/electrician/get/${id}`)
}

export function getElectricianAgentForTableAPI(filters) {
    let { page = 1, limit = 25, orderBy = "desc", sortBy = 'created_at', id = '' } = filters || {}
    return apiGet(`/api/electrician/get-agents?electrician_id=${id}&page=${page}&limit=${limit}&orderBy=${orderBy}&sortBy=${sortBy}`)
}

export function deleteElectricianAPI(id) {
    return apiDelete(`/api/electrician/delete/${id}`)
}

export function updateElectricianAPI(data, id) {
    let obj = {
        first_name: data?.first_name,
        last_name: data?.last_name,
        phone_no: data?.phone_no,
        address: data?.address,
        company_name: data?.company_name,
        abn_number: data?.abn_number,
        eid: data?.eid,
        email: data?.id,
        aggregator_id: data?.aggregator?.value,
        city: data?.city,
        postcode: data?.postcode,
        state: data?.state.value
    }

    if (data.password) {
        obj = {
            ...obj,
            password: data?.password,
        }
    }

    return apiPut(`/api/electrician/edit/${id}`, obj)
}

export function getAllAggregatorsAPI() {
    return apiGet(`/api/aggregators/get-list`)
}

export function createElectricianAPI(data) {

    let { aggregator = {}, eid = '', state = {}, password = '', ...rest } = data
    if ((state?.value) && (state?.value !== null) || (state?.value !== undefined)) {
        rest = { ...rest, state: state.value }
    }
    return apiPost('/api/electrician/add', rest)
}

export function getElecAgentDevicesForTableAPI(filters) {
    let { page = 1, limit = 25, orderBy = "desc", sortBy = 'created_at', id = '' } = filters || {}
    return apiGet(`/api/electrician/get-devices?electrician_id=${id}&page=${page}&limit=${limit}&orderBy=${orderBy}&sortBy=${sortBy}`)
}
