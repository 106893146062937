import React from "react";

export default function CustomSearchBar(props) {
  const {
    value = "",
    onChange = () => {},
    name = "",
    placeholder = "",
    onChangeKeyDown = () => {},
    className = "",
  } = props;
  return (
    <div className={`custom-search-field ${className}`}>
      <input
        type="text"
        placeholder={placeholder}
        value={value || ""}
        onChange={onChange}
        name={name}
        onKeyDown={onChangeKeyDown}
      />
    </div>
  );
}
