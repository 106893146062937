import {
    getAllElectriciansAgentsAPI,
    getElectricianInstallerAgentAPI,
    updateElectricianInstallerAgentAPI,
    getElectricianAgentDevicesForTableAPI
} from './apis'
import *  as commonActions from "../../common/actions"
import { errorMessage } from '../../../utils'

export const GET_ALL_ELECTRICIANS_AGENTS_REQUEST = 'GET_ALL_ELECTRICIANS_AGENTS_REQUEST';
export const GET_ALL_ELECTRICIANS_AGENTS_SUCCESS = 'GET_ALL_ELECTRICIANS_AGENTS_SUCCESS';
export const GET_ALL_ELECTRICIANS_AGENTS_FAILED = 'GET_ALL_ELECTRICIANS_AGENTS_FAILED';

export const getAllElectriciansAgents = (filters) => dispatch =>
    new Promise((resolve, reject) => {
        dispatch({
            type: GET_ALL_ELECTRICIANS_AGENTS_REQUEST
        })
        getAllElectriciansAgentsAPI(filters).then(res => {
            const { info = {} } = res
            dispatch({
                type: GET_ALL_ELECTRICIANS_AGENTS_SUCCESS,
                payload: info
            })
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: GET_ALL_ELECTRICIANS_AGENTS_FAILED
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })

export const GET_ELECTRICIANS_INSTALLER_AGENTS_REQUEST = 'GET_ELECTRICIANS_INSTALLER_AGENTS_REQUEST';
export const GET_ELECTRICIANS_INSTALLER_AGENTS_SUCCESS = 'GET_ELECTRICIANS_INSTALLER_AGENTS_SUCCESS';
export const GET_ELECTRICIANS_INSTALLER_AGENTS_FAILED = 'GET_ELECTRICIANS_INSTALLER_AGENTS_FAILED';

export const getElectricianInstallerAgent = (id) => dispatch =>
    new Promise((resolve, reject) => {
        dispatch({
            type: GET_ELECTRICIANS_INSTALLER_AGENTS_REQUEST
        })
        getElectricianInstallerAgentAPI(id).then(res => {
            const { info = {} } = res
            dispatch({
                type: GET_ELECTRICIANS_INSTALLER_AGENTS_SUCCESS,
                payload: info
            })
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: GET_ELECTRICIANS_INSTALLER_AGENTS_FAILED
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })


export const UPDATE_ELECTRICIAN_INSTALLER_AGENT_DETAIL_BY_ID_REQUEST = 'UPDATE_ELECTRICIAN_INSTALLER_AGENT_DETAIL_BY_ID_REQUEST';
export const UPDATE_ELECTRICIAN_INSTALLER_AGENT_DETAIL_BY_ID_SUCCESS = 'UPDATE_ELECTRICIAN_INSTALLER_AGENT_DETAIL_BY_ID_SUCCESS';
export const UPDATE_ELECTRICIAN_INSTALLER_AGENT_DETAIL_BY_ID_FAILED = 'UPDATE_ELECTRICIAN_INSTALLER_AGENT_DETAIL_BY_ID_FAILED';

export const updateElectricianInstallerAgent = (data) => dispatch =>
    new Promise((resolve, reject) => {
        dispatch({
            type: UPDATE_ELECTRICIAN_INSTALLER_AGENT_DETAIL_BY_ID_REQUEST
        })
        updateElectricianInstallerAgentAPI(data).then(res => {
            dispatch({
                type: UPDATE_ELECTRICIAN_INSTALLER_AGENT_DETAIL_BY_ID_SUCCESS,
            })
            dispatch(commonActions.openSnackbarsState({ message: res && res.message }))
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: UPDATE_ELECTRICIAN_INSTALLER_AGENT_DETAIL_BY_ID_FAILED
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })

export const GET_ADMIN_ELECTRICIAN_AGENT_DEVICES_FOR_TABLE_REQUEST = "GET_ADMIN_ELECTRICIAN_AGENT_DEVICES_FOR_TABLE_REQUEST";
export const GET_ADMIN_ELECTRICIAN_AGENT_DEVICES_FOR_TABLE_SUCCESS = "GET_ADMIN_ELECTRICIAN_AGENT_DEVICES_FOR_TABLE_SUCCESS";
export const GET_ADMIN_ELECTRICIAN_AGENT_DEVICES_FOR_TABLE_FAILED = "GET_ADMIN_ELECTRICIAN_AGENT_DEVICES_FOR_TABLE_FAILED";

export const getElectricianAgentDevicesForTable = (filters) => (dispatch) => {
    return new Promise((resolve, reject) => {
        dispatch({
            type: GET_ADMIN_ELECTRICIAN_AGENT_DEVICES_FOR_TABLE_REQUEST
        })
        getElectricianAgentDevicesForTableAPI(filters).then(res => {
            const { info = {} } = res
            dispatch({
                type: GET_ADMIN_ELECTRICIAN_AGENT_DEVICES_FOR_TABLE_SUCCESS,
                payload: info
            })
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: GET_ADMIN_ELECTRICIAN_AGENT_DEVICES_FOR_TABLE_FAILED,
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })
}

export const CLEAR_ELECTRICIAN_AGENTS = 'CLEAR_ELECTRICIAN_AGENTS';

export const clearElectricianAgents = () => {
    return (dispatch) => {
        dispatch({
            type: CLEAR_ELECTRICIAN_AGENTS
        })
    }
}