import * as actionsTypes from './actions';

const initialState = {
    isLoading: false,
    allHeatPumpModels: {},
    singleHeatpumpModel: {},
    isFetchingSingleModel: false,
    deviceHistory: [],
    isUpdating: false,
    isCreating:false,
}

export default function (state = { ...initialState }, action) {
    switch (action.type) {

        case actionsTypes.GET_HEAT_PUMP_MODELS_REQUEST:
            return { ...state, isLoading: true }

        case actionsTypes.GET_HEAT_PUMP_MODELS_SUCCESS:
            return { ...state, isLoading: false, allHeatPumpModels: action.payload || {} }

        case actionsTypes.GET_HEAT_PUMP_MODELS_FAILED:
            return { ...state, isLoading: false }

        case actionsTypes.GET_SINGLE_HEAT_PUMP_MODEL_REQUEST:
            return { ...state, isFetchingSingleModel: true }

        case actionsTypes.GET_SINGLE_HEAT_PUMP_MODEL_SUCCESS:
            return { ...state, isFetchingSingleModel: false, singleHeatpumpModel: action.payload || {} }

        case actionsTypes.GET_SINGLE_HEAT_PUMP_MODEL_FAILED:
            return { ...state, isFetchingSingleModel: false }

        case actionsTypes.UPDATE_HEAT_PUMP_MODEL_REQUEST:
            return { ...state, isUpdating: true }

        case actionsTypes.UPDATE_HEAT_PUMP_MODEL_SUCCESS:
            return { ...state, isUpdating: false, }

        case actionsTypes.UPDATE_HEAT_PUMP_MODEL_FAILED:
            return { ...state, isUpdating: false }

        case actionsTypes.CREATE_HEAT_PUMP_MODEL_REQUEST:
            return { ...state, isCreating: true }

        case actionsTypes.CREATE_HEAT_PUMP_MODEL_SUCCESS:
            return { ...state, isCreating: false, }

        case actionsTypes.CREATE_HEAT_PUMP_MODEL_FAILED:
            return { ...state, isCreating: false }
        default:
            return state
    }
}