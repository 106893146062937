import { Grid, Table, TableContainer } from "@material-ui/core";
import StripCard from "./stripcard";
import { useDispatch, useSelector } from "react-redux";
import Map from "./map";
import SiteTable from "../common-comp-hp/SiteTable";
import withSearch from "../common-comp-hp/TableSearch";
import TableSearch from "../common-comp-hp/TableSearch";
import { useEffect, useState } from "react";
import * as actions from './actions';
import EnhancedTableHead from "../../../../components/common/EnhancedTableHead";
import TableLoader from "../../../../components/common/TableLoader";



const stripdata = [
    {
        title: "Total device installed",
        addButton: true,
        filter: false,
        total: "17K",
        key: "total_sites"
    },
    {
        title: "Total sites",
        addButton: false,
        filter: false,
        total: "43",
        key: "offline_devices"
    },
    {
        title: "Offline",
        addButton: false,
        filter: true,
        total: "1.6K",
        key: "offline_devices"
    },
    {
        title: "Important offline device",
        addButton: false,
        filter: true,
        total: "17K",
        key: "imp_offline_devices"
    }
]


const DummyMapData = [
    { name: 'Place 1', lat: 37.758936, lng: 122.491842, status: 'offline' },
    { name: 'Place 2', lat: 37.739390, lng: -122.466419, status: 'offline' },
    { name: 'Place 3', lat: 37.745906, lng: -122.409391, status: 'online' },
    { name: 'Place 4', lat: 37.686700, lng: -122.443745, status: 'online' },
    { name: 'Place 5', lat: 37.690504, lng: -122.487032, status: 'online' },
    { name: 'Place 6', lat: 37.800181, lng: -122.471229, status: 'online' },
    { name: 'Place 7', lat: 37.789872, lng: -122.318173, status: 'offline' },
    { name: 'Place 8', lat: 37.739390, lng: -122.246028, status: 'online' },
];


const headCells = [
    { id: 'sitename', numeric: false, disablePadding: true, label: 'Site Name', dropdown: false },
    { id: 'status', numeric: false, disablePadding: false, label: 'Status', dropdown: false },
    { id: 'devicenumber', numeric: true, disablePadding: false, label: 'Number of Devices', dropdown: false },
    { id: 'online', numeric: true, disablePadding: false, label: 'Online Devices', dropdown: false },
    { id: 'offline', numeric: true, disablePadding: false, label: 'Offline Devices', dropdown: false },
    { id: 'siteintegration', numeric: false, disablePadding: false, label: 'Site Integration', dropdown: false },
    { id: 'alertprofile', numeric: false, disablePadding: false, label: 'Alert Profiles', dropdown: false },
];



const tableData = [
    {
        id: "1",
        sitename: "Supergateway 1",
        status: "Active",
        devicenumber: 10,
        online: 5,
        offline: 5,
        siteintegration: "Admin",
        alertprofile: ['1external team profile']
    },
    {
        id: "2",
        sitename: "Krypton 1",
        status: "In Active",
        devicenumber: 5,
        online: 5,
        offline: 10,
        siteintegration: "Admin",
        alertprofile: ['1external team profile']
    },
    {
        id: "3",
        sitename: "Casual 1",
        status: "Active",
        devicenumber: 10,
        online: 5,
        offline: 5,
        siteintegration: "Admin",
        alertprofile: ['1external team profile']
    }
]


// const SearchWithEnhancement = withSearch(<SiteTable headers={headCells} data={tableData} tableType="Inventory" />);
const EnhancedSiteTable = withSearch(SiteTable);

export default function Sites() {
    const { isDarkMode, selectedSideBarProduct = '' } = useSelector(state => state.common)
    const { graphisloading, onlineofflineGraphdata, faultnonfaultyGraphdata } = useSelector(state => state.heatpumpdashboardreducer)
    const [searchValue, setSearchValue] = useState('');
    const [filteredData, setFilteredData] = useState([])
    const { allSites = {}, isLoading = false, sitesCount } = useSelector(state => state.heatPumpSites)
    const { data: sites = [], total = 0 } = allSites
    const dispatch = useDispatch()
    const MapMarker = (props) => {
        if (props.mapType === 'dashboard') {
            return (<img src="/images/locationpin.svg" alt="pin" />)
        } else if (props.mapType === 'sites') {
            return (
                <img
                    src={`/images/${props.status === "online" ? "online" : "offline"}-pin.svg`}
                    alt="pin"
                />
            );
        }
    };


    const refreshHeatPumpDevices = (filters) => {
        dispatch(actions.getallSites(filters))
        dispatch(actions.getSitesCount())

    }

    useEffect(() => {
        let tempFilter = {}
        refreshHeatPumpDevices(tempFilter)
        return () => {
            // dispatch(actions.clearStoreForDevice())
        }
    }, [])


    useEffect(() => {
        // const filteredRow = tableData.filter(item => item.site_name.toLowerCase().includes(searchValue.toLowerCase()));
        // setFilteredData(filteredRow)
    }, [searchValue])

    useEffect(() => {
        if (sites?.length) {

            setFilteredData(sites)
        }
    }, [sites])


    return (
        <Grid container spacing={2}>
            <Grid item xs={4}>
                {stripdata.map((item, index) => (<StripCard key={index} data={item} countData={sitesCount} />))}
            </Grid>
            <Grid item xs={8}> <Map places={DummyMapData} MapMarker={MapMarker} mapType="sites" /></Grid>
            <Grid item xs={12} className="red">
                {
                    isLoading ?
                        <>
                            <TableContainer className={""} style={{ overflow: "hidden" }}>
                                <Table className="table-program" stickyHeader aria-label="sticky table">
                                    <EnhancedTableHead
                                        headCells={headCells}
                                        filters={filteredData}
                                    />
                                </Table>
                            </TableContainer>
                            <TableLoader />
                        </>
                        : null
                }
                {!isLoading ?
                    <Grid container direction="column">
                        <Grid item className="searchBar">
                            <TableSearch searchValue={searchValue} changehandler={(value) => setSearchValue(value)} />
                        </Grid>
                        <Grid item>
                            <SiteTable headers={headCells} data={filteredData} tableType="Inventory" />
                        </Grid>
                    </Grid> : null
                }

            </Grid>
        </Grid>
    )
}