import React, { useEffect, useState, useCallback } from "react";
import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  TextField,
} from "@material-ui/core";
import * as actions from "../../containers/MultiSite/actions";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { openGlobalDialog } from "../../containers/common/actions";
import TableLoader from "../common/TableLoader";
import searchIcon from "../../assets/image/searchIcon.svg";
import addIcon from "../../assets/image/addIcon.svg";
import { isLoggedIn } from "../../utils";
import { getCompaniesSites } from "../../containers/Admin/HeatPumpDevices/actions";

const initialFilter = {
  site_name: "",
};

export default function AddToSite(props) {
  const { userType } = isLoggedIn();
  const dispatch = useDispatch();
  const { addDevices, addDevicesToMsc, selectedMsc = "" } = props?.globalModal.data || {};

  const { mscSites = {}, mscSitesLoading = false } = useSelector((state) => state.heatPumpDevices);
  const {
    isSiteLoading = false,
    mssite_list = [],
    isSubmitBtnloading = false,
  } = useSelector((state) => state?.multiSiteDevices);
  const { data, total = 0 } = userType === "Admin" ? mscSites : mssite_list;

  const [errors, setErrors] = useState({});
  const [filters, setfilters] = useState({ ...initialFilter });
  const [selectedDevice, setSelectedDevice] = useState();
  const [allSites, setAllSites] = useState([]);

  const filterCompanies = (filters) => {
    if (userType === "Admin") {
      dispatch(getCompaniesSites(filters, selectedMsc));
    } else {
      dispatch(actions.getAllMultiSiteSite(filters));
    }
    // searchCompanies(filters);
  };

  const delaySearch = useCallback(
    _.debounce((filters) => {
      const existingSites = allSites?.filter((item) => {
        return item?.site_manager_email?.includes(filters?.site_name);
      });
      if (existingSites?.length > 0) {
        setAllSites(existingSites || []);
      } else {
        filterCompanies(filters);
      }
    }, 500),
    [data, allSites]
  );

  const onChangeGroupField = ({ target: { value = "", checked = false, name = "" } }) => {
    if (name == "site_name" && value?.length > 60) return;
    setfilters({ ...filters, site_name: value });
    setErrors({ ...errors, site_name: "" });
    delaySearch({ ...filters, site_name: value });
    // if (name === "selectedAll") {
    //     setData({ ...data, [name]: checked })
    //     setErrors({ ...errors, selectedDevice: '' })
    // } else {
    // }
  };

  const isValid = () => {
    let isValid = true;
    let error_msgs = {};

    if (selectedDevice !== "") {
      error_msgs.site_name = "";
    } else {
      isValid = false;
      error_msgs.site_name = "Please select a site";
    }
    setErrors(error_msgs);
    return isValid;
  };

  const onSubmit = () => {
    if (isValid()) {
      addDevices(selectedDevice).then(() => {
        props.closeModal();
      });
      addDevicesToMsc &&
        addDevicesToMsc(selectedMsc).then(() => {
          props.closeModal();
        });
    }
  };

  const selectItem = (itemId) => {
    if (selectedDevice == itemId) {
      setErrors({ site_name: "Please select a site" });
      setSelectedDevice("");
    } else {
      setSelectedDevice(itemId);
      setErrors({ site_name: "" });
    }
  };

  useEffect(() => {
    filterCompanies(filters);
  }, []);

  useEffect(() => {
    if (setAllSites) {
      setAllSites(data);
    }
  }, [data]);

  return (
    <>
      <DialogTitle className="heat_pump_modal_title">
        <Grid
          item
          md={12}
          className="d-flex"
          style={{ textAlign: "left", justifyContent: "space-between", alignItems: "center" }}>
          <h6>Choose Site for the Selected Devices</h6>
          <Button
            variant="outlined"
            className="cus-btn-modal-head"
            startIcon={<img src={addIcon} />}
            onClick={() =>
              dispatch(
                openGlobalDialog(
                  {
                    // multi_site_company_id: '',
                    addSite: actions.addMultiSiteToSite,
                    openModalCheck: true,
                    addDevices: addDevices,
                    // getSite: refreshMscs,
                    // getMultiSiteContact: actions.getMultiSiteContactData
                  },
                  "ADD_SITE"
                )
              )
            }>
            {" "}
            Add New Site
          </Button>
        </Grid>
      </DialogTitle>
      <DialogContent dividers className="heat_pump_dialog_content">
        <TextField
          className="heat_pump_dialog_content_searchbar"
          placeholder={"Search for site"}
          variant="filled"
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" className="srhIcon">
                <img src={searchIcon} />
              </InputAdornment>
            ),
          }}
          value={filters.site_name}
          onChange={onChangeGroupField}
        />
        {/* <TextField
                        variant="filled"
                        fullWidth
                        name="site_name"
                        label=""
                        type="text"
                        value={filters.site_name}
                        onChange={onChangeGroupField}
                    /> */}
        {/* {errors.name && <span className="profile-error">{errors.name}</span>} */}
        {errors.site_name && <span className="profile-error">{errors.site_name}</span>}
        <Grid item md={12} className="">
          {(userType === "Admin" && mscSitesLoading) || isSiteLoading ? (
            <TableLoader />
          ) : allSites && allSites.length > 0 ? (
            <List dense className="heat_pump_dialog_content_list">
              {allSites.map((item, i) => (
                <Card className="mb-2 mt-0 p-0" variant="outlined">
                  <CardContent className="p-0">
                    <ListItem
                      key={i}
                      className={`${selectedDevice == item.id ? "listItemSelected" : ""}`}
                      button
                      onClick={() => selectItem(item.id)}>
                      <ListItemText
                        id={`checkbox-list-secondary-label-${i}`}
                        primary={
                          <>
                            <p className="text-left"> {item.site_name}</p>
                            <span className="">
                              {item.site_address}
                              {item.sites_count ? ` | ${item.sites_count} properties` : ""}
                            </span>
                          </>
                        }
                      />
                      {selectedDevice == item.id ? (
                        <ListItemSecondaryAction>
                          <input
                            type="checkbox"
                            className="heat_pump_checkbox"
                            edge="end"
                            name="selectDevice"
                            value={item.id}
                            checked={selectedDevice == item.id}
                            onChange={(e) => {
                              if (!e.target.checked) {
                                setSelectedDevice("");
                                setErrors({ site_name: "Please select a site" });
                              } else {
                                setSelectedDevice(item.id);
                                setErrors({});
                              }
                            }}
                            inputProps={{
                              "aria-labelledby": `checkbox-list-secondary-label-${i}`,
                            }}
                          />
                        </ListItemSecondaryAction>
                      ) : null}
                    </ListItem>
                  </CardContent>
                </Card>
              ))}
            </List>
          ) : (
            <p className="text-center mt-4 mb-4 no-data-modal-text">No Data Found</p>
          )}
        </Grid>
      </DialogContent>
      <Grid>
        <DialogActions className="heat_pump_dialog_action">
          <Button
            className={`cus-btn-modal-submit ${!selectedDevice ? "disabled" : ""}`}
            onClick={isSubmitBtnloading ? null : onSubmit}>
            {isSubmitBtnloading ? <CircularProgress size={18} color="white" /> : "Add"}
          </Button>
        </DialogActions>
      </Grid>
    </>
  );
}
