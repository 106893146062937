import { apiGet, apiPut } from '../../../utils';
import moment from 'moment';

export function getAllElectriciansAgentsAPI(filters) {
    let arr = []
    for (const [key, value] of Object.entries(filters)) {
        if (key === "isOpen") continue
        if (typeof value !== "number" && !value?.length) continue

        if (["status"].includes(key)) {
            arr.push({ key, value, type: 'multi' })
        } else {
            arr.push({ key, value: encodeURIComponent(value), type: "single" })
        }
    }
    return apiGet(`/api/electrician-agent/get/all?filter=${JSON.stringify(arr)}`)

}

export function getElectricianInstallerAgentAPI(id) {
    return apiGet(`/api/electrician-agent/get/${id}`)
}

export function updateElectricianInstallerAgentAPI(data) {
    const { agentData, params } = data
    let finalData = {
        email: agentData.email,
        first_name: agentData.firstName,
        last_name: agentData.lastName,
        phone: agentData.phone,
        // status: agentData.status,
    }
    return apiPut(`/api/electrician-agent/edit/${params.id}`, finalData)
}

export function getElectricianAgentDevicesForTableAPI(filters) {
    let { page = 1, limit = 25, orderBy = "desc", sortBy = 'created_at', id = '' } = filters || {}
    return apiGet(`/api/electrician-agent/devices?elect_agent_id=${id}&page=${page}&limit=${limit}&orderBy=${orderBy}&sortBy=${sortBy}`)
}

