import * as actionsTypes from './actions';
const initalState = {
    isLoading: false,
    user: {},
}


export default function (state = { ...initalState }, action) {
    switch (action.type) {

        case actionsTypes.GET_ELECTRICIAN_USER_PROFILE_REQUEST:
            return { ...state, isLoading: true }

        case actionsTypes.GET_ELECTRICIAN_USER_PROFILE_SUCCESS:
            return { ...state, isLoading: false, user: action.payload }

        case actionsTypes.GET_ELECTRICIAN_USER_PROFILE_FAILED:
            return { ...state, isLoading: false }

        case actionsTypes.EDIT_ELECTRICIAN_USER_PROFILE_REQUEST:
            return { ...state, isLoading: true }

        case actionsTypes.EDIT_ELECTRICIAN_USER_PROFILE_SUCCESS:
            return { ...state, isLoading: false }

        case actionsTypes.EDIT_ELECTRICIAN_USER_PROFILE_FAILED:
            return { ...state, isLoading: false }

        case actionsTypes.CLEAR_ELECTRICIAN_PANEL_PROFILE_STORE_DATA:
            return { ...state, ...initalState }

        default:
            return state
    }
}

