import *  as commonActions from "../../common/actions"
import { errorMessage } from '../../../utils'
import {
    getAllSmokeAlarmDevicesAPI,
    getSingleSmokeAlarmDeviceAPI,
    getSmokeAlarmDeviceForTableAPI
} from './apis'

export const GET_ALL_SMOKEALARM_DEVIECS_REQUEST = 'GET_ALL_SMOKEALARM_DEVIECS_REQUEST';
export const GET_ALL_SMOKEALARM_DEVIECS_SUCCESS = 'GET_ALL_SMOKEALARM_DEVIECS_SUCCESS';
export const GET_ALL_SMOKEALARM_DEVIECS_FAILED = 'GET_ALL_SMOKEALARM_DEVIECS_FAILED';

export const getAllSmokeAlarmDevices = (filters) => dispatch =>

    new Promise((resolve, reject) => {
        dispatch({
            type: GET_ALL_SMOKEALARM_DEVIECS_REQUEST
        })
        getAllSmokeAlarmDevicesAPI(filters).then(res => {
            dispatch({
                type: GET_ALL_SMOKEALARM_DEVIECS_SUCCESS,
                payload: res && res.info || {}
            })
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: GET_ALL_SMOKEALARM_DEVIECS_FAILED
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })


export const GET_SINGLE_SMOKEALARM_DEVIECS_REQUEST = 'GET_SINGLE_SMOKEALARM_DEVIECS_REQUEST';
export const GET_SINGLE_SMOKEALARM_DEVIECS_SUCCESS = 'GET_SINGLE_SMOKEALARM_DEVIECS_SUCCESS';
export const GET_SINGLE_SMOKEALARM_DEVIECS_FAILED = 'GET_SINGLE_SMOKEALARM_DEVIECS_FAILED';

export const getSingleSmokeAlarmDevice = (id) => dispatch =>

    new Promise((resolve, reject) => {
        dispatch({
            type: GET_SINGLE_SMOKEALARM_DEVIECS_REQUEST
        })
        getSingleSmokeAlarmDeviceAPI(id).then(res => {
            dispatch({
                type: GET_SINGLE_SMOKEALARM_DEVIECS_SUCCESS,
                payload: res && res.info || {}
            })
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: GET_SINGLE_SMOKEALARM_DEVIECS_FAILED
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })

export const GET_SMOKE_ALARM_TABLE_REQUEST = "GET_SMOKE_ALARM_TABLE_REQUEST";
export const GET_SMOKE_ALARM_TABLE_SUCCESS = "GET_SMOKE_ALARM_TABLE_SUCCESS";
export const GET_SMOKE_ALARM_TABLE_FAILED = "GET_SMOKE_ALARM_TABLE_FAILED";

export const getSmokeAlarmDeviceForTable = (filters) => (dispatch) => {
    return new Promise((resolve, reject) => {
        dispatch({
            type: GET_SMOKE_ALARM_TABLE_REQUEST
        })
        getSmokeAlarmDeviceForTableAPI(filters).then(res => {
            const { info = {} } = res
            dispatch({
                type: GET_SMOKE_ALARM_TABLE_SUCCESS,
                payload: info
            })
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: GET_SMOKE_ALARM_TABLE_FAILED,
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })
}

export const CLEAR_ALL_SAFELINK_DEVICE_DATA = 'CLEAR_ALL_SAFELINK_DEVICE_DATA';

export const clearStoreForSafelinkDevice = () => {
    return (dispatch) => {
        dispatch({
            type: CLEAR_ALL_SAFELINK_DEVICE_DATA
        })
    }
}