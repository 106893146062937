import React, { useEffect, useState, useCallback } from 'react';
import { Button, Card, CardContent, Checkbox, CircularProgress, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, InputAdornment, List, ListItem, ListItemSecondaryAction, ListItemText, TextField } from '@material-ui/core';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Input from '../common/Input'
import * as actions from '../../containers/Agency/AgencyDevices/actions'
import _, { iteratee } from 'lodash'
import { useDispatch, useSelector } from 'react-redux';
import Validation from '../../containers/Validation';
import { openGlobalDialog } from '../../containers/common/actions';
import TableLoader from '../common/TableLoader';
import Search from '@material-ui/icons/Search';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const initialFilter = {
    property_name: '',
}

const initialState = {
    name: '',
    selectedAll: false
}

export default function PropertyModal(props) {
    const dispatch = useDispatch()
    const [filters, setfilters] = useState({ ...initialFilter })
    // const [data, setData] = useState([])
    const [selectedDevice, setSelectedDevice] = useState();
    // const { isLoading = false, devices = [] } = useSelector(state => state?.OTAGroupReducer)
    const { isPropertyLoading = false, property_list = [], isSubmitBtnloading = false } = useSelector(state => state?.siteManagerDevice)
    const { data, total = 0 } = property_list;
    const [errors, setErrors] = useState({})
    const { searchCompanies, addDevices } = props?.globalModal.data || {};
    const filterCompanies = (filters) => {
        searchCompanies(filters);
    }
    const delaySearch = useCallback(_.debounce(filters => filterCompanies(filters), 500), []);

    // useEffect(() => {
    //     refreshDevice(filters)
    //     if (id) {
    //         setData({ ...data, name: props?.globalModal?.data?.name })
    //     }
    // }, [])

    // const refreshDevice = (filters) => {
    //     dispatch(actions.getAllDevice(filters))
    // }

    // const onChange = (e, value) => {
    //     setSelectedDevice(value)
    //     refreshDevice({ ...filters, serial_number: '' })
    // }

    // const onChangeText = ({ target: { value = '' } }) => {
    //     if (value && !value.match(/^[0-9]*$/)) return
    //     setErrors({ ...errors, selectedDevice: '' })
    //     let newFilters = { ...filters, serial_number: value }
    //     delaySearch({ ...newFilters, reset: true })
    // }

    const onChangeGroupField = ({ target: { value = '', checked = false, name = '' } }) => {
        if (name == 'property_name' && value?.length > 60) return
        setfilters({ ...filters, property_name: value })
        setErrors({ ...errors, property_name: '' })
        delaySearch({...filters, property_name: value});
        // if (name === "selectedAll") {
        //     setData({ ...data, [name]: checked })
        //     setErrors({ ...errors, selectedDevice: '' })
        // } else {
        // }
    }

    // const selectDevice = () => {
    //     if (selectedD)
    // }

    // const closeModal = () => {
    //     props.closeModal()
    //     setSelectedDevice([])
    //     setData({ ...initialState })
    // }

    const isValid = () => {
        // let { isValid, errors } = Validation.addGroup(selectedDevice, data)
        // setErrors(errors)
        // return isValid
        let isValid = true;
        let error_msgs = {};

        if (selectedDevice !== '') {
            error_msgs.property_name = '';
        } else {
            isValid = false;
            error_msgs.property_name = 'Please Select Property';
        }
        setErrors(error_msgs);
        return isValid;
    }

    const onSubmit = () => {
        if(isValid){  
            addDevices(selectedDevice).then(() => {
                props.closeModal();
            });
        }
        // if (isValid()) {
        //     if (id) {
        //         dispatch(actions.updateGroup(selectedDevice, data, id)).then(res => {
        //             props.closeModal()
        //             dispatch(actions.getGroupDetails(id))
        //             setSelectedDevice([])
        //             setData({ ...initialState })
        //         })
        //     }
        //     else {
        //         dispatch(actions.addDeviceInGroup(selectedDevice, data)).then(res => {
        //             props.closeModal()
        //             props.globalModal.data.getAllOTAGroupFn({ limit: 25, serial_number: '', page: 1, sortBy: 'created_at', orderBy: 'desc', searchBy: '' })
        //             setSelectedDevice([])
        //             setData({ ...initialState })
        //         })
        //     }
        // }
    }

    const selectItem = (itemId) => {
        if (selectedDevice == itemId) {
            setErrors({ property_name: 'Please Select Property' });
            setSelectedDevice("");
        } else  {
            setSelectedDevice(itemId);
            setErrors({ property_name: '' });
        }
    }

    // let mergeOptionsArray = [...devices, ...selectedDevice]
    // let uniqueOptions = mergeOptionsArray.filter((elem, index) => mergeOptionsArray.findIndex(obj => obj.value === elem.value) === index);
    return (
        <>
            <DialogTitle>
                <Grid item md={12} className="d-flex">
                    <h6>Choose Property for the selected devices</h6>
                </Grid>
            </DialogTitle>
            <DialogContent dividers className='heat_pump_dialog_content'>
                <TextField
                    variant="filled"
                    fullWidth
                    name="property_name"
                    label="Search"
                    type="text"
                    value={filters.property_name}
                    onChange={onChangeGroupField}
                    InputProps={{
                        startAdornment: <InputAdornment position="start"><Search /></InputAdornment>,
                    }}
                />
                {/* {errors.name && <span className="profile-error">{errors.name}</span>} */}
                {errors.property_name && <span className="profile-error">{errors.property_name}</span>}
                <Grid item md={12} className='mt-1'>
                    {isPropertyLoading ? <TableLoader /> :
                        data && data.length > 0 ? (
                            <List dense>
                                {data.map((item, i) => (
                                    <Card className='mb-2 mt-0 p-0' variant="outlined">
                                    <CardContent className='p-0'>
                                        <ListItem key={i} button onClick={() => selectItem(item.id)}>
                                            <ListItemText id={`checkbox-list-secondary-label-${i}`} primary={<p> <b>{item.property_name}</b></p>} />
                                            {selectedDevice == item.id ? (
                                                <ListItemSecondaryAction>
                                                    <Checkbox
                                                        edge="end"
                                                        name="selectDevice" value={item.id} checked={selectedDevice == item.id}
                                                        onChange={(e) => { if (!e.target.checked) { setSelectedDevice(''); setErrors({ property_name: 'Please Select a Property' }) } else { setSelectedDevice(item.id); setErrors({}) } }}
                                                        inputProps={{ 'aria-labelledby': `checkbox-list-secondary-label-${i}` }}
                                                    />
                                                </ListItemSecondaryAction>
                                            ) : null}
                                        </ListItem>
                                    </CardContent>
                                </Card> 
                            
                            ))}
                            </List>
                        ) : <p className='text-center mt-3 mb-3'>No Data found</p>
                    }
                </Grid>
            </DialogContent>
            <Grid>
                <DialogActions className='heat_pump_dialog_action'>

                    <Button className={`cus-btn-modal-submit ${!selectedDevice ? "disabled" : ""}`} onClick={isSubmitBtnloading ? null : onSubmit}>
                        {isSubmitBtnloading ? <CircularProgress size={18} color='white' /> : 'Add'}
                    </Button>
                </DialogActions>
            </Grid>
        </>
    )
}

