import * as actionsTypes from './actions';

const initialState = {
    isLoading: false,
    mscSiteCount: {},
    mscSiteProperties: {},
    mscSiteDetails: {},
    heatPumpDevices: []
}

export default function (state = { ...initialState }, action) {
    switch (action.type) {

        case actionsTypes.GET_MSC_SITE_REQUEST :
            return { ...state, isLoading: true }

        case actionsTypes.GET_MSC_SITE_SUCCESS:
            return { ...state, isLoading: false, ...action.payload || {} }

        case actionsTypes.GET_MSC_SITE_FAIED:
            return { ...state, isLoading: false }

        default:
            return state
    }
}