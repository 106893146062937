
export const OPEN_SNACKBAR_STATE = 'OPEN_SNACKBAR_STATE'
export const CLOSE_SNACKBAR_STATE = 'CLOSE_SNACKBAR_STATE'

export const openSnackbarsState = (data) => {
    return (dispatch) => {
        dispatch({
            type: OPEN_SNACKBAR_STATE,
            payload: data
        })
    }
}

export const closeSnackbarsState = () => {
    return (dispatch) => {
        dispatch({
            type: CLOSE_SNACKBAR_STATE
        })
    }
}

export const OPEN_DELETE_MODAL = 'OPEN_DELETE_MODAL'
export const CLOSE_DELETE_MODAL = 'CLOSE_DELETE_MODAL'

export const openDeleteModal = (data) => {
    return (dispatch) => {
        dispatch({
            type: OPEN_DELETE_MODAL,
            payload: data
        })
    }
}

export const closeDeleteModal = () => {
    return (dispatch) => {
        dispatch({
            type: CLOSE_DELETE_MODAL
        })
    }
}

export const OPEN_EDIT_MODAL = 'OPEN_EDIT_MODAL'
export const CLOSE_EDIT_MODAL = 'CLOSE_EDIT_MODAL'

export const openEditModal = (data) => {
    return (dispatch) => {
        dispatch({
            type: OPEN_EDIT_MODAL,
            payload: data
        })
    }
}

export const closeEditModal = () => {
    return (dispatch) => {
        dispatch({
            type: CLOSE_EDIT_MODAL
        })
    }
}

export const OPEN_TOKEN_EXPIRED_MODAL = 'OPEN_TOKEN_EXPIRED_MODAL'
export const CLOSE_TOKEN_EXPIRED_MODAL = 'CLOSE_TOKEN_EXPIRED_MODAL'

export const openTokenExpiredModal = (data) => {
    return (dispatch) => {
        dispatch({
            type: OPEN_TOKEN_EXPIRED_MODAL,
            payload: data
        })
    }
}

export const closeTokenExpiredModal = () => {
    return (dispatch) => {
        dispatch({
            type: CLOSE_TOKEN_EXPIRED_MODAL
        })
    }
}

export const OPEN_GLOBAL_DIALOG = 'OPEN_GLOBAL_DIALOG'
export const CLOSE_GLOBAL_DIALOG = 'CLOSE_GLOBAL_DIALOG'

export const openGlobalDialog = (data = {}, modal) => {
    return (dispatch) => {
        dispatch({
            type: OPEN_GLOBAL_DIALOG,
            data: data,
            modalName: modal
        })
    }
}

export const closeGlobalDialog = () => {
    return (dispatch) => {
        dispatch({
            type: CLOSE_GLOBAL_DIALOG
        })
    }
}

export const DARK_THEME = 'DARK_THEME'

export const darkTheme = (value) => {
    return (dispatch) => {
        dispatch({
            type: DARK_THEME,
            payload: value
        })
    }
}


export const CHANGE_SIDEBAR_PRODUCT = "CHANGE_SIDEBAR_PRODUCT"
export const CHANGE_SIDEBAR_PRODUCT_LOADING = "CHANGE_SIDEBAR_PRODUCT_LOADING"

export const changeSidebarProduct = (value) => {
    return (dispatch) => {
        dispatch({
            type: CHANGE_SIDEBAR_PRODUCT_LOADING,
        })
        return new Promise((resolve, reject) => {
            window.localStorage.setItem("selectedProduct", value);
            dispatch({
                type: CHANGE_SIDEBAR_PRODUCT,
                payload: value
            })
            resolve(true)
        })
    }
}
