import * as actionsTypes from './actions';

const initialState = {
    isLoading: false,
    allAgencyAgents: {},
    agent: {},
    agentDevicesForTable: {},
    agentDevicesIsLoading: false,
    // rating: {},
    // ratingCustomerList: {},
    // ratingCustomerListIsLoading: false
}

export default function (state = { ...initialState }, action) {
    switch (action.type) {

        case actionsTypes.GET_INSTALLATION_AGENTS_BY_AGENCY_REQUEST:
            return { ...state, isLoading: true }

        case actionsTypes.GET_INSTALLATION_AGENTS_BY_AGENCY_SUCCESS:
            return { ...state, isLoading: false, allAgencyAgents: action.payload || {} }

        case actionsTypes.GET_INSTALLATION_AGENT_BY_AGENCY_FAILED:
            return { ...state, isLoading: false }

        case actionsTypes.GET_INSTALLATION_AGENT_DETAIL_BY_ID_REQUEST:
            return { ...state, isLoading: true }

        case actionsTypes.GET_INSTALLATION_AGENT_DETAIL_BY_ID_SUCCESS:
            return { ...state, isLoading: false, agent: action.payload || {}, rating: action.rating || {}}

        case actionsTypes.GET_INSTALLATION_AGENT_DETAIL_BY_ID_FAILED:
            return { ...state, isLoading: false }

        case actionsTypes.UPDATE_INSTALLATION_AGENT_REQUEST:
            return { ...state, isLoading: true }

        case actionsTypes.UPDATE_INSTALLATION_AGENT_SUCCESS:
            return { ...state, isLoading: false }

        case actionsTypes.UPDATE_INSTALLATION_AGENT_FAILED:
            return { ...state, isLoading: false }

        case actionsTypes.UPDATE_INSTALLATION_AGENT_REQUEST:
            return { ...state, isLoading: true }

        case actionsTypes.ADD_INSTALLATION_AGENT_REQUEST:
            return { ...state, isLoading: true }

        case actionsTypes.ADD_INSTALLATION_AGENT_SUCCESS:
            return { ...state, isLoading: false }

        case actionsTypes.ADD_INSTALLATION_AGENT_FAILED:
            return { ...state, isLoading: false }

        case actionsTypes.DELETE_INSTALLATION_AGENT_REQUEST:
            return { ...state, isLoading: true }

        case actionsTypes.DELETE_INSTALLATION_AGENT_SUCCESS:
            return { ...state, isLoading: false }

        case actionsTypes.DELETE_INSTALLATION_AGENT_FAILED:
            return { ...state, isLoading: false }

        case actionsTypes.CLEAR__AGENCY_AGENT_DETAIL_BY_ID:
            return { ...state, agent: {} }

        case actionsTypes.GET_AGENT_DEVICES_FOR_TABLE_REQUEST:
            return { ...state, agentDevicesIsLoading: true }

        case actionsTypes.GET_AGENT_DEVICES_FOR_TABLE_SUCCESS:
            return { ...state, agentDevicesIsLoading: false, agentDevicesForTable: action.payload || {} }

        case actionsTypes.GET_AGENT_DEVICES_FOR_TABLE_FAILED:
            return { ...state, agentDevicesIsLoading: false }

        // case actionsTypes.GET_AGENCY_AGENT_RATING_BY_CUSTOMER_FOR_TABLE_REQUEST:
        //     return { ...state, ratingCustomerListIsLoading: true }

        // case actionsTypes.GET_AGENCY_AGENT_RATING_BY_CUSTOMER_FOR_TABLE_SUCCESS:
        //     return { ...state, ratingCustomerListIsLoading: false, ratingCustomerList: action.payload || {} }

        // case actionsTypes.GET_AGENCY_AGENT_RATING_BY_CUSTOMER_FOR_TABLE_FAILED:
        //     return { ...state, ratingCustomerListIsLoading: false }

        default:
            return state
    }
}