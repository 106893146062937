import { apiDelete, apiGet, apiPost, apiPut } from '../../../utils';
import moment from 'moment';


export function getAllHeatPumpModelsAPI(filters) {
    let arr = []

    for (const [key, value] of Object.entries(filters)) {

        if (key === "isOpen") continue

        if (typeof value !== "number" && !value?.length) continue

        if (key === "installation_date") {
            if (value[0] == null) continue
            arr.push({
                key,
                value: moment(filters.installation_date[0]).format("DD-MM-YYYY"),
                end_value: moment(filters.installation_date[1]).format("DD-MM-YYYY"),
                type: 'single'
            })
        }
        else if (["device_status", "status", "connection_type"].includes(key)) {
            arr.push({ key, value, type: 'multi' })
        } else {
            arr.push({ key, value: encodeURIComponent(value), type: "single" })
        }
    }
    return apiGet(`/api/heat-pump/model/list?filter=${JSON.stringify(arr)}`, null, 'safelink')

}

export function getSingleHeatPumpModelAPI(id) {
    return apiGet(`/api/heat-pump/model/info/${id}`, null, 'safelink')
}

export function deleteSingleHeatPumpModelAPI(id) {
    return apiDelete(`/api/heat-pump/model/delete/${id}`, null, 'safelink')
}

export function updateHeatPumpModelAPI(data) {
    return apiPut(`/api/heat-pump/model/update`, data, 'safelink')
}

export function createHeatPumpModelAPI(data) {
    return apiPost(`/api/heat-pump/model/save`, data, 'safelink')
}

export function deleteHeatPumpModelAPI(id) {
    return apiDelete(`/api/heat-pump/model/delete/${id}`, null, 'safelink')
}

