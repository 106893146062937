import * as actionsTypes from './actions';

const initialState = {
    isLoading: false,
    allPartners: {},
    partner: {},
    partnerAgentsForTable: {},
    partnerAgentsIsLoading: false,
    partnerDevicesForTable: {},
    partnerDevicesIsLoading: false,
    partnerAgenciesForTable: {},
    partnerAgenciesIsLoading: false
}

export default function (state = { ...initialState }, action) {
    switch (action.type) {

        case actionsTypes.GET_ALL_PARTNERS_REQUEST:
            return { ...state, isLoading: true }

        case actionsTypes.GET_ALL_PARTNERS_SUCCESS:
            return { ...state, isLoading: false, allPartners: action.payload || {} }

        case actionsTypes.GET_ALL_PARTNERS_FAILED:
            return { ...state, isLoading: false }

        case actionsTypes.GET_PARTNER_BY_ID_REQUEST:
            return { ...state, isLoading: true }

        case actionsTypes.GET_PARTNER_BY_ID_SUCESS:
            return { ...state, isLoading: false, partner: action.payload || {} }

        case actionsTypes.GET_PARTNER_BY_ID_FAILED:
            return { ...state, isLoading: false }

        case actionsTypes.GET_PARTNER_AGENTS_FOR_TABLE_REQUEST:
            return { ...state, partnerAgentsIsLoading: true }

        case actionsTypes.GET_PARTNER_AGENTS_FOR_TABLE_SUCCESS:
            return { ...state, partnerAgentsIsLoading: false, partnerAgentsForTable: action.payload || {} }

        case actionsTypes.GET_PARTNER_AGENTS_FOR_TABLE_FAILED:
            return { ...state, partnerAgentsIsLoading: false }

        case actionsTypes.GET_PARTNER_DEVICES_FOR_TABLE_REQUEST:
            return { ...state, partnerDevicesIsLoading: true }

        case actionsTypes.GET_PARTNER_DEVICES_FOR_TABLE_SUCCESS:
            return { ...state, partnerDevicesIsLoading: false, partnerDevicesForTable: action.payload || {} }

        case actionsTypes.GET_PARTNER_DEVICES_FOR_TABLE_FAILED:
            return { ...state, partnerDevicesIsLoading: false }

        case actionsTypes.GET_PARTNER_AGENCIES_FOR_TABLE_REQUEST:
            return { ...state, partnerAgenciesIsLoading: true }

        case actionsTypes.GET_PARTNER_AGENCIES_FOR_TABLE_SUCCESS:
            return { ...state, partnerAgenciesIsLoading: false, partnerAgenciesForTable: action.payload || {} }

        case actionsTypes.GET_PARTNER_AGENCIES_FOR_TABLE_FAILED:
            return { ...state, partnerAgenciesIsLoading: false }

        case actionsTypes.CLEAR_STORE_FOR_PARTNERS:
            return { ...state, ...initialState }

        default:
            return state
    }

}