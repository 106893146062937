import *  as commonActions from "../../common/actions"
import { errorMessage } from '../../../utils'
import {
    getAllAirlinkDevicesAPI,
    getSingleAirlinkDeviceAPI,
    getAirlinkDeviceHistoryBySerialNoAPI
} from './apis'
import { DownloadCSVAllDevicesAPI } from "../../common/apis";

export const GET_ALL_AIRLINK_DEVIECS_REQUEST = 'GET_ALL_AIRLINK_DEVIECS_REQUEST';
export const GET_ALL_AIRLINK_DEVIECS_SUCCESS = 'GET_ALL_AIRLINK_DEVIECS_SUCCESS';
export const GET_ALL_AIRLINK_DEVIECS_FAILED = 'GET_ALL_AIRLINK_DEVIECS_FAILED';

export const getAllAirlinkDevices = (filters) => dispatch =>

    new Promise((resolve, reject) => {
        dispatch({
            type: GET_ALL_AIRLINK_DEVIECS_REQUEST
        })
        getAllAirlinkDevicesAPI(filters).then(res => {
            dispatch({
                type: GET_ALL_AIRLINK_DEVIECS_SUCCESS,
                payload: res && res.info || {}
            })
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: GET_ALL_AIRLINK_DEVIECS_FAILED
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })

export const GET_SINGLE_AIRLINK_DEVIECS_REQUEST = 'GET_SINGLE_AIRLINK_DEVIECS_REQUEST';
export const GET_SINGLE_AIRLINK_DEVIECS_SUCCESS = 'GET_SINGLE_AIRLINK_DEVIECS_SUCCESS';
export const GET_SINGLE_AIRLINK_DEVIECS_FAILED = 'GET_SINGLE_AIRLINK_DEVIECS_FAILED';

export const getSingleAirlinkDevice = (id) => dispatch =>

    new Promise((resolve, reject) => {
        dispatch({
            type: GET_SINGLE_AIRLINK_DEVIECS_REQUEST
        })
        getSingleAirlinkDeviceAPI(id).then(res => {
            dispatch({
                type: GET_SINGLE_AIRLINK_DEVIECS_SUCCESS,
                payload: res && res.info || {}
            })
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: GET_SINGLE_AIRLINK_DEVIECS_FAILED
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })

export const GET_AIRLINK_DEVICE_HISTORY_REQUEST = 'GET_AIRLINK_DEVICE_HISTORY_REQUEST';
export const GET_AIRLINK_DEVICE_HISTORY_SUCCESS = 'GET_AIRLINK_DEVICE_HISTORY_SUCCESS';
export const GET_AIRLINK_DEVICE_HISTORY_FAILED = 'GET_AIRLINK_DEVICE_HISTORY_FAILED';

export const getAirlinkDeviceHistoryBySerialNo = (serialNo) => dispatch =>
    new Promise((resolve, reject) => {
        dispatch({
            type: GET_AIRLINK_DEVICE_HISTORY_REQUEST
        })
        getAirlinkDeviceHistoryBySerialNoAPI(serialNo).then(res => {
            const { info = [] } = res || {}
            dispatch({
                type: GET_AIRLINK_DEVICE_HISTORY_SUCCESS,
                payload: info
            })
            // dispatch(commonActions.openSnackbarsState({ message: res && res.message }))
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: GET_AIRLINK_DEVICE_HISTORY_FAILED
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })

    export const DownloadCSVAllDevices = () => dispatch => {
        return new Promise((resolve, reject) => {
            DownloadCSVAllDevicesAPI().then(res => {
                return resolve(res)
            }).catch(err => {
                dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
                return reject(err)
            })
        })
    }