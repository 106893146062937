import * as actionsTypes from './actions';

const initialState = {
    isLoading: false,
    allWeeklyReports: {},
}

export default function (state = { ...initialState }, action) {
    switch (action.type) {
        case actionsTypes.GET_ALL_WEEKLY_REPORTS_REQUEST:
            return { ...state, isLoading: true }

        case actionsTypes.GET_ALL_WEEKLY_REPORTS_SUCCESS:
            return { ...state, isLoading: false, allWeeklyReports: action.payload || {} }

        case actionsTypes.GET_ALL_WEEKLY_REPORTS_FAILED:
            return { ...state, isLoading: false }

        case actionsTypes.CLEAR_ALL_WEEKLY_REPORTS_DATA:
            return { ...state, ...initialState }

        default:
            return state

    }
}