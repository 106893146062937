import { apiGet, getDeviceType, getWifiSignalStrengthValue, isLoggedIn } from "../../../utils";
import moment from "moment";

export function getAllHeatPumpDevicesAPI(filters) {
  let arr = [];

  for (const [key, value] of Object.entries(filters)) {
    if (key === "isOpen" || key === "signal_strength") continue;

    if (typeof value !== "number" && !value?.length) continue;

    if (key === "installation_date") {
      if (value[0] == null) continue;
      arr.push({
        key,
        value: moment(filters.installation_date[0]).format("DD-MM-YYYY"),
        end_value: moment(filters.installation_date[1]).format("DD-MM-YYYY"),
        type: "single",
      });
    } else if (
      ["device_status", "device_operation_status", "status", "connection_type"].includes(key)
    ) {
      arr.push({ key, value, type: "multi" });
    } else {
      arr.push({ key, value: encodeURIComponent(value), type: "single" });
    }
  }

  if (filters.signal_strength) {
    const strength = getWifiSignalStrengthValue(filters.signal_strength?.value);
    arr.push({ ...strength, key: "signal_strength", type: "single" });
  }

  let user = isLoggedIn("access-token");
  if (user?.userType === "Customer") {
    return apiGet(
      `/api/nms/customer/heat-pump/list?filter=${JSON.stringify(arr)}`,
      null,
      "safelink"
    );
  }
  return apiGet(`/api/heat-pump/list?filter=${JSON.stringify(arr)}`, null, "safelink");
  // return apiGet(`/api/nms/customer/device/all/devices?filter=${JSON.stringify(arr)}`, null, 'safelink')
}

export function getSingleHeatPumpDeviceAPI(id) {
  let user = isLoggedIn("access-token");
  if (user?.userType === "Customer") {
    return apiGet(`/api/nms/customer/heat-pump/info/${id}`, null, "safelink");
  }
  return apiGet(`/api/heat-pump/info/${id}`, null, "safelink");
}

export function getHeatPumpDeviceHistoryBySerialNoAPI(mac_address, type, name, id, date) {
  let { term = "", endDate = "" } = date;
  // if (!term && type) return Promise.resolve()
  if (term) term = moment(date.term).format("YYYY-MM-DD");
  if (endDate) endDate = moment(date.endDate).format("YYYY-MM-DD");
  if (type && type !== "activity-logs") {
    return apiGet(
      `https://stage.api.sync.flashdata.ihd.eems.app/heat-pump/${type}?${name}=${id}&start_date=${term}&end_date=${endDate}`,
      null,
      ""
    );
  } else if (type == "activity-logs") {
    return apiGet(
      `/api/heat-pump/${type}?${name}=${id}&start_date=${term}&end_date=${endDate}&source=nms`,
      null,
      "safelink"
    );
  }
  return apiGet(`/api/heat-pump/install-history?mac_address=${mac_address}`, null, "safelink");
}

export function getHeatPumpDeviceHistoryAPI(type, heat_pump_id) {
  return apiGet(`/api/heat-pump/${type}?heat_pump_id=${heat_pump_id}`, null, "safelink");
}

export function getMultiSiteCompaniesAPI(filters = []) {
  let arr = [];
  for (const [key, value] of Object.entries(filters)) {
    if (key === "isOpen") continue;
    if (typeof value !== "number" && !value?.length) continue;

    if (["status"].includes(key)) {
      arr.push({ key, value, type: "multi" });
    } else {
      arr.push({ key, value, type: "single" });
    }
  }
  return apiGet(`/api/nms/msc/get/all?filter=${JSON.stringify(arr)}`);
}

export function getCompaniesSitesAPI(filters = [], mscId) {
  let arr = [];
  for (const [key, value] of Object.entries(filters)) {
    if (key === "isOpen") continue;
    if (typeof value !== "number" && !value?.length) continue;

    if (["status"].includes(key)) {
      arr.push({ key, value, type: "multi" });
    } else {
      arr.push({ key, value, type: "single" });
    }
  }
  return apiGet(
    `/api/nms/msc/${mscId}/sites?filter=${JSON.stringify(arr)}&deviceType=${getDeviceType()}`
  );
}
