import * as actionsTypes from './actions';

const initialState = {
    isLoading: false,
    allSliders: {},
    totalSliders: 0,
    createLoading: false
}

export default function (state = { ...initialState }, action) {
    switch (action.type) {
        case actionsTypes.GET_ALL_SLIDES_REQUEST:
            return { ...state, isLoading: true }

        case actionsTypes.GET_ALL_SLIDES_SUCCESS:
            return { ...state, isLoading: false, allSliders: action.payload || {} }

        case actionsTypes.GET_ALL_SLIDES_FAILED:
            return { ...state, isLoading: false }

        case actionsTypes.CLEAR_STORE_FOR_SLIDES:
            return { ...state, ...initialState }
        case actionsTypes.ADD_SLIDE_REQUEST:
            return { ...state, createLoading: true }

        case actionsTypes.ADD_SLIDE_SUCCESS:
            return { ...state, createLoading: false }

        case actionsTypes.ADD_SLIDE_FAILED:
            return { ...state, createLoading: false }

        case actionsTypes.UPDATE_SLIDE_REQUEST:
            return { ...state, createLoading: true }

        case actionsTypes.UPDATE_SLIDE_SUCCESS:
            return { ...state, createLoading: false }

        case actionsTypes.UPDATE_SLIDE_FAILED:
            return { ...state, createLoading: false }

        case actionsTypes.DELETE_SLIDE_REQUEST:
            return { ...state, isLoading: true }

        case actionsTypes.DELETE_SLIDE_SUCCESS:
            return { ...state, isLoading: false }

        case actionsTypes.DELETE_SLIDE_FAILED:
            return { ...state, isLoading: false }
        default:
            return state;
    }
}