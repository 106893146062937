import { apiGet, apiPost, apiPut, getDeviceType } from '../../../utils';


export function getAllAgentsAPI(filters) {
    let arr = []
    for (const [key, value] of Object.entries(filters)) {
        if (key === "isOpen") continue
        if (typeof value !== "number" && !value?.length) continue

        if (["status"].includes(key)) {
            arr.push({ key, value, type: 'multi' })
        } else {
            arr.push({ key, value: encodeURIComponent(value), type: "single" })
        }
    }

    return apiGet(`/api/agent/get/all?filter=${JSON.stringify(arr)}&deviceType=${getDeviceType()}`)
}

export function getInstallerAgentAPI(id) {
    return apiGet(`/api/agency/agent/${id}?deviceType=${getDeviceType()}`)
}

export function updateInstallerAgentAPI(data) {
    const { agentData, params } = data
    let finalData = {
        email: agentData.email,
        // agent_name: agentData.agentName,
        first_name: agentData.firstName,
        last_name: agentData.lastName,
        phone: agentData.phone,
        status: agentData.status,
        deviceType: getDeviceType()
    }
    return apiPut(`/api/agent/edit/${params.id}`, finalData)
}

export function getAgentDeviceInfoAPI(id) {
    return apiGet(`/api/agency/device/get-info?device_id=${id}&deviceType=${getDeviceType()}`)
}

export function getAgentDevicesForTableAPI(filters) {
    let { page = 1, limit = 25, orderBy = "desc", sortBy = 'created_at', id = '' } = filters || {}
    return apiGet(`/api/agent/get-devices?agent_id=${id}&page=${page}&limit=${limit}&orderBy=${orderBy}&sortBy=${sortBy}&deviceType=${getDeviceType()}`)
}

export function agentLoginPayloadForTableAPI(filters) {
    let { page = 1, limit = 25, orderBy = "desc", sortBy = 'created_at', id = '' } = filters || {}
    return apiGet(`/api/agent/login-payload?agent_id=${id}&page=${page}&limit=${limit}&orderBy=${orderBy}&sortBy=${sortBy}&deviceType=${getDeviceType()}`)
}

export function DownloadCSVAllAgentsAPI() {
    return apiGet(`/api/export/agents?&deviceType=${getDeviceType()}`)
}

// export function getAgentRatedByCustomerForTableAPI(filters) {
//     let { page = 1, limit = 25, orderBy = "desc", sortBy = 'created_at', id = '', rating = '' } = filters || {}
//     let obj = {
//         installer_id: id,
//         rating
//     }
//     return apiGet(`/api/agent/agent-rating-customers?page=${page}&limit=${limit}&orderBy=${orderBy}&sortBy=${sortBy}`,obj)
// }

