import { apiPost, apiGet, apiDelete, apiPut, getDeviceType } from "../../../utils";

export function addNewRuleAPI(data) {
    data = {
        ...data,
        deviceType: getDeviceType()
    }
    return apiPost('/api/ota/rule/add', data)
}

export function getAllRuleAPI(filters) {
    let arr = []
    for (const [key, value] of Object.entries(filters)) {
        if (key === "isOpen") continue
        if (typeof value !== "number" && !value?.length) continue

        if (["status"].includes(key)) {
            arr.push({ key, value, type: 'multi' })
        } else {
            arr.push({ key, value, type: "single" })
        }
    }
    return apiGet(`/api/ota/rule/list?filter=${JSON.stringify(arr)}&deviceType=${getDeviceType()}`)
}

export function updateRuleAPI(data) {
    data = {
        ...data,
        deviceType: getDeviceType()
    }
    return apiPut('/api/ota/rule/update', data)
}


export function deleteRuleAPI(id) {
    let obj = {
        id: id,
        deviceType: getDeviceType()
    }
    return apiDelete('/api/ota/rule/delete', obj)
}


