import React from 'react';
import ReactDOM from 'react-dom';
import "rsuite/dist/rsuite.min.css";
import './assets/scss/index.scss';
import Routes from './routes'
import reportWebVitals from './reportWebVitals';
import { Provider } from "react-redux";
import store from './redux/configureStore';
import i18n from './i18n';
import { I18nextProvider } from 'react-i18next';

import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
// import colors from './assets/scss/_colors.scss';
import { colors } from './constant'

import 'react-dates/lib/css/_datepicker.css';
import 'react-dates/initialize';


const theme = createMuiTheme({
  palette: {
    primary: {
      main: colors.primary
    },
    secondary: {
      main: colors.secondary
    },
  },
  overrides: {
    MuiTabs: {
      root: {
        color: colors.secondary,
      },
      indicator: {
        backgroundColor: colors.primary,
        height: 4,
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
      },
      flexContainer: {
        paddingLeft: 24,
      }
    },
    MuiTab: {
      root: {
        height: 60,
      }
    }
  }
});


// const store = configureStore();

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <I18nextProvider i18n={i18n}>
        <Routes />
      </I18nextProvider>
    </Provider>
  </ThemeProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
