import React, { useEffect, useState } from 'react';
import { Dialog, DialogContent, DialogTitle, IconButton } from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';
import { getDeviceType } from '../../../utils';


export default function CustomDialog(props) {

    const { children, customFunction = () => { }, maxWidth, className = "" } = props;

    const [open, setModalOpen] = useState(true)

    const [currentDeviceType, setCurrentDeviceType] = useState("");


    useEffect(() => {
        let deviceType = getDeviceType();
        if (deviceType == 'heat_pump') {
            setCurrentDeviceType("heat_pump");
        } else {
            setCurrentDeviceType("");
        }
        return () => {
            setCurrentDeviceType("");
        }
    }, []);


    function handleClose(event, reason) {
        // if (reason === 'backdropClick') {
        //     return
        // }

        setModalOpen(false)
        customFunction();
    }
    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
                maxWidth={maxWidth}
                className={`${className} cus-dialog py-3`}
                scroll={currentDeviceType == "heat_pump" ? "paper" : ""}
                fullWidth
            >
                {currentDeviceType != "heat_pump" ? (
                    <DialogContent className="dailogBox" style={{ marginBottom: 20 }}>
                        {children}
                    </DialogContent>

                ) : (
                    <>
                        {children}
                    </>
                )}
                {/* <IconButton onClick={handleClose} className="close">
                            <CloseIcon />
                        </IconButton> */}
            </Dialog>
        </div>
    );
}
