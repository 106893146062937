import React, { useEffect, useState, useCallback } from "react";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  InputLabel,
  Paper,
  TextField,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Input from "../common/Input";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import Validation from "../../containers/Validation";
import { openGlobalDialog } from "../../containers/common/actions";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { isLoggedIn } from "../../utils";
import {
  addNewSiteManager,
  getAllSiteManagersList,
} from "../../containers/SiteManager/SiteManagers/actions";
import {
  addNewMscSiteManager,
  getAllMscSiteManagersList,
} from "../../containers/MultiSite/SiteManagers/actions";
import { getAllMultiSiteSite } from "../../containers/MultiSite/actions";
import AccountAdminEmailInfo from "../common/AccountAdminEmailInfo";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

// const initialFilter = {
//     limit: 25, serial_number: '', page: 1, sortBy: 'created_at', orderBy: 'desc',
// }

const initialState = {
  first_name: "",
  last_name: "",
  email: "",
  site_ids: [],
};

export default function AddSiteManager(props) {
  const { userType = null } = isLoggedIn();
  const dispatch = useDispatch();
  const [data, setData] = useState(
    {
      ...props?.globalModal?.data,
      site_ids: props?.globalModal?.data?.sites?.map((site) => site.id) || [],
    } || { ...initialState }
  );
  const isEdit = props?.globalModal?.data?.id ? true : false;
  const [siteList, setSiteList] = useState([]);
  const [selectedDevice, setSelectedDevice] = useState([]);
  const [isLoadingPagination, setIsLoadingPagination] = useState(false);
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [open, setOpen] = React.useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const userInfo = JSON.parse(localStorage.getItem("login-user-info"));
  const userData = userInfo.sites;

  useEffect(() => {
    if (userType === "mscc" || userType === "sm" || userType === "Admin") {
      getMultiSiteSites();
    }
  }, []);

  const debouncedFetchData = useCallback(
    _.debounce((query) => {
      getMultiSiteSites(query);
    }, 500),
    []
  );

  const getMultiSiteSites = (query = "", currentPage = 1, currentSites = []) => {
    setIsLoadingPagination(true);
    dispatch(getAllMultiSiteSite({ site_name: query, page: currentPage, limit: 25 }))
      .then((res) => {
        if (currentPage > page) {
          setSiteList([...currentSites, ...(res?.info?.data ?? [])]);
        } else {
          setSiteList(res?.info?.data || []);
        }
        setIsLoadingPagination(false);
        setPage(res?.info?.current_page);
        setLastPage(res?.info?.last_page);
      })
      .catch(() => {
        setIsLoadingPagination(false);
      });
  };

  const closeModal = () => {
    setData({ ...initialState });
    props.closeModal();
  };

  const handleInputChange = (event, value) => {
    setSearchQuery(value.trim());
    setIsLoadingPagination(true);
    debouncedFetchData(value.trim());
  };

  const onSiteChange = (event, value) => {
    if (value) {
      setData({ ...data, site_ids: value.map((site) => site.id) });
      setErrors({ ...errors, site_ids: "" });
    } else {
      // setData({})
      setErrors({ ...errors, site_ids: value.length === 0 ? "Please Select Site" : "" });
    }
  };

  const onChangeGroupField = ({ target: { value = "", type = "text", name = "" } }) => {
    if ((name == "first_name" || name == "last_name") && value?.length > 60) return;
    // if (name == 'first_name' && value && !/^[a-zA-Z\s]*$/.test(value)) {
    //     // setErrors({ ...errors, [name]: 'Company name can only contain alphabets and spaces.' });
    //     return
    // }
    setData({ ...data, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  const isValid = () => {
    let isValid = true;
    let error_msgs = {};

    if (!data.site_ids || data.site_ids.length === 0) {
      isValid = false;
      error_msgs.site_ids = "Please Select Site";
    } else {
      error_msgs.site_id = "";
    }

    if (!data.first_name) {
      isValid = false;
      error_msgs.first_name = "First Name is required";
    } else {
      error_msgs.first_name = "";
    }

    if (!data.last_name) {
      isValid = false;
      error_msgs.last_name = "Last Name is required";
    } else {
      error_msgs.last_name = "";
    }

    if (!data.email) {
      isValid = false;
      error_msgs.email = "Site Manager Email is required";
    } else if (!data.email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
      isValid = false;
      error_msgs.email = "Please enter a valid email address";
    } else {
      error_msgs.email = "";
    }
    setErrors(error_msgs);
    return isValid;
  };

  const onSubmit = () => {
    // if (isValid()) {
    //     if (id) {
    //         dispatch(actions.updateGroup(selectedDevice, data, id)).then(res => {
    //             props.closeModal()
    //             dispatch(actions.getGroupDetails(id))
    //             setSelectedDevice([])
    //             setData({ ...initialState })
    //         })
    //     }
    //     else {
    //         dispatch(actions.addDeviceInGroup(selectedDevice, data)).then(res => {
    //             props.closeModal()
    //             props.globalModal.data.getAllOTAGroupFn({ limit: 25, serial_number: '', page: 1, sortBy: 'created_at', orderBy: 'desc', searchBy: '' })
    //             setSelectedDevice([])
    //             setData({ ...initialState })
    //         })
    //     }
    // }
    if (isValid()) {
      setIsLoading(true);

      dispatch(addNewMscSiteManager(data, isEdit))
        .then((res) => {
          dispatch(getAllMscSiteManagersList({}));
          closeModal();
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err.message);
        });
      // }
    }
  };

  const CustomPaper = (props) => {
    return <Paper {...props} style={{ border: "1px solid black", borderRadius: "4px" }} />;
  };

  const handleScroll = (event) => {
    const listboxNode = event.currentTarget;
    if (
      listboxNode.scrollTop + listboxNode.clientHeight >= listboxNode.scrollHeight &&
      !isLoadingPagination &&
      page < lastPage
    ) {
      setIsLoadingPagination(true);
      getMultiSiteSites(searchQuery, page + 1, siteList);
    }
  };

  // let mergeOptionsArray = [...devices, ...selectedDevice]
  // let uniqueOptions = mergeOptionsArray.filter((elem, index) => mergeOptionsArray.findIndex(obj => obj.value === elem.value) === index);
  return (
    <>
      <DialogTitle className="heat_pump_modal_title">
        <Grid item md={12} className="d-flex">
          <h6>{isEdit ? "Update" : "Add New"} Site Manager</h6>
        </Grid>
      </DialogTitle>
      <DialogContent dividers className="heat_pump_dialog_content">
        {/* {userType === "sm" ? <Grid item md={12} className='mb-3'>
                    <InputLabel className="inputlabelHeatPumpModal">
                        Select Site
                    </InputLabel>
                    <Autocomplete
                        multiple
                        id="asynchronous-demo"
                        sx={{ width: 300 }}
                        open={open}
                        onOpen={() => {
                            setOpen(true);
                        }}
                        onClose={() => {
                            setOpen(false);
                        }}
                        name="Select Site"
                        isOptionEqualToValue={(option, value) => option.site_name === value.site_name}
                        getOptionLabel={(option) => option.site_name}
                        options={userData?.filter(site => !data?.site_ids?.includes(site.id)) || []}
                        onChange={onSiteChange}
                        value={data.site_ids || []}  // Ensure this matches the structure of `options`
                        // PaperComponent={CustomPaper}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                className="inputfieldHeatPumpModalRemove"
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <React.Fragment>
                                            {params.InputProps.endAdornment}
                                        </React.Fragment>
                                    ),
                                }}
                            />
                        )}
                    />
                    {errors.site_ids && <span className="profile-error">{errors.site_ids}</span>}
                </Grid> : */}
        <Grid item md={12} className="mb-3">
          <InputLabel className="inputlabelHeatPumpModal">Select Site</InputLabel>
          <Autocomplete
            multiple
            id="asynchronous-demo"
            sx={{ width: 300 }}
            open={open}
            onOpen={() => {
              setOpen(true); // Keep it open
            }}
            onClose={(event, reason) => {
              if (reason !== "blur") {
                setOpen(false); // Close only when clicking outside
              }
            }}
            ListboxProps={{
              onScroll: handleScroll, // Attach the scroll event handler for infinite scrolling
            }}
            name="Select Site"
            isOptionEqualToValue={(option, value) => option.site_name === value.site_name}
            getOptionLabel={(option) => option.site_name}
            // options={userData?.filter((data) => data.site_name) || []}
            options={siteList?.filter((site) => !data.site_ids.includes(site.id)) || []}
            loading={isLoadingPagination}
            onChange={onSiteChange}
            onInputChange={handleInputChange}
            defaultValue={isEdit ? data?.sites : data?.site_ids || []}
            // PaperComponent={CustomPaper}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                // label="Select Site"
                className="inputfieldHeatPumpModalRemove"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {isLoadingPagination ? (
                        <CircularProgress
                          className="heatpump_dropdownloader"
                          color="inherit"
                          size={20}
                        />
                      ) : null}
                      {/* {loading ? <CircularProgress color="inherit" size={20} /> : null} */}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
          />
          {errors.site_ids && <span className="profile-error">{errors.site_ids}</span>}
        </Grid>
        {/* } */}

        <Grid item md={12} className="mb-3">
          <InputLabel className="inputlabelHeatPumpModal">First Name</InputLabel>
          <Input
            variant="outlined"
            fullWidth
            name="first_name"
            className="inputfieldHeatPumpModal"
            // label="First Name"
            type="text"
            value={data.first_name || ""}
            onChange={onChangeGroupField}
          />
          {errors.first_name && <span className="profile-error">{errors.first_name}</span>}
        </Grid>
        <Grid item md={12} className="mb-3">
          <InputLabel className="inputlabelHeatPumpModal">Last Name</InputLabel>
          <Input
            variant="outlined"
            fullWidth
            name="last_name"
            label=""
            className="inputfieldHeatPumpModal"
            type="text"
            value={data.last_name || ""}
            onChange={onChangeGroupField}
          />
          {errors.last_name && <span className="profile-error">{errors.last_name}</span>}
        </Grid>
        <Grid item md={12} className="">
          <InputLabel className="inputlabelHeatPumpModal">
            <div style={{ display: "flex", alignItems: "center" }}>
              Email
              <AccountAdminEmailInfo
                content={
                  "The Site Manager is the employee of the Multi-Site Company that is in charge of all Heat Pumps within this particular site."
                }
              />
            </div>
          </InputLabel>
          <Input
            variant="outlined"
            fullWidth
            name="email"
            // label={`Email`}
            className="inputfieldHeatPumpModal"
            type="text"
            value={data.email || ""}
            onChange={onChangeGroupField}
            disabled={isEdit}
          />
          {errors.email && <span className="profile-error">{errors.email}</span>}
        </Grid>
      </DialogContent>
      <Grid>
        <DialogActions className="heat_pump_dialog_action">
          <Button
            className={`cus-btn-modal-submit ${isLoading ? "disabled" : ""}`}
            onClick={isLoading ? null : onSubmit}>
            {isLoading ? <CircularProgress size={18} color="white" /> : isEdit ? "Update" : "Add"}
          </Button>
        </DialogActions>
      </Grid>
    </>
  );
}
