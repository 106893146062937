import { apiGet, apiPost, apiPut, getDeviceType } from '../../../utils';
import moment from 'moment';
import axios from 'axios';


export function getAllSafelinkDevicesAPI(filters) {
    let arr = []

    for (const [key, value] of Object.entries(filters)) {

        if (key === "isOpen") continue

        if (typeof value !== "number" && !value?.length) continue

        if (key === "installation_date") {
            if (value[0] == null) continue
            arr.push({
                key,
                value: moment(filters.installation_date[0]).format("DD-MM-YYYY"),
                end_value: moment(filters.installation_date[1]).format("DD-MM-YYYY"),
                type: 'single'
            })
        }
        else if (["device_status", "status", "connection_type"].includes(key)) {
            arr.push({ key, value, type: 'multi' })
        } else {
            arr.push({ key, value: encodeURIComponent(value), type: "single" })
        }
    }

    return apiGet(`/api/safelink?filter=${JSON.stringify(arr)}`, null, 'safelink')
}

export function getSingleSafelinkDeviceAPI(id) {
    return apiGet(`/api/safelink/info/${id}`, null, 'safelink')
}

export function getSmokeAlarmDeviceForTableAPI(filters) {
    let { page = 1, limit = 25, orderBy = "desc", sortBy = 'created_at', id = '' } = filters || {}

    return apiGet(`/api/safelink/smokealarm/${id}?page=${page}&limit=${limit}&orderBy=${orderBy}&sortBy=${sortBy}`, '', 'safelink')
}

export function getSafelinkDeviceHistoryByMacAddressAPI(mac_address) {
    return apiGet(`/api/safelink/install-history?mac_address=${mac_address}`, null, 'safelink')
}

export function singleSafelinkDeviceUpdateFirmwareAPI(obj) {
    return apiPut('/api/ota/update-firmware', obj)
}
