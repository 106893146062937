import { isEmpty } from 'lodash';
import Validator from 'is_js'
export const addMemberValidation =(data)=>{
    const {phone = ""} = data || {}
    let errors = {};
    const emailRegex = /^.+@.+\..+$/

    if (!data.email) {
        errors.email = "Email is required";
    }

    if (data.email && !emailRegex.test(data.email)) {
        errors.email = "Email address is invalid"
    }

    if (!data?.first_name) {
        errors.firstName = "First Name is required"
    }

    if (!data?.last_name) {
        errors.lastName = "Last Name is required"
    }
    if (Validator.empty(phone)) {
        errors.phone = "Phone Number is required";
    }

    if (data.phone && (data.phone.length < 8)) {
        errors.phone = "Phone Number must be 8 to 13 characters.";
    }

    if (data.phone && (data.phone.length > 13)) {
        errors.phone = "Phone Number must be 8 to 13 characters.";
    }
    return {
        isValid: isEmpty(errors),
        errors
    };;
}