import { apiGet, apiPost, apiPut, apiDelete, getDeviceType } from '../../../utils';


export function getAllFirmwareAPI(filters) {
    let arr = []
    for (const [key, value] of Object.entries(filters)) {
        if (key === "isOpen" || key === 'deviceType') continue
        if (typeof value !== "number" && !value?.length) continue

        if (["status"].includes(key)) {
            arr.push({ key, value, type: 'multi' })
        } else {
            arr.push({ key, value, type: "single" })
        }
    }
    return apiGet(`/api/ota/get?filter=${JSON.stringify(arr)}&deviceType=${filters?.deviceType || "livelink"}`)
}

export function createFirmwareAPI(data) {
    const formData = new FormData()
    formData.append("ota_file", data.fileToUpload[0])
    formData.append("version", data.firmwareVersion)
    formData.append("deviceType", data.deviceType)
    return apiPost('/api/ota/store', formData)
}


export function updateFirmwareAPI(data) {
    const formData = new FormData()
    formData.append("ota_file", data.fileToUpload[0])
    formData.append("version", data.firmwareVersion)
    formData.append("deviceType", data?.deviceType)
    return apiPost(`/api/ota/update/${data.selectedId}`, formData)
}

export function deleteFirmwareAPI(id,deviceType) {
    let obj = {
        ota_id: id,
        "deviceType": getDeviceType(deviceType)
    }
    return apiDelete('/api/ota/delete', obj)
}



