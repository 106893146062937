import React from 'react';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';

export default function CustomAddModal(props) {
    const {
        createBtnTitle = 'Create',
        cancelBtnTitle = 'Close',
        open,
        handleClose,
        createHandler,
        closeHandler,
        title = "",
        createLoading = false
    } = props

    return (
        <div>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" className="cus-dialog py-3" fullWidth>
                <DialogTitle>
                    {createBtnTitle} {title}
                </DialogTitle>
                <DialogContent>
                    {props.children}
                </DialogContent>
                <DialogActions className="dialog-btns modal-add-pdf">
                    <Button onClick={closeHandler} className="button-btn btn-custom-black mr-3"  >
                        {cancelBtnTitle}
                    </Button>
                    <Button onClick={createHandler}  className="button-btn btn-custom-primary" >
                        {createLoading ? <CircularProgress size={18} color='white'/> : createBtnTitle}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}