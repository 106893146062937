import * as actionsTypes from './actions';

const initialState = {
    isLoading: false,
    allDevices: {},
    device: {},
    installationDateIsLoading: false,
    impulseRateHistory: {},
    firmwareHistory: {},
    deviceHistory: []
}

export default function (state = { ...initialState }, action) {
    switch (action.type) {

        case actionsTypes.GET_ALL_DEVIECS_REQUEST:
            return { ...state, isLoading: true }

        case actionsTypes.GET_ALL_DEVIECS_SUCCESS:
            return { ...state, isLoading: false, allDevices: action.payload || {} }

        case actionsTypes.GET_ALL_DEVIECS_FAILED:
            return { ...state, isLoading: false }

        case actionsTypes.GET_DEVICE_BY_ID_REQUEST:
            return { ...state, isLoading: true }

        case actionsTypes.GET_DEVICE_BY_ID_SUCCESS:
            return { ...state, isLoading: false, device: action.payload || {} }

        case actionsTypes.GET_DEVICE_BY_ID_FAILED:
            return { ...state, isLoading: false }

        case actionsTypes.UPDATE_DEVICE_BY_ID_REQUEST:
            return { ...state, isLoading: true }

        case actionsTypes.UPDATE_DEVICE_BY_ID_SUCCESS:
            return { ...state, isLoading: false, }

        case actionsTypes.UPDATE_DEVICE_BY_ID_FAILED:
            return { ...state, isLoading: false }

        case actionsTypes.UPDATE_DEVICE_INSTALLATION_DATE_REQUEST:
            return { ...state, installationDateIsLoading: true }

        case actionsTypes.UPDATE_DEVICE_INSTALLATION_DATE_SUCCESS:
            return { ...state, installationDateIsLoading: false, }

        case actionsTypes.UPDATE_DEVICE_INSTALLATION_DATE_FAILED:
            return { ...state, installationDateIsLoading: false }

        case actionsTypes.GET_IMPULSE_RATE_HISTORY_REQUEST:
            return { ...state, isLoading: true }

        case actionsTypes.GET_IMPULSE_RATE_HISTORY_SUCCESS:
            return { ...state, isLoading: false, impulseRateHistory: action.payload }

        case actionsTypes.GET_IMPULSE_RATE_HISTORY_FAILED:
            return { ...state, isLoading: false }

        case actionsTypes.GET_FIRMWARE_HISTORY_REQUEST:
            return { ...state, isLoading: true }

        case actionsTypes.GET_FIRMWARE_HISTORY_SUCCESS:
            return { ...state, isLoading: false, firmwareHistory: action.payload }

        case actionsTypes.GET_FIRMWARE_HISTORY_FAILED:
            return { ...state, isLoading: false }

        case actionsTypes.GET_DEVICE_HISTORY_REQUEST:
            return { ...state, isLoading: true }

        case actionsTypes.GET_DEVICE_HISTORY_SUCCESS:
            return { ...state, isLoading: false, deviceHistory: action.payload }

        case actionsTypes.GET_DEVICE_HISTORY_FAILED:
            return { ...state, isLoading: false }

        case actionsTypes.CLEAR_ALL_DEVICE_DATA:
            return { ...state, ...initialState }

        default:
            return state
    }
}
