import *  as commonActions from "../../common/actions"
import { errorMessage } from '../../../utils'
import {
    getSingleHeatPumpDeviceAPI,
    getAllHeatPumpDevicesAPI,
    getAllHeatPumpModelsAPI,
    getSingleHeatPumpModelAPI,
    updateHeatPumpModelAPI,
    createHeatPumpModelAPI,
    deleteHeatPumpModelAPI
} from './apis'
import { DownloadCSVAllDevicesAPI } from "../../common/apis";

export const GET_HEAT_PUMP_MODELS_REQUEST = 'GET_HEAT_PUMP_MODELS_REQUEST';
export const GET_HEAT_PUMP_MODELS_SUCCESS = 'GET_HEAT_PUMP_MODELS_SUCCESS';
export const GET_HEAT_PUMP_MODELS_FAILED = 'GET_HEAT_PUMP_MODELS_FAILED';

export const getallHeatPumpModels = (filters) => dispatch =>

    new Promise((resolve, reject) => {
        dispatch({
            type: GET_HEAT_PUMP_MODELS_REQUEST
        })
        getAllHeatPumpModelsAPI(filters).then(res => {
            dispatch({
                type: GET_HEAT_PUMP_MODELS_SUCCESS,
                payload: res && res.info || {}
            })
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: GET_HEAT_PUMP_MODELS_FAILED
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })

export const GET_SINGLE_HEAT_PUMP_MODEL_REQUEST = 'GET_SINGLE_HEAT_PUMP_MODEL_REQUEST';
export const GET_SINGLE_HEAT_PUMP_MODEL_SUCCESS = 'GET_SINGLE_HEAT_PUMP_MODEL_SUCCESS';
export const GET_SINGLE_HEAT_PUMP_MODEL_FAILED = 'GET_SINGLE_HEAT_PUMP_MODEL_FAILED';

export const getSingleHeatPumpModel = (id) => dispatch =>

    new Promise((resolve, reject) => {
        dispatch({
            type: GET_SINGLE_HEAT_PUMP_MODEL_REQUEST
        })
        getSingleHeatPumpModelAPI(id).then(res => {
            dispatch({
                type: GET_SINGLE_HEAT_PUMP_MODEL_SUCCESS,
                payload: res && res.info || {}
            })
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: GET_SINGLE_HEAT_PUMP_MODEL_FAILED
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })

export const UPDATE_HEAT_PUMP_MODEL_REQUEST = 'UPDATE_HEAT_PUMP_MODEL_REQUEST';
export const UPDATE_HEAT_PUMP_MODEL_SUCCESS = 'UPDATE_HEAT_PUMP_MODEL_SUCCESS';
export const UPDATE_HEAT_PUMP_MODEL_FAILED = 'UPDATE_HEAT_PUMP_MODEL_FAILED';

export const updateHeatPumpModel = (data) => dispatch =>
    new Promise((resolve, reject) => {
        dispatch({
            type: UPDATE_HEAT_PUMP_MODEL_REQUEST
        })
        updateHeatPumpModelAPI(data).then(res => {
            const { info = [] } = res || {}
            dispatch({
                type: UPDATE_HEAT_PUMP_MODEL_SUCCESS,
                payload: info
            })
            dispatch(commonActions.openSnackbarsState({ message: res && res.message }))
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: UPDATE_HEAT_PUMP_MODEL_FAILED
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })

export const DownloadCSVAllDevices = () => dispatch => {
    return new Promise((resolve, reject) => {
        DownloadCSVAllDevicesAPI().then(res => {
            return resolve(res)
        }).catch(err => {
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })
}

export const CREATE_HEAT_PUMP_MODEL_REQUEST = 'CREATE_HEAT_PUMP_MODEL_REQUEST';
export const CREATE_HEAT_PUMP_MODEL_SUCCESS = 'CREATE_HEAT_PUMP_MODEL_SUCCESS';
export const CREATE_HEAT_PUMP_MODEL_FAILED = 'CREATE_HEAT_PUMP_MODEL_FAILED';

export const createHeatPumpModel = (data) => dispatch =>
    new Promise((resolve, reject) => {
        dispatch({
            type: CREATE_HEAT_PUMP_MODEL_REQUEST
        })
        createHeatPumpModelAPI(data).then(res => {
            const { info = [] } = res || {}
            dispatch({
                type: CREATE_HEAT_PUMP_MODEL_SUCCESS,
                payload: info
            })
            dispatch(commonActions.openSnackbarsState({ message: res && res.message }))
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: CREATE_HEAT_PUMP_MODEL_FAILED
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })

export const deleteHeatPumpModel = (id) => dispatch =>
    new Promise((resolve, reject) => {
        dispatch({
            type: CREATE_HEAT_PUMP_MODEL_REQUEST
        })
        deleteHeatPumpModelAPI(id).then(res => {
            const { info = [] } = res || {}
            dispatch({
                type: CREATE_HEAT_PUMP_MODEL_SUCCESS,
                payload: info
            })
            dispatch(commonActions.openSnackbarsState({ message: res && res.message }))
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: CREATE_HEAT_PUMP_MODEL_FAILED
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })