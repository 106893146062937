import * as actionsTypes from './actions';

const initialState = {
    isLoading: false,
    allFaultySystem: {},
    singleFaultySystem: {},
    faultHistory: [],
    new_fault_exist: false
}

export default function (state = { ...initialState }, action) {
    switch (action.type) {

        case actionsTypes.GET_FAULTY_SYSTEM_REQUEST:
            return { ...state, isLoading: true }

        case actionsTypes.GET_FAULTY_SYSTEM_SUCCESS:
            return { ...state, isLoading: false, allFaultySystem: action.payload || {} }

        case actionsTypes.GET_FAULTY_SYSTEM_FAILED:
            return { ...state, isLoading: false }

        case actionsTypes.UPDATE_FAULTY_SYSTEM_REQUEST:
            return { ...state, isLoading: true }

        case actionsTypes.UPDATE_FAULTY_SYSTEM_SUCCESS:
            return { ...state, isLoading: false, }

        case actionsTypes.UPDATE_FAULTY_SYSTEM_FAILED:
            return { ...state, isLoading: false }

        case actionsTypes.GET_SINGLE_FAULTY_SYSTEM_REQUEST:
            return { ...state, isLoading: true }

        case actionsTypes.GET_SINGLE_FAULTY_SYSTEM_SUCCESS:
            return { ...state, isLoading: false, singleFaultySystem: action.payload || {} }

        case actionsTypes.GET_SINGLE_FAULTY_SYSTEM_FAILED:
            return { ...state, isLoading: false }

        case actionsTypes.GET_FAULTY_SYSTEM_HISTORY_REQUEST:
            return { ...state, isLoading: true }

        case actionsTypes.GET_FAULTY_SYSTEM_HISTORY_SUCCESS:
            return { ...state, isLoading: false, faultHistory: action.payload || [] }

        case actionsTypes.GET_FAULTY_SYSTEM_HISTORY_FAILED:
            return { ...state, isLoading: false }

        case actionsTypes.NEW_FAULTY_SYSTEM_REQUEST:
            return { ...state, isLoading: true, }

        case actionsTypes.NEW_FAULTY_SYSTEM_SUCCESS:
            return { ...state, isLoading: false, new_fault_exist: action?.payload?.new_fault_exist }

        case actionsTypes.NEW_FAULTY_SYSTEM_FAILED:
            return { ...state, isLoading: false, }

        default:
            return state
    }
}