import { apiPost, apiGet, apiDelete, apiPut, getDeviceType } from "../../../utils";

export function getAllDeviceAPI(filters) {

    let arr = []
    for (const [key, value] of Object.entries(filters)) {
        if (key === "isOpen") continue
        if (typeof value !== "number" && !value?.length) continue

        if (["status"].includes(key)) {
            arr.push({ key, value, type: 'multi' })
        } else {
            arr.push({ key, value, type: "single" })
        }
    }

    return apiGet(`/api/ota/devices?filter=${JSON.stringify(arr)}&deviceType=${getDeviceType()}`)

}

export function getAllOTAGroupAPI(filters) {
    let arr = []
    for (const [key, value] of Object.entries(filters)) {
        if (key === "isOpen") continue
        if (typeof value !== "number" && !value?.length) continue

        if (["status"].includes(key)) {
            arr.push({ key, value, type: 'multi' })
        } else {
            arr.push({ key, value, type: "single" })
        }
    }
    return apiGet(`/api/ota/group/all?filter=${JSON.stringify(arr)}&deviceType=${getDeviceType()}`)
}

export function deleteSingleGroupAPI(id) {
    let obj = {
        group_id: id,
        deviceType: getDeviceType()
    }
    return apiDelete(`/api/ota/group/delete`, obj)
}

export function addDeviceInGroupAPI(selectedDevice, data) {
    let finalArr = selectedDevice?.length ? selectedDevice.map(item => item.value) : []
    let obj = {
        device_ids: finalArr,
        group_name: data.name,
        all: data.selectedAll,
        deviceType: getDeviceType()
    }
    return apiPost(`/api/ota/group/add`, obj)
}

export function getGroupDetailsAPI(id, filters) {
    filters = { ...filters, group_id: id }
    let arr = []
    for (const [key, value] of Object.entries(filters)) {
        if (key === "isOpen") continue
        if (typeof value !== "number" && !value?.length) continue

        if (["status"].includes(key)) {
            arr.push({ key, value, type: 'multi' })
        } else {
            arr.push({ key, value, type: "single" })
        }
    }
    return apiGet(`/api/ota/group/info?group_id=${id}&filter=${JSON.stringify(arr)}&deviceType=${getDeviceType()}`)
}

export function updateGroupAPI(selectedDevice, data, id) {
    let finalArr = selectedDevice?.length ? selectedDevice.map(item => item.value) : []
    let obj = {
        device_ids: finalArr,
        group_name: data.name,
        group_id: id,
        all: data.selectedAll,
        deviceType: getDeviceType()
    }
    return apiPut(`/api/ota/group/add-devices`, obj)
}

export function updateGroupInformationAPI(data) {
    data = {
        ...data,
        deviceType: getDeviceType()
    }
    return apiPut(`/api/ota/group/update-data`, data)
}

export function deleteDeviceFromGroupAPI(data) {
    let deviceSerialNumber = data?.deletedDevice?.length ? data?.deletedDevice?.map(res => res.device_id) : []
    let obj = {
        device_ids: deviceSerialNumber,
        group_id: data.id,
        all: data.allSelected,
        deviceType: getDeviceType()
    }
    return apiPut(`/api/ota/group/remove-devices`, obj)
}




