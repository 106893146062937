import { apiGet, apiPost, apiPut, apiDelete } from '../../../utils';

export function getAllAgentsAPI(filters) {
    let arr = []
    for (const [key, value] of Object.entries(filters)) {
        if (key === "isOpen") continue
        if (typeof value !== "number" && !value.length) continue

        if (["status"].includes(key)) {
            arr.push({ key, value, type: 'multi' })
        } else {
            arr.push({ key, value: encodeURIComponent(value), type: "single" })
        }
    }

    return apiGet(`/api/electrician/agents?filter=${JSON.stringify(arr)}`)
}

export function updateInstallerAgentAPI(data, agentID) {
    let finalData = {
        email: data.email,
        first_name: data.firstName,
        last_name: data.lastName,
        phone: data.mobile,
    }

    if (data.password) {
        finalData = { ...finalData, password: data.password }
    }
    return apiPut(`/api/electrician/agent/edit/${agentID}`, finalData)
}


export function addInstallerAgentAPI(data) {
    let finalData = {
        first_name: data.firstName,
        last_name: data.lastName,
        email: data.email,
        password: data.password,
        phone: data.mobile,
        // status: data.status,
    }
    return apiPost('/api/electrician/agent/add', finalData)
}

export function getAgentDetailByIdAPI(id) {
    return apiGet(`/api/electrician/agent/get/${id}`)
}

export function deleteInstallerAgentAPI(id) {
    return apiDelete(`/api/electrician/agent/${id}`)
}

export function getElecPanelAgentDevicesForTableAPI(filters) {
    let { page = 1, limit = 25, orderBy = "desc", sortBy = 'created_at', id = '' } = filters || {}
    return apiGet(`/api/electrician-agent/devices?elect_agent_id=${id}&page=${page}&limit=${limit}&orderBy=${orderBy}&sortBy=${sortBy}`)
}
