import { isEmpty } from "lodash";

export function addGroup(selectedDevice, data) {
    let errors = {}

    if (!selectedDevice?.length && !data.selectedAll) {
        errors.selectedDevice = 'Device is required.'
    }

    if (!data.name) {
        errors.name = 'Name is required.'
    }

    return {
        isValid: isEmpty(errors),
        errors
    }
}