import { combineReducers } from 'redux';

// Auth
import auth from '../containers/Auth/reducer'

// Admin
import dashboard from '../containers/Admin/Dashboards/reducer';
import analytics from '../containers/Admin/Dashboards/Analytics/reducer'
import device from '../containers/Admin/Devices/reducer'
import liveLinkDevices from '../containers/Admin/LiveLinkDevices/reducer'
import customer from '../containers/Admin/Customers/reducer'
import installerAgencie from '../containers/Admin/InstallerAgencies/reducer'
import partners from '../containers/Admin/Integrations/reducers'
import installerAgent from '../containers/Admin/InstallerAgents/reducer'
import tariff from '../containers/Admin/Tariffs/reducer'
import electricityRetailer from '../containers/Admin/ElectricityRetailers/reducer'
import firmware from '../containers/Admin/Firmwares/reducer'
import OTA from '../containers/Admin/OTAs/reducer'
import OTAHistory from '../containers/Admin/OTAHistory/reducer'
import ruleReducer from '../containers/Admin/OTARules/reducer';
import OTAGroupReducer from '../containers/Admin/OTAGroups/reducer'
import adminElectrician from '../containers/Admin/Electricians/reducer';
import adminElectricainAgent from '../containers/Admin/ElectricianInstallers/reducer';
import aggregators from '../containers/Admin/Aggregators/reducer';
import weeklyReports from '../containers/Admin/WeeklyReports/reducer';
import safelinkDevices from '../containers/Admin/SafelinkDevices/reducer'
import smokeAlarmDevices from '../containers/Admin/SmokeAlarmDevices/reducer';
import airlinkDevices from '../containers/Admin/AirlinkDevices/reducer';
import lightingDevices from '../containers/Admin/LightingDevices/reducer';
import allDevicesReducer from '../containers/Admin/AllDevicesTab/reducer'
import heatPumpDevices from '../containers/Admin/HeatPumpDevices/reducer';
import faultySystemsReducer from '../containers/Admin/Faultysystems/reducer';
import heatPumpSites from '../containers/Admin/Dashboards/Sites/reducers';
import heatPumpDevicesModels from '../containers/Admin/HeatpumpModels/reducer';



import Slider from '../containers/Admin/Slider/reducer'

import adminProfile from '../containers/Admin/Profile/reducer'
// Agency
import agencyDevice from '../containers/Agency/AgencyDevices/reducer'
import dataForceAgencyElectrician from '../containers/Agency/DataForceAgencyElectricians/reducer'
import dataForceAgencyAgent from '../containers/Agency/DataForceAgencyAgents/reducer'
import dataForceAgencyDevice from '../containers/Agency/DataForceAgencyDevices/reducer';
import installationAgent from '../containers/Agency/InstallationAgents/reducer'

import agencyCustomers from '../containers/Agency/AgencyCustomers/reducer'
import agencyFaultySystems from '../containers/Agency/AgencyFaultysystems/reducer'
import agencyHeatpumpdashboardreducer from '../containers/Agency/AgencyDashboards/Heatpumpdashboard/Heatpumpstore/reducer'
import agencyDashboard from '../containers/Agency/AgencyDashboards/reducer'
import agencyMultiSite from '../containers/Agency/AgencyMultisite/reducer'

// Electrician 
import electricianPanelDevice from '../containers/Electrician/Devices/reducer'
import electricianPanelAgent from '../containers/Electrician/InstallerAgents/reducer'
import electricianProfile from '../containers/Electrician/Profile/reducer'

// Common Pages
import commonPages from '../containers/commonPages/reducer'

// Common
import common from "../containers/common/reducer"

// heatpumpdashboardreducer

import heatpumpdashboardreducer from "../containers/Admin/Dashboards/Heatpumpdashboard/Heatpumpstore/reducer"

// Multi Site Company
import multiSiteCompanies from '../containers/Admin/MultiSiteCompany/reducer';
import multiSiteCompany from '../containers/Admin/MultiSiteCompany/MultiSiteCompanyDashboard/reducers';
import multiSiteCompanySites from '../containers/Admin/MultiSiteCompany/Sites/reducers';
import multiSiteCompanySite from '../containers/Admin/MultiSiteCompany/Sites/Site/reducers';

// Site Manager
import siteManagerDevice from "../containers/SiteManager/reducer"
import siteManagersList from "../containers/SiteManager/SiteManagers/reducer";

// Multi Site
import multiSiteDevices from "../containers/MultiSite/reducer"
import mulitiSiteSiteManagers from "../containers/MultiSite/SiteManagers/reducer";
import multiSiteContacts from '../containers/MultiSite/MultiSiteContacts/reducer';

export default combineReducers({
    auth,
    dashboard,
    analytics,
    device,
    liveLinkDevices,
    customer,
    installerAgencie,
    partners,
    installerAgent,
    tariff,
    firmware,
    OTA,
    common,
    Slider,
    allDevices: allDevicesReducer,
    electricityRetailer,
    OTAHistory,
    ruleReducer,
    OTAGroupReducer,
    adminElectrician,
    adminElectricainAgent,
    aggregators,
    weeklyReports,
    safelinkDevices,
    smokeAlarmDevices,
    airlinkDevices,
    lightingDevices,
    heatPumpDevices,
    faultySystemsReducer,
    heatPumpDevicesModels,
    heatPumpSites,
    adminProfile,

    agencyDevice,
    agencyCustomers,
    agencyDashboard,
    agencyFaultySystems,
    agencyHeatpumpdashboardreducer,
    agencyMultiSite,

    installationAgent,
    dataForceAgencyAgent,
    dataForceAgencyElectrician,
    dataForceAgencyDevice,

    electricianPanelDevice,
    electricianPanelAgent,
    electricianProfile,

    commonPages,
    heatpumpdashboardreducer,

    multiSiteCompanies,
    multiSiteCompany,
    multiSiteCompanySites,
    multiSiteCompanySite,

    siteManagerDevice,
    siteManagersList,

    multiSiteDevices,
    mulitiSiteSiteManagers,
    multiSiteContacts
})