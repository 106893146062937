import Validator from "is_js";
import { isEmpty } from "lodash";


export function addFirmware(data) {
    let errors = {};
    let versionRegex = /^(\d+\.)?(\d+\.)?(\*|\d+)$/

    if (!data.firmwareVersion) {
        errors.firmwareVersion = "Firmware version is required"
    }

    if (data.firmwareVersion && !versionRegex.test(data.firmwareVersion)) {
        errors.firmwareVersion = "Invalid firmware version"
    }

    if (data.firmwareVersion && versionRegex.test(data.firmwareVersion)) {
        if ((data.firmwareVersion).split(".").length < 2 || (data.firmwareVersion).split(".").length > 3) {
            errors.firmwareVersion = "Invalid firmware version"
        }
        if ((data.firmwareVersion).split(".").length === 3) {
        let splitedFirmware = data?.firmwareVersion.split(".")
        for (let i = 0; i < splitedFirmware?.length; i++) {
            if (splitedFirmware[i].length > 3) {
                errors.firmwareVersion = "Invalid firmware version"
            }
        }
        }
    }

    if (!data.fileToUpload.length) {
        errors.fileToUpload = "Firmware file is required"
    }

    return {
        isValid: isEmpty(errors),
        errors
    };
}
