import {
    getAllAgentsAPI,
    addInstallerAgentAPI,
    updateInstallerAgentAPI,
    getAgentDetailByIdAPI,
    deleteInstallerAgentAPI,
    getElecPanelAgentDevicesForTableAPI
} from './apis'

import *  as commonActions from "../../common/actions"
import { errorMessage } from '../../../utils'


export const GET_ALL_AGENTS_REQUEST = 'GET_ALL_AGENTS_REQUEST';
export const GET_ALL_AGENTS_SUCCESS = 'GET_ALL_AGENTS_SUCCESS';
export const GET_ALL_AGENTS_FAILED = 'GET_ALL_AGENTS_FAILED';

export const getAllAgents = (filters) => dispatch =>
    new Promise((resolve, reject) => {
        dispatch({
            type: GET_ALL_AGENTS_REQUEST
        })
        getAllAgentsAPI(filters).then(res => {
            dispatch({
                type: GET_ALL_AGENTS_SUCCESS,
                payload: res && res.info || {}
            })
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: GET_ALL_AGENTS_FAILED
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            reject(err)
        })
    })

export const ADD_INSTALLER_AGENT_REQUEST = 'ADD_INSTALLER_AGENT_REQUEST';
export const ADD_INSTALLER_AGENT_SUCCESS = 'ADD_INSTALLER_AGENT_SUCCESS';
export const ADD_INSTALLER_AGENT_FAILED = 'ADD_INSTALLER_AGENT_FAILED';

export const addInstallerAgent = (data) => dispatch =>
    new Promise((resolve, reject) => {

        dispatch({
            type: ADD_INSTALLER_AGENT_REQUEST
        })
        addInstallerAgentAPI(data).then(res => {
            dispatch({
                type: ADD_INSTALLER_AGENT_SUCCESS,
            })
            dispatch(commonActions.openSnackbarsState({ message: res && res.message }))
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: ADD_INSTALLER_AGENT_FAILED
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })

export const UPDATE_INSTALLER_AGENT_REQUEST = 'UPDATE_INSTALLER_AGENT_REQUEST';
export const UPDATE_INSTALLER_AGENT_SUCCESS = 'UPDATE_INSTALLER_AGENT_SUCCESS';
export const UPDATE_INSTALLER_AGENT_FAILED = 'UPDATE_INSTALLER_AGENT_FAILED';

export const updateInstallerAgent = (data, agentID) => dispatch =>
    new Promise((resolve, reject) => {
        dispatch({
            type: UPDATE_INSTALLER_AGENT_REQUEST
        })
        updateInstallerAgentAPI(data, agentID).then(res => {
            dispatch({
                type: UPDATE_INSTALLER_AGENT_SUCCESS,
                payload: res
            })
            dispatch(commonActions.openSnackbarsState({ message: res && res.message }))
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: UPDATE_INSTALLER_AGENT_FAILED
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })

export const GET_INSTALLER_AGENT_DETAIL_BY_ID_REQUEST = 'GET_INSTALLER_AGENT_DETAIL_BY_ID_REQUEST';
export const GET_INSTALLER_AGENT_DETAIL_BY_ID_SUCCESS = 'GET_INSTALLER_AGENT_DETAIL_BY_ID_SUCCESS';
export const GET_INSTALLER_AGENT_DETAIL_BY_ID_FAILED = 'GET_INSTALLER_AGENT_DETAIL_BY_ID_FAILED';

export const getAgentDetailById = (id) => dispatch =>
    new Promise((resolve, reject) => {
        dispatch({
            type: GET_INSTALLER_AGENT_DETAIL_BY_ID_REQUEST
        })
        getAgentDetailByIdAPI(id).then(res => {
            dispatch({
                type: GET_INSTALLER_AGENT_DETAIL_BY_ID_SUCCESS,
                payload: res && res.info,
            })
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: GET_INSTALLER_AGENT_DETAIL_BY_ID_FAILED
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })

export const DELETE_INSTALLER_AGENT_REQUEST = 'DELETE_INSTALLER_AGENT_REQUEST';
export const DELETE_INSTALLER_AGENT_SUCCESS = 'DELETE_INSTALLER_AGENT_REQUEST';
export const DELETE_INSTALLER_AGENT_FAILED = 'DELETE_INSTALLER_AGENT_REQUEST';

export const deleteInstallerAgent = (id) => dispatch =>
    new Promise((resolve, reject) => {
        dispatch({
            type: DELETE_INSTALLER_AGENT_REQUEST
        })
        deleteInstallerAgentAPI(id).then(res => {
            dispatch({
                type: DELETE_INSTALLER_AGENT_SUCCESS,
                payload: res
            })
            dispatch(commonActions.openSnackbarsState({ message: res && res.message }))
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: DELETE_INSTALLER_AGENT_FAILED
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })

export const GET_ELEC_PANEL_AGENT_DEVICES_FOR_TABLE_REQUEST = 'GET_ELEC_PANEL_AGENT_DEVICES_FOR_TABLE_REQUEST';
export const GET_ELEC_PANEL_AGENT_DEVICES_FOR_TABLE_SUCCESS = 'GET_ELEC_PANEL_AGENT_DEVICES_FOR_TABLE_SUCCESS';
export const GET_ELEC_PANEL_AGENT_DEVICES_FOR_TABLE_FAILED = 'GET_ELEC_PANEL_AGENT_DEVICES_FOR_TABLE_FAILED';

export const getElecPanelAgentDevicesForTable = (id) => dispatch =>
    new Promise((resolve, reject) => {
        dispatch({
            type: GET_ELEC_PANEL_AGENT_DEVICES_FOR_TABLE_REQUEST
        })
        getElecPanelAgentDevicesForTableAPI(id).then(res => {
            dispatch({
                type: GET_ELEC_PANEL_AGENT_DEVICES_FOR_TABLE_SUCCESS,
                payload: res?.info || {}
            })
            // dispatch(commonActions.openSnackbarsState({ message: res && res.message }))
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: GET_ELEC_PANEL_AGENT_DEVICES_FOR_TABLE_FAILED
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })

export const CLEAR_ELECTRICIAN_AGENT_DATA = 'CLEAR_ELECTRICIAN_AGENT_DATA';

export const clearStoreForElectricianAgent = () => {
    return (dispatch) => {
        dispatch({
            type: CLEAR_ELECTRICIAN_AGENT_DATA
        })
    }
}