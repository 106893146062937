import React from "react"
import Pagination from '@material-ui/lab/Pagination'
import { Grid, TextField } from "@material-ui/core"
import AutorenewIcon from '@material-ui/icons/Autorenew'
import { paginationList } from '../../constant'

export default function CustomTablePagination(props) {
    const {
        count = 0,
        onPageChange = () => { },
        filters = {},
        refresh = false,
        onClickRefresh = () => { },
        onChangeLimit = () => { },
        onChangeGoToPage = () => { },
        goToPage = ''
    } = props;

    if (!filters.page) {
        filters.page = 1
    }
    const totalPages = Math.ceil(Number(count) / filters.limit)

    let ofStart = (filters.page - 1) * filters.limit
    let ofEnd;

    if (filters.page == 1) {
        ofEnd = filters.limit
    } else {
        ofEnd = parseInt(((filters.page - 1) * filters.limit)) + parseInt(filters.limit)
    }

    if (ofEnd > count) {
        ofEnd = count
    }

    if (count) {
        ofStart += 1
    }

    return (
        <>
            {
                count ? <Grid container className="custom-table-pagination d-flex">
                    <div className="pagination-div">
                        <div className="d-flex">
                            {count ?
                                <div className="showing-items">
                                    <p>Showing <span>{`${ofStart || 1}-${ofEnd || 1}`}</span> of {`${count}`}</p>
                                    <AutorenewIcon className={refresh ? "refresh-svg" : ""} onClick={onClickRefresh} />
                                </div> : <div></div>
                            }

                            {
                                paginationList?.length && count ? <div className="select-custom ml-3">
                                    <select value={filters.limit} onChange={onChangeLimit}>
                                        <option disabled={true}>Limit</option>
                                        {
                                            paginationList.map((value, label) => {
                                                return <option key={label} value={value.value}>{value.label}</option>
                                            })
                                        }
                                    </select>
                                </div> : null
                            }
                            <input type="text" placeholder="Go To Page" className="goto-pagination" value={goToPage} onChange={onChangeGoToPage} />
                        </div>
                        {/* <TextField id="outlined-basic" label="Go To Page"  variant="outlined" value={goToPage} onChange={onChangeGoToPage} /> */}
                        <div className="table-pagination">
                            <Pagination
                                count={totalPages}
                                showFirstButton
                                showLastButton
                                page={filters.page || 1}
                                onChange={onPageChange}
                            />
                        </div>
                    </div>
                </Grid> : null
            }
        </>
    )
}