import *  as commonActions from "../../common/actions"
import { errorMessage } from '../../../utils'
import {
    getAllLivelinkDevicesAPI,
    getLivelinkDeviceByIdAPI,
    getLivelinkDeviceHistoryBySerialNoAPI
} from './apis'
import { DownloadCSVAllDevicesAPI } from "../../common/apis";

export const GET_ALL_LIVELINK_DEVIECS_REQUEST = 'GET_ALL_LIVELINK_DEVIECS_REQUEST';
export const GET_ALL_LIVELINK_DEVIECS_SUCCESS = 'GET_ALL_LIVELINK_DEVIECS_SUCCESS';
export const GET_ALL_LIVELINK_DEVIECS_FAILED = 'GET_ALL_LIVELINK_DEVIECS_FAILED';

export const getAllLivelinkDevices = (filters) => dispatch =>

    new Promise((resolve, reject) => {
        dispatch({
            type: GET_ALL_LIVELINK_DEVIECS_REQUEST
        })
        getAllLivelinkDevicesAPI(filters).then(res => {
            dispatch({
                type: GET_ALL_LIVELINK_DEVIECS_SUCCESS,
                payload: res && res.info || {}
            })
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: GET_ALL_LIVELINK_DEVIECS_FAILED
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })


export const GET_LIVELINK_DEVICE_BY_ID_REQUEST = 'GET_LIVELINK_DEVICE_BY_ID_REQUEST';
export const GET_LIVELINK_DEVICE_BY_ID_SUCCESS = 'GET_LIVELINK_DEVICE_BY_ID_SUCCESS';
export const GET_LIVELINK_DEVICE_BY_ID_FAILED = 'GET_LIVELINK_DEVICE_BY_ID_FAILED';

export const getLivelinkDeviceById = (id) => dispatch =>

    new Promise((resolve, reject) => {
        dispatch({
            type: GET_LIVELINK_DEVICE_BY_ID_REQUEST
        })
        getLivelinkDeviceByIdAPI(id).then(res => {
            dispatch({
                type: GET_LIVELINK_DEVICE_BY_ID_SUCCESS,
                payload: res && res.info
            })
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: GET_LIVELINK_DEVICE_BY_ID_FAILED
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })

export const GET_LIVELINK_DEVICE_HISTORY_REQUEST = 'GET_LIVELINK_DEVICE_HISTORY_REQUEST';
export const GET_LIVELINK_DEVICE_HISTORY_SUCCESS = 'GET_LIVELINK_DEVICE_HISTORY_SUCCESS';
export const GET_LIVELINK_DEVICE_HISTORY_FAILED = 'GET_LIVELINK_DEVICE_HISTORY_FAILED';

export const getLivelinkDeviceHistoryBySerialNo = (id) => dispatch =>

    new Promise((resolve, reject) => {
        dispatch({
            type: GET_LIVELINK_DEVICE_HISTORY_REQUEST
        })
        getLivelinkDeviceHistoryBySerialNoAPI(id).then(res => {
            dispatch({
                type: GET_LIVELINK_DEVICE_HISTORY_SUCCESS,
                payload: res && res.info
            })
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: GET_LIVELINK_DEVICE_HISTORY_FAILED
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })


export const CLEAR_ALL_LIVELINK_DEVICE_DATA = 'CLEAR_ALL_LIVELINK_DEVICE_DATA';

export const clearStoreForLiveLinkDevice = () => {
    return (dispatch) => {
        dispatch({
            type: CLEAR_ALL_LIVELINK_DEVICE_DATA
        })
    }
}

export const DownloadCSVAllDevices = () => dispatch => {
    return new Promise((resolve, reject) => {
        DownloadCSVAllDevicesAPI().then(res => {
            return resolve(res)
        }).catch(err => {
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })
}