import { apiGet, apiPost } from '../../../../utils';

export function getAllSites(filters = {}) {
    let arr = []
    for (const [key, value] of Object.entries(filters)) {
        if (key === "isOpen") continue
        if (typeof value !== "number" && !value?.length) continue

        if (["status"].includes(key)) {
            arr.push({ key, value, type: 'multi' })
        } else {
            arr.push({ key, value, type: "single" })
        }
    }
    return apiGet(`/api/nms/site/get/all?filter=${JSON.stringify(arr)}&deviceType=heat_pump`, null, 'heat_pump')
}

export function postSite(data) {
    return apiPost(`/api/nms/site/add`, data)
}

export function getMultiSiteContact(filters = {}) {
    let arr = [];
    for (const [key, value] of Object.entries(filters)) {
        if (key === "isOpen") continue
        if (typeof value !== "number" && !value?.length) continue

        if (["status"].includes(key)) {
            arr.push({ key, value, type: 'multi' })
        } else {
            arr.push({ key, value, type: "single" })
        }
    }
    return apiGet(`/api/nms/contact/get/all?filter=${JSON.stringify(arr)}&deviceType=heat_pump`, null, 'heat_pump')
}

export function getMultiSiteCompaniesApiData(filters = {}) {
    let arr = [];
    for (const [key, value] of Object.entries(filters)) {
        if (key === "isOpen") continue
        if (typeof value !== "number" && !value?.length) continue

        if (["status"].includes(key)) {
            arr.push({ key, value, type: 'multi' })
        } else {
            arr.push({ key, value, type: "single" })
        }
    }
    return apiGet(`/api/nms/msc/get/all?filter=${JSON.stringify(arr)}&deviceType=heat_pump&fields=id,company_name`, null, 'heat_pump')
}