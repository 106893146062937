import React, { useEffect, useState, useCallback } from 'react';
import { Button, Checkbox, CircularProgress, DialogActions, FormControlLabel, Grid, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Input from '../../../components/common/Input'
import * as actions from './actions'
import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux';
import validation from '../../Validation'

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const initialFilter = {
    limit: 25, serial_number: '', page: 1, sortBy: 'created_at', orderBy: 'desc',
}

const initialState = {
    name: '',
    selectedAll: false
}

export default function AddOTAGroup(props) {
    const dispatch = useDispatch()
    const [filters, setfilters] = useState({ ...initialFilter })
    const [data, setData] = useState({ ...initialState })
    const [selectedDevice, setSelectedDevice] = useState([])
    const { isLoading = false, devices = [] } = useSelector(state => state.OTAGroupReducer)
    const [errors, setErrors] = useState({})
    const delaySearch = useCallback(_.debounce(filters => refreshDevice(filters), 500), []);
    const { id = '' } = props?.globalModal?.data || {}

    useEffect(() => {
        refreshDevice(filters)
        if (id) {
            setData({ ...data, name: props?.globalModal?.data?.name })
        }
    }, [])

    const refreshDevice = (filters) => {
        dispatch(actions.getAllDevice(filters))
    }

    const onChange = (e, value) => {
        setSelectedDevice(value)
        refreshDevice({ ...filters, serial_number: '' })
    }

    const onChangeText = ({ target: { value = '' } }) => {
        if (value && !value.match(/^[0-9]*$/)) return
        setErrors({ ...errors, selectedDevice: '' })
        let newFilters = { ...filters, serial_number: value }
        delaySearch({ ...newFilters, reset: true })
    }

    const onChangeGroupField = ({ target: { value = '', checked = false, name = '' } }) => {
        if (name == 'name' && value.length > 60) return
        if (name === "selectedAll") {
            setData({ ...data, [name]: checked })
            setErrors({ ...errors, selectedDevice: '' })
        } else {
            setData({ ...data, [name]: value })
            setErrors({ ...errors, [name]: '' })
        }
    }

    const closeModal = () => {
        props.closeModal()
        setSelectedDevice([])
        setData({ ...initialState })
    }

    const isValid = () => {
        let { isValid, errors } = validation.addGroup(selectedDevice, data)
        setErrors(errors)
        return isValid
    }

    const onSubmit = () => {
        if (isValid()) {
            if (id) {
                dispatch(actions.updateGroup(selectedDevice, data, id)).then(res => {
                    props.closeModal()
                    dispatch(actions.getGroupDetails(id))
                    setSelectedDevice([])
                    setData({ ...initialState })
                })
            }
            else {
                dispatch(actions.addDeviceInGroup(selectedDevice, data)).then(res => {
                    props.closeModal()
                    props.globalModal.data.getAllOTAGroupFn({ limit: 25, serial_number: '', page: 1, sortBy: 'created_at', orderBy: 'desc', searchBy: '' })
                    setSelectedDevice([])
                    setData({ ...initialState })
                })
            }
        }
    }

    let mergeOptionsArray = [...devices, ...selectedDevice]
    let uniqueOptions = mergeOptionsArray.filter((elem, index) => mergeOptionsArray.findIndex(obj => obj.value === elem.value) === index);
    return (
        <Grid container className="add-ota-container">
            <h2>{id ? 'ADD DEVICES' : 'ADD GROUP'}</h2>
            <Grid item md={12} className="mb-3 mt-3">
                <Input
                    variant="outlined"
                    fullWidth
                    name="name"
                    label="Name*"
                    type="text"
                    value={data.name || ""}
                    onChange={onChangeGroupField}
                />
                {errors.name && <span className="profile-error">{errors.name}</span>}
            </Grid>

            <Grid item md={12}>
                <Autocomplete
                    fullWidth
                    multiple
                    limitTags={4}
                    id="checkboxes-tags-demo"
                    options={uniqueOptions}
                    value={selectedDevice}
                    onChange={onChange}
                    loading={isLoading}
                    disableCloseOnSelect
                    getOptionSelected={(option, value) => option.title === value.title}
                    getOptionLabel={(option) => option.title}
                    renderOption={(option, { selected }) => (
                        <React.Fragment>
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                            />
                            {option.title}
                        </React.Fragment>
                    )}
                    renderInput={(params) => (
                        <TextField {...params} variant="outlined" label="Devices" placeholder="Devices" onChange={onChangeText} />
                    )}
                />
                {errors.selectedDevice && <span className="profile-error">{errors.selectedDevice}</span>}
            </Grid>
            <Grid item md={12} className='mt-1'>
                <FormControlLabel
                    control={<Checkbox name="selectedAll" value={data.selectedAll} onChange={onChangeGroupField} />}
                    label="All Device"
                />
            </Grid>
            <Grid>
            <DialogActions className="dialog-bottom-btns dialog-btns">
                    <Button className="button-btn btn-custom-black mr-3" onClick={closeModal}>
                        No
                    </Button>
                    <Button className="button-btn btn-custom-primary" onClick={onSubmit}>
                        {false ? <CircularProgress size={18} color='white'/> : 'Yes'}
                    </Button>
               </DialogActions>
            </Grid>
        </Grid>
    )
}

