import { IconButton, Popover } from "@material-ui/core";
import { useEffect, useState } from "react";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

const AccountAdminEmailInfo = ({ content = "" }) => {
  const [showCompanyInfo, setShowCompanyInfo] = useState(null);

  const onMouseLeaveHandler = () => {
    setShowCompanyInfo(null);
  };

  useEffect(() => {
    return () => {
      setShowCompanyInfo(null);
    };
  }, []);
  return (
    <>
      <IconButton>
        <InfoOutlinedIcon onMouseEnter={(e) => setShowCompanyInfo(e.currentTarget)} />
      </IconButton>
      <Popover
        sx={{ pointerEvents: "none" }}
        open={Boolean(showCompanyInfo)}
        anchorEl={showCompanyInfo}
        className="account-admin-email-modal"
        // transitionDuration={500}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={(e) => setShowCompanyInfo(null)}
        disableRestoreFocus>
        <div className="cus-pointer tableMoreInfo" onMouseLeave={onMouseLeaveHandler}>
          <div className="mt-2">
            <p className="mt-2">
              {content ||
                `This field captures the email address of the account administrator responsible for
              overseeing installations at the specific company site. It's used for contact and
              communication purposes to ensure timely support and updates.`}
            </p>
          </div>
        </div>
      </Popover>
    </>
  );
};

export default AccountAdminEmailInfo;
