
import {
    getAllAggregatorsAPI,
    addNewAggregatorAPI,
    updateAggregatorAPI,
    deleteAggregatorAPI
} from './apis'

import *  as commonActions from "../../common/actions"
import { errorMessage } from '../../../utils'

export const GET_ALL_AGGREGATORS_REQUEST = 'GET_ALL_AGGREGATORS_REQUEST';
export const GET_ALL_AGGREGATORS_SUCCESS = 'GET_ALL_AGGREGATORS_SUCCESS';
export const GET_ALL_AGGREGATORS_FAILED = 'GET_ALL_AGGREGATORS_FAILED';

export function getAllAggregators(filters) {
    return function (dispatch) {
        return new Promise((resolve, reject) => {
            dispatch({
                type: "GET_ALL_AGGREGATORS_REQUEST"
            })
            getAllAggregatorsAPI(filters).then(res => {
                const { info = {} } = res || {}
                dispatch({
                    type: 'GET_ALL_AGGREGATORS_SUCCESS',
                    payload: info || {}
                })
                resolve(res)
            }).catch(err => {
                dispatch({
                    type: 'GET_ALL_AGGREGATORS_FAILED'
                })
                dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
                reject(err)
            })
        })
    }
}

export const ADD_NEW_AGGREGATOR_REQUEST = 'ADD_NEW_AGGREGATOR_REQUEST';
export const ADD_NEW_AGGREGATOR_SUCCESS = 'ADD_NEW_AGGREGATOR_SUCCESS';
export const ADD_NEW_AGGREGATOR_FAILED = 'ADD_NEW_AGGREGATOR_FAILED';

export function addNewAggregator(data) {
    return function (dispatch) {
        return new Promise((resolve, reject) => {
            dispatch({
                type: "ADD_NEW_AGGREGATOR_REQUEST"
            })
            addNewAggregatorAPI(data).then(res => {
                dispatch({
                    type: 'ADD_NEW_AGGREGATOR_SUCCESS'
                })
                dispatch(commonActions.openSnackbarsState({ message: res && res.message }))
                resolve(res)
            }).catch(err => {
                dispatch({
                    type: 'ADD_NEW_AGGREGATOR_FAILED'
                })
                dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
                reject(err)
            })
        })
    }
}

export const UPDATE_AGGREGATOR_REQUEST = 'UPDATE_AGGREGATOR_REQUEST';
export const UPDATE_AGGREGATOR_SUCCESS = 'UPDATE_AGGREGATOR_SUCCESS';
export const UPDATE_AGGREGATOR_FAILED = 'UPDATE_AGGREGATOR_FAILED';

export function updateAggregator(data) {
    return function (dispatch) {
        return new Promise((resolve, reject) => {
            dispatch({
                type: "UPDATE_AGGREGATOR_REQUEST"
            })
            updateAggregatorAPI(data).then(res => {
                dispatch({
                    type: 'UPDATE_AGGREGATOR_SUCCESS'
                })
                dispatch(commonActions.openSnackbarsState({ message: res && res.message }))
                resolve(res)
            }).catch(err => {
                dispatch({
                    type: 'UPDATE_AGGREGATOR_FAILED'
                })
                dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
                reject(err)
            })
        })
    }
}

export const DELETE_AGGREGATOR_REQUEST = 'DELETE_AGGREGATOR_REQUEST';
export const DELETE_AGGREGATOR_SUCCESS = 'DELETE_AGGREGATOR_SUCCESS';
export const DELETE_AGGREGATOR_FAILED = 'DELETE_AGGREGATOR_FAILED';

export function deleteAggregator(id) {
    return function (dispatch) {
        return new Promise((resolve, reject) => {
            dispatch({
                type: "DELETE_AGGREGATOR_REQUEST"
            })
            deleteAggregatorAPI(id).then(res => {
                dispatch({
                    type: 'DELETE_AGGREGATOR_SUCCESS'
                })
                dispatch(commonActions.openSnackbarsState({ message: res && res.message }))
                resolve(res)
            }).catch(err => {
                dispatch({
                    type: 'DELETE_AGGREGATOR_FAILED'
                })
                dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
                reject(err)
            })
        })
    }
}


export const CLEAR_ALL_AGGREGATOR_STORE = "CLEAR_ALL_AGGREGATOR_STORE"

export function clearStoreForAggregator() {
    return (dispatch) => {
        dispatch({
            type: "CLEAR_ALL_AGGREGATOR_STORE"
        })
    }
}