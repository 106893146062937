import {
  getAllMultiSiteDevicesAPI,
  assignAddToSiteDevicesApi,
  getMultiSiteSiteList,
  AddNewMultiSiteSiteApi,
  GetMultiSiteProfileAPI,
  getSiteByIdAPI,
  getSiteDevicesForTableAPI,
  assignDevicesToMscApi,
} from "./apis";
import * as commonActions from "../common/actions";
import { errorMessage } from "../../utils";

export const GET_ALL_MULTI_SITE_DEVIECS_REQUEST = "GET_ALL_MULTI_SITE_DEVIECS_REQUEST";
export const GET_ALL_MULTI_SITE_DEVIECS_SUCCESS = "GET_ALL_MULTI_SITE_DEVIECS_SUCCESS";
export const GET_ALL_MULTI_SITE_DEVIECS_FAILED = "GET_ALL_MULTI_SITE_DEVIECS_FAILED";

export const getAllMultiSiteDevices = (filters) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch({
      type: GET_ALL_MULTI_SITE_DEVIECS_REQUEST,
    });
    getAllMultiSiteDevicesAPI(filters)
      .then((res) => {
        dispatch({
          type: GET_ALL_MULTI_SITE_DEVIECS_SUCCESS,
          payload: (res && res.info) || {},
        });
        return resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: GET_ALL_MULTI_SITE_DEVIECS_FAILED,
        });
        dispatch(
          commonActions.openSnackbarsState({ message: errorMessage(err), messageType: "error" })
        );
        return reject(err);
      });
  });

export const CLEAR_ALL_DEVICE_DATA = "CLEAR_ALL_DEVICE_DATA";

export const clearStoreForAgencyDevice = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_ALL_DEVICE_DATA,
    });
  };
};

export const GET_MS_SITE_REQUEST = "GET_MS_SITE_REQUEST";
export const GET_MS_SITE_SUCCESS = "GET_MS_SITE_SUCCESS";
export const GET_MS_SITE_FAILED = "GET_MS_SITE_FAILED";

export const getAllMultiSiteSite = (filters) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch({
      type: GET_MS_SITE_REQUEST,
    });
    getMultiSiteSiteList(filters)
      .then((res) => {
        dispatch({
          type: GET_MS_SITE_SUCCESS,
          payload: (res && res.info) || {},
        });
        return resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: GET_MS_SITE_FAILED,
        });
        dispatch(
          commonActions.openSnackbarsState({ message: errorMessage(err), messageType: "error" })
        );
        return reject(err);
      });
  });

export const ASSIGN_ADD_TO_SITE_DEVICES_REQUEST = "ASSIGN_ADD_TO_SITE_DEVICES_REQUEST";
export const ASSIGN_ADD_TO_SITE_DEVICES_SUCCESS = "ASSIGN_ADD_TO_SITE_DEVICES_SUCCESS";
export const ASSIGN_ADD_TO_SITE_DEVICES_FAILED = "ASSIGN_ADD_TO_SITE_DEVICES_FAILED";

export const assignAddToSiteDevices = (filters) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch({
      type: ASSIGN_ADD_TO_SITE_DEVICES_REQUEST,
    });
    assignAddToSiteDevicesApi(filters)
      .then((res) => {
        dispatch({
          type: ASSIGN_ADD_TO_SITE_DEVICES_SUCCESS,
        });
        dispatch(commonActions.openSnackbarsState({ message: res && res.message }));
        return resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: ASSIGN_ADD_TO_SITE_DEVICES_FAILED,
        });
        dispatch(
          commonActions.openSnackbarsState({ message: errorMessage(err), messageType: "error" })
        );
        return reject(err);
      });
  });

export const ASSIGN_DEVICES_TO_MSC_REQUEST = "ASSIGN_DEVICES_TO_MSC_REQUEST";
export const ASSIGN_DEVICES_TO_MSC_SUCCESS = "ASSIGN_DEVICES_TO_MSC_SUCCESS";
export const ASSIGN_DEVICES_TO_MSC_FAILED = "ASSIGN_DEVICES_TO_MSC_FAILED";

export const assignDevicesToMsc = (filters) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch({
      type: ASSIGN_DEVICES_TO_MSC_REQUEST,
    });
    assignDevicesToMscApi(filters)
      .then((res) => {
        dispatch({
          type: ASSIGN_DEVICES_TO_MSC_SUCCESS,
        });
        dispatch(commonActions.openSnackbarsState({ message: res && res.message }));
        return resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: ASSIGN_DEVICES_TO_MSC_FAILED,
        });
        dispatch(
          commonActions.openSnackbarsState({ message: errorMessage(err), messageType: "error" })
        );
        return reject(err);
      });
  });

export const ADD_NEW_MULTISITE_SITE_REQUEST = "ADD_NEW_MULTISITE_SITE_REQUEST";
export const ADD_NEW_MULTISITE_SITE_SUCCESS = "ADD_NEW_MULTISITE_SITE_SUCCESS";
export const ADD_NEW_MULTISITE_SITE_FAILED = "ADD_NEW_MULTISITE_SITE_FAILED";

export const addMultiSiteToSite = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch({
      type: ADD_NEW_MULTISITE_SITE_REQUEST,
    });
    AddNewMultiSiteSiteApi(data)
      .then((res) => {
        dispatch({
          type: ADD_NEW_MULTISITE_SITE_SUCCESS,
        });
        dispatch(commonActions.openSnackbarsState({ message: res && res.message }));
        return resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: ADD_NEW_MULTISITE_SITE_FAILED,
        });
        // dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
        return reject(err);
      });
  });

export function getMultiSiteProfile() {
  return new Promise((resolve, reject) => {
    GetMultiSiteProfileAPI()
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        // dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
        reject(err);
      });
  });
}

export const GET_SITES_BY_ID_REQUEST = "GET_SITES_BY_ID_REQUEST";
export const GET_SITES_BY_ID_SUCCESS = "GET_SITES_BY_ID_SUCCESS";
export const GET_SITES_BY_ID_FAILED = "GET_SITES_BY_ID_FAILED";
export const CLEAR_SITES_DETAIL = "CLEAR_SITES_DETAIL";

export const getSitesDetails = (id) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch({
      type: GET_SITES_BY_ID_REQUEST,
    });
    getSiteByIdAPI(id)
      .then((res) => {
        dispatch({
          type: GET_SITES_BY_ID_SUCCESS,
          payload: res && res.info,
        });

        return resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: GET_SITES_BY_ID_FAILED,
        });
        dispatch(
          commonActions.openSnackbarsState({ message: errorMessage(err), messageType: "error" })
        );
        return reject(err);
      });
  });

export const clearStoreForSiteSDetails = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_SITES_DETAIL,
    });
  };
};

export const GET_SITE_DEVICES_FOR_TABLE_REQUEST = "GET_SITE_DEVICES_FOR_TABLE_REQUEST";
export const GET_SITE_DEVICES_FOR_TABLE_SUCCESS = "GET_SITE_DEVICES_FOR_TABLE_SUCCESS";
export const GET_SITE_DEVICES_FOR_TABLE_FAILED = "GET_SITE_DEVICES_FOR_TABLE_FAILED";

export const getSitesDevicesForTable = (filters) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: GET_SITE_DEVICES_FOR_TABLE_REQUEST,
    });
    getSiteDevicesForTableAPI(filters)
      .then((res) => {
        const { info = {} } = res;
        dispatch({
          type: GET_SITE_DEVICES_FOR_TABLE_SUCCESS,
          payload: info,
        });

        return resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: GET_SITE_DEVICES_FOR_TABLE_FAILED,
        });
        dispatch(
          commonActions.openSnackbarsState({ message: errorMessage(err), messageType: "error" })
        );
        return reject(err);
      });
  });
};
